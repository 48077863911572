import React from "react";
import "@tabler/core";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faBlackboard,
  faBook,
  faBoxArchive,
  faBuilding,
  faChartSimple,
  faClipboardList,
  faCompress,
  faDashboard,
  faListSquares,
  faMessage,
  faNewspaper,
  faSchoolCircleXmark,
  faUserGroup,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

export default function HeaderAdmin() {
  const activeClassName = " border-bottom border-2 border-primary rounded-0";
  const { pathname } = useLocation();

  return (
    <div className="bg-light m-0 p-0" style={{ zIndex: 1 }}>
      <div
        class="container-lg container-fluid ps-0"
      >
        <div class="row" id="scrollcontainer">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-light ">
              <div class="container-fluid ">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav d-flex tex-nowrap">
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " +
                              (pathname === "/admin" ? activeClassName : null)
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faDashboard}
                        />
                        <span className="nav-link-title">Tableau de bord</span>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle pt-3 "
                        href="/#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faCompress}
                        />
                        <span className="nav-link-title">Ressources</span>
                      </a>

                      <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                        <NavLink
                          to="/admin/categorie"
                          className={({ isActive }) =>
                            isActive
                              ? " dropdown-item " + "bg-blue-lt"
                              : "  dropdown-item"
                          }
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            color="#8692A5"
                            fontSize={"15px"}
                            icon={faSchoolCircleXmark}
                          />
                          <span className="nav-link-title">Categories</span>
                        </NavLink>
                        <NavLink
                           to="/admin/gerer_module"
                           className={({ isActive }) =>
                           isActive
                             ? " dropdown-item " + "bg-blue-lt"
                             : "  dropdown-item"
                         }
                        >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faListSquares}
                        />
                        <span className="nav-link-title">Modules</span>
                        </NavLink>
                        <NavLink
                         to="/admin/gerer_lesson"
                           className={({ isActive }) =>
                           isActive
                             ? " dropdown-item " + "bg-blue-lt"
                             : "  dropdown-item"
                         }
                        >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faBook}
                        />
                        <span className="nav-link-title">Leçons</span>
                        </NavLink>
                   
                        <NavLink
                           to="/admin/gerer_questionnaire"
                           className={({ isActive }) =>
                           isActive
                             ? " dropdown-item " + "bg-blue-lt"
                             : "  dropdown-item"
                         }
                        >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faBlackboard}
                        />

                        <span className="nav-link-title">Exercice</span>
                        </NavLink>
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/gerer_actualite"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faNewspaper}
                        />
                        <span className="nav-link-title">Actualités</span>
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/gerer_textelois"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faArchive}
                        />
                        <span className="nav-link-title">Textes en vigueur</span>
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/gerer_sensibilisation"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faWarning}
                        />
                        <span className="nav-link-title">Sensibilisations</span>
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/organisme"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faBuilding}
                        />

                        <span className="nav-link-title">MTM</span>
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/exam_type"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faClipboardList}
                        />

                        <span className="nav-link-title">Examen pratique</span>
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/admin/gestion des utilisateurs"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 text-nowrap " + activeClassName
                            : " nav-link pb-1 text-nowrap"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faUserGroup}
                        />

                        <span className="nav-link-title">
                          Utilisateurs
                        </span>
                      </NavLink>
                    </li>
                  
                          <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                            to="/admin/GererNote"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faChartSimple}
                            />
                            <span className="nav-link-title">Gérer Note</span>
                          </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
