import axios from "axios";
import jwtDecode from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";
import { LOGIN_API } from "../config";
/**
 * Deconnexion (suppresion du token du localestorage et sur axios)
 */

function logout(){
   
    window.localStorage.removeItem("authToken");
    delete axios.defaults.headers["Authorization"];
}
/**
 * Requete HTTP d'authentifiction et stockage du token dans le storge sur axios
 * @param {object} credentials 
 * @returns 
 */
function authenticate(credentials) {
 return axios
    .post(LOGIN_API, credentials)
    .then(response => response.data.token)
    .then(token => {

            //je stock mon token dans mon localstorage
            window.localStorage.setItem("authToken", token);
            //on previent axios qu'on a maintenant un header par defaut sur toutes nos futures requete
            setAxiosToken(token);
    });
}
/**
 * Positionne le token JWT sur Axios
 * @param {string} token le token JWT
 */
function setAxiosToken(token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token;
}
/**
 * Mise en place lors du chargement de l'application
 */
function setup() {
    //1. voir si on a un token ?
    const token = window.localStorage.getItem("authToken");
    console.log("mande")
    //2.si le token est encore valide
    if (token) {
        const {nom_util: nom} = jwtDecode(token);
        if(nom){
            setAxiosToken(token);
        }
    }
    //Donner le token a axios
}
/**
 * Permet de savoir si on est authentifier ou pas
 * @returns boolean
 */

function isAuthenticated() {
     //1. voir si on a un token ?
     const token = window.localStorage.getItem("authToken");
      //2.si le token est encore valide
    if (token) {
        const {nom_util: nom} = jwtDecode(token);
        if(nom){
            console.log(nom)
            return true
        }
        return false; 
    }
    return false;   
}

export default {
    authenticate,
    logout,
    setup,
    isAuthenticated,
    setAxiosToken
};