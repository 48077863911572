import React from 'react'
import Section3 from '../Accueil/section/Section3'
import ScrollToTopOnMount from '../../service/remonter'
import { useEffect } from 'react';

export default function AccueilExercice(props) {
useEffect(()=>{
 setTimeout(() => {
    window.scrollTo({top:0,behavior:'smooth'})
 },1000);   
},[])
  return (
   <>
    <div id='exercices'></div>
    <Section3 test={props.test} categ={props.categorie}/>
   </>
  )
}
