import React from 'react'
import { useState } from 'react';
import { Zoom } from 'react-awesome-reveal';

export default function LoadingCard() {
    const styleCard = {
        position: "relative",
        zIndex: "20",
      };
      const styleBulle = {
        position: "absolute",
        top: "-25px",
        left: "41%",
        zIndex: "56",
      };
    
      let dataMod = [];
    
      const [chiffre, setChiffre] = useState([
        { id: "1" },
        { id: "1" },
        { id: "1" },
        { id: "1" },
      ]);
     
  return (
    <div>
          <Zoom triggerOnce>
          <div className="row">
            {chiffre.map((io, i) => (
              <div
                key={i}
                className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-2"
              >
                <div
                  className="card effet rounded-4 placeholder w-100 rounded-3"
                  style={styleCard}
                >
                  <div
                    className="card-header placeholder rounded-top-3 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                    style={{
                      position: "relative",
                      zIndex: "20",
                      backgroundSize: "cover",
                    }}
                  >
                    <span
                      className="avatar avatar-md bg-azure text-white mb-3 placeholder avatar-rounded"
                      style={styleBulle}
                    >
                      A'
                    </span>
                  </div>
                  <div className="card-body placeholder">
                    <a href="/details/2">
                      <h5 className="card-title text-center placeholder">
                        ishdhsufhsfuisd
                      </h5>
                    </a>
                    <p className="card-text fw-light text-center fs-4 pb-4 placeholder">
                      Lorem nfuiwhweurgwh uy hh uegwe yugewyu gweu gweuwg ueygw
                      uygweuygeywu
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>{" "}
        </Zoom>
    </div>
  )
}
