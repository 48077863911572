import {
  faAdd,
  faBookBookmark,
  faCancel,
  faEdit,
  faEye,
  faFile,
  faSchool,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import axios from "axios";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";


import { Rating } from "react-simple-star-rating";
import { FichierLesson_API, LESSON_API, ListLessonHateaos_API, MODULE_API, file_url } from "../../../service/config";
import Pagination from "../../../outils/Pagination";
import AjoutModifLesson from "../lessons/AjoutModifLesson";
import { truncate } from "../../../outils/Truncate";
import Comment_Les from "../lessons/Comment_les";
import { Button } from "react-bootstrap";

const animatedComponents = makeAnimated();
const Fichier = [
  { value: "Video", label: "Video" },
  { value: "Photo", label: "Photo" },
  { value: "Document", label: "Document" },
];
export default function ModuleLesson() {
  const {id} = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataRedux = useSelector((state) => state.lesson);
  const ref = useRef(null);
  const [ID, setID] = useState(null);
  const [loadModule, setLoadModule] = useState(false);
  let dataLes = [];
  const [idLF, setidLF] = useState(null);
  const [load, setLoad] = useState(false);
  const [barcolor, setBarcolor] = useState("blue");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const abortController = new AbortController();
  const [error, setError] = useState([]);
  const [options, setOptions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState({
    titre_les: "",
    desc_les: "",
  });
  const vide = {
    titre_les: "",
    desc_les: "",
    "img_les ": null,
    "id_mod ": "",
  };
  const [formValues, setFormValues] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setLesson({ ...lesson, [name]: value });
  };

  const [image, setImage] = useState(null);
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setLesson({ ...lesson, img_les: e.target.files[0] });
    }
  };
  const onChangefichier = (i, e) => {
    let formfichier = [...formValues];

    if (e.target.files && e.target.files[0]) {
      formfichier[i]["fichierl"] = e.target.files[0];
    }
    setFormValues(formfichier);
  };
  let handleChangeFichier = (i, e) => {
    let formfichier = [...formValues];
    formfichier[i][e.target.name] = e.target.value;
    formfichier[i]["index"] = i;
    setFormValues(formfichier);
    if (e.target.name === "type_fichl") {
      formValues.forEach((element, index) => {
        document.getElementById(`type${index}`).innerHTML = "";
        if (
          element.type_fichl === "" ||
          element.type_fichl === "choisir ici..."
        ) {
          document.getElementById(`type${index}`).innerHTML =
            "<p className='text-danger fs-5'>type fichier requis</p>";
        } else {
          document.getElementById(`type${index}`).innerHTML = "";
        }
      });
    }
  };

  const addInput = (e) => {
    e.preventDefault();
    const nvInput = [
      ...formValues,
      { titre_fichl: "", type_fichl: "", fichierl: null, id_les: idLF },
    ];
    setFormValues(nvInput);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var text = "";
    setIto(choice);
    choice.forEach((element) => {
      text += element.value + ",";
    });
    console.log(text);
    setLesson({ ...lesson, id_mod: text });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const ajoutLessonFichier = (e) => {
    e.preventDefault();
    var process = 0;
    setLoad(true);
    for (let index = 0; index < formValues.length; index++) {
      const element = formValues[index];
      document.getElementById(`type${index}`).innerHTML = "";
      if (
        element.type_fichl === "" ||
        element.type_fichl === "choisir ici..."
      ) {
        document.getElementById(`type${index}`).innerHTML =
          "<p className='text-danger fs-5'>type fichier requis</p>";
        setLoad(false);
        break;
      } else {
        document.getElementById(`type${index}`).innerHTML = "";
        if (index === formValues.length - 1) {
          formValues.forEach((element) => {
            process++;
            try {
              axios
                .post(FichierLesson_API + "?_method=PUT", element, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response);
                  if (process === formValues.length) {
                    toast.success("Fichier ajouter avec success");
                    document.getElementById("fermerModal").click();
                    setLoad(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  const msg = error.response.data;
                  console.log(msg);
                });
              setError([]);
            } catch (error) {
              console.log(error);
              setLoad(false);
            }
          });
        }
      }
    }
  };

  const paginatedInvoices = Pagination.getData(
    lessons,
    currentPage,
    itemsPerPage
  );
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
    console.log(lesson);
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(LESSON_API, lesson, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success("insertion reussi");
          getAllLesson();
          let id_les = response.data.data.id_les;
          setidLF(id_les);

          setLesson(vide);
          setLoad(false);

          console.log(response);
          console.log(id_les);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.titre_les = msg.titre_les;
            apiErrors.desc_les = msg.desc_les;
            apiErrors.img_les = msg.img_les;
            apiErrors.id_mod = msg.id_mod;
          }

          console.log(error);
        });
      setError(apiErrors);
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };
  const GetModuleByoneLesson = async (module) => {
    const GETIO = [];
    try {
      var i = 0;
      module.forEach((element) => {
        i++;
        GETIO.push({
          value: element.modules.id_mod,
          label: element.modules.titre_mod,
        });
        if (i === module.length) {
          setIto(GETIO);
          change(GETIO);
        }
      });
    } catch (error) {}
  };

  const getAllLesson = () => {
    ref.current.continuousStart();
    setBarcolor("blue");
    setLessons(dataRedux);
    try {
      axios
        .get(ListLessonHateaos_API, {
          signal: abortController.signal,
        })
        .then((response) => {
      
          
          const filteredInvoices = response.data.data.filter(element =>
            element.modulelessons?.some(el => el.id_mod == id)
          );
          setLessons(filteredInvoices);
          console.log(filteredInvoices)
          dispatch({ type: "AddLesson", payload: filteredInvoices });
          ref.current.complete();
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getAllModule = () => {
    setBarcolor("blue");
    const getModules = [];
    try {
      axios
        .get(MODULE_API, {
          signal: abortController.signal,
        })
        .then((response) => {
          console.log(response);
          const data = response.data.data;

          data.forEach((element) => {
            getModules.push({
              value: element.id_mod,
              label: element.titre_mod,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
      setOptions(getModules);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(LESSON_API + "/" + ID + "?_method=PUT", lesson, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          document.getElementById("fermermodif").click();
          toast.success("modification reussi");
          getAllLesson();
          setLoad(false);
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est sruvenu");
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.titre_les = msg.titre_les;
            apiErrors.desc_les = msg.desc_les;
          }

          console.log(error);
        });
      setError(apiErrors);
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  const supprimer = async () => {
    setLoad(true);
    try {
      await axios
        .delete(LESSON_API + "/" + ID)
        .then((response) => {
          toast.success("Lesson numero " + ID + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getAllLesson();
        })
        .catch((error) => {
          console.log(error);
          toast.error("module pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  const fetchUpdate = (les, module) => {
    setID(les.id_les);
    setLesson(vide);
    setIto([]);
    GetModuleByoneLesson(module);
    const updt = {};
    setImage(file_url + les.img_les);
    updt.titre_les = les.titre_les;
    updt.desc_les = les.desc_les;
    var text = "";
    module.forEach((element) => {
      text += element.id_mod + ",";
    });
    updt.id_mod = text;
    setLesson(updt);
  };

  const initialiser = () => {
    setIto([]);
    setLesson(vide);
    setFormValues([]);
    setImage(null);
    setError(vide);
    setidLF(null);
    console.log("click");
    document.getElementById("reset_form").click();
  };

  // calls whenever state will be change , another approach
  useEffect(() => {
    getAllLesson();
    getAllModule();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <LoadingBar ref={ref} color={barcolor} />
      <div className="container-lg container-fluid py-4">
        <div
          className="modal modal-blur"
          id="modal-danger"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-danger"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon mb-2 text-danger icon-lg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9v2m0 4v.01"></path>
                  <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                </svg>
                <h3>vous etes sur ?</h3>
                <div className="text-muted">
                  vous-voulez vraiment supprimer cette leçon? c'est
                  irreversible.
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        id="fermerdel"
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={supprimer}
                        className={
                          "btn btn-danger w-100 " + (load && "disabled")
                        }
                      >
                        Supprimer leçon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h2>Leçons</h2>
            <p className="">Liste des leçons du module</p>
          </div>
          <div className="d-flex gap-2">
            <Button variant="secondary" onClick={()=>navigate(-1)}>Retour</Button>
            <AjoutModifLesson options={options} />
          </div>
        </div>

        <div className="overflow-auto">
          <table className="table table-hover table-bordered table-striped table-light ">
            <thead>
              <tr className="">
                <th>ID</th>
                <th>Titre</th>
                <th className="col-2">Module</th>
                <th>Description</th>
                <th>Note Moyenne</th>
                <th>Commentaires</th>
                <th>Image</th>
                <th>Fichier</th>
                <th>Chapitre</th>
                <th className="col-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedInvoices.map((element, index) => (
                <tr key={index}>
                  <td>{element.id_les}</td>
                  <td>{element.titre_les}</td>
                  <td className="text-center">
                    <div className="d-flex flex-wrap gap-1">
                      {element?.modulelessons?.map((el, index) => (
                        <span key={index} className="badge bg-cyan text-wrap">
                          {el.modules.titre_mod}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>{truncate(element.desc_les)}</td>
                  <td>
                    <Rating
                      showTooltip={false}
                      /* tooltipArray={tooltipArraylist}*/
                      tooltipDefaultText={
                        Math.round(element.avislessons * 100) / 100
                      }
                      size={'20px'}
                      titleSeparator="sur"
                      initialValue={element.avislessons}
                      readonly={true}
                      /* Available Props */
                    />
                  </td>
                  <td>
                    <Comment_Les id={element.id_les} />
                  </td>
                  <td>
                    {" "}
                    <span
                      className="avatar avatar-sm"
                      style={{
                        backgroundImage: `url(${file_url}/${element.img_les})`,
                      }}
                    ></span>
                  </td>

                  <td className="text-center">
                    <Link
                      state={element}
                      to={`/admin/fichier_les/${element.id_les}`}
                      className="nav-link "
                    >
                      <span className="badge bg-info py-2 px-2">
                        <FontAwesomeIcon icon={faFile} className="me-2" />
                        Gerer
                      </span>
                    </Link>
                  </td>
                  <td className="text-center">
                    <Link
                      state={element}
                      to={`/admin/leçon/chapter/${element.id_les}`}
                      className="nav-link "
                    >
                      <span className="badge bg-cyan p-2">
                        <FontAwesomeIcon
                          icon={faBookBookmark}
                          className="me-2"
                        />
                        Gerer
                      </span>
                    </Link>
                  </td>
                  <td className="d-flex gap-2 justify-content-center">
                    <AjoutModifLesson id={element.id_les} options={options} />
                    <button
                      type="button"
                      onClick={() => setID(element.id_les)}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-danger"
                      className="btn btn-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChanged={handlePageChange}
              length={lessons.length}
            />
          </div>
        </div>
      </div>
    </>
  );
}
