import {
  faEye,
  faPhone,
  faTrash,
  faUndoAlt,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { getListeUtilisateurs_Api } from "../../../service/config";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import Details from "./Details";
import Supprimer from "./Supprimer";
import { Tag } from "primereact/tag";

export default function Gestion(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setdata] = useState([]);
  const [supp, setsupp] = useState(false);
  const [affichage,setaffichage] = useState('tout')
  const [filtre,setFiltre] = useState([])

  const columns = [
    // {
    //   width: "10%",
    //   name: "Photo",
    //   selector: (row) => (
    //     <Image src={file_url + row.photo_cand} alt="Image" width="50" preview />
    //   ),
    //   sortable: true,
    // },
    {
      name: "Nom et Prénom",
      minWidth:'250px',
      wrap: true,
      selector: (row) => row.nom_util + " " + row.prenom_util,
      sortable: true,
    },
    {
      minWidth:'250px',
      name: "email",
      wrap:true,
      selector: (row) => <div>{row.email}</div>,
      sortable: true,
    },
    {
      name: "téléphone",
      selector: (row) => (
        <>
          {" "}
          <FontAwesomeIcon icon={faPhone} className="pe-2" />
          {row.tel_util}
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "compte",
      selector: (row) => row.status_util,
      format: (row) =><Tag severity={row.status_util == "Bloquer" ? "danger" :row.status_util == "Activer" ? "success" :"warning"}>{row.status_util == "Bloquer" ? "Exclus" :row.status_util == "Activer" ? "Activé" :"Désactivé" }</Tag>,
      sortable: true,
      
    },
    {
      name: "Organisme",
      selector: (row) => row.type_util,
      format: (row) =><Tag severity={"info"}>{row.type_util}</Tag>,
      sortable: true,
     
    },

    {
      name: "Détails",
      cell: (row) => (
        <div className="d-flex gap-1">
        <Details getUsers={getUsers} data={row}/>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const getUsers =  () => {
    setLoad(true);
    try {
       axios
        .get(
          getListeUtilisateurs_Api +
            "?types_utilisateurs[]=SuperAdmin&types_utilisateurs[]=Admin"
        )
        .then((rep) => {
          console.log(rep.data);
          const uniqueTypes = new Set(rep.data.data.map(item => item.type_util));
          const uniqueTypesArray = Array.from(uniqueTypes);
          setFiltre(uniqueTypesArray);
          setdata(
            rep.data.data
          );

          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  const datafiltrer = data.filter((item) => {
   return (
      affichage == 'tout' ? item : (item.type_util && item.type_util === affichage) 
    );
  });
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="h-100">
      <div className="jumbotron container-lg container-fluid h-100 w-100 py-4">
        <div className="row row-cards">
         {!props.dash && <div className="col-12 d-flex justify-content-start">
            <h3>
              <FontAwesomeIcon icon={faUserGroup} /> Gestion des utilisateurs
            </h3>
          </div>}
          <div className="">
            <DataTable
            fixedHeader
            responsive
            highlightOnHover
              className="custom-table"
              title={
                <div className="d-flex justify-content-between">
                  <p className="fs-4">{props.dash ? "Les admins": "Les utilisateurs"}</p>
                 {!props.dash && <div className="d-flex gap-2 fs-5 align-items-center">
                   <span className="text-nowrap"> FILTRE PAR  :</span> 
                    <select className="form-control" name="filtrage" onChange={(e)=> setaffichage(e.target.value)}>
                      <option value="tout">Afficher tout</option>
                      {filtre?.map((item,i)=> <option value={item} key={i}>{item}</option>)}
                    </select>
                  </div>}
                </div>
              }
              columns={columns}
              data={props.dash ? data.filter(row=>row.type_util == "SuperAdmin") :datafiltrer}
              selectableRows
              clearSelectedRows={toggledClearRows}
              selectedRow={selectedRows}
              progressPending={load}
              contextActions={
                <div className="d-flex w-100 justify-content-between">
                  <Button
                    className="me-2"
                    variant="secondary"
                    onClick={() => {
                      settoggledClearRows(!toggledClearRows);
                    }}
                  >
                    <FontAwesomeIcon icon={faUndoAlt} className="" />
                  </Button>

                  <Supprimer selected={selectedRows} deselected={settoggledClearRows}/>
                </div>
              }
              progressComponent={
                <div className="w-100 d-flex justify-content-center py-5">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              }
              contextMessage={{
                singular: "élement",
                plural: "élements",
                message: "sélectionnées",
              }}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows.selectedRows);
              }}
              pagination
              paginationPerPage={5}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page",
              }}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
