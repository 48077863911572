import { faHourglass3, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import image from '../../../assets/image/imgtest/image'

export default function Pointer() {
    return (
        <>
            <div className="container-fluid py-4">
                <div className='container-lg container-fluid'>
                    <div>
                        <h2>Pointeur</h2>
                        <p className=''>Données candidat</p>
                    </div>
                    <div className='section1 p-3 rounded-2'>
                        <div className='row '>
                            <div className='col'>
                                <div>
                                    <h3>Candidat numero 126115212</h3>
                                </div>
                                <div className='p-2'>
                                    <table className='border-none'>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Nom et prenom :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Maminiaina Indrafo Henri</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>CIN :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Maminiaina Indrafo Henri</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Sexe :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Homme</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Nationnalitée :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Malagasy</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Adresse :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Ambalatambina Beravina Talamatamaty Fianarantsoa</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>CP :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>301</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Region :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Haute Matsiatra</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Pays :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>Madagascar</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Teléphone :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>+261345413788</span></th>
                                        </tr>
                                        <tr>
                                            <th className='pb-1'><span className='fw-light text-muted me-2'>Email :</span></th>
                                            <th className='pb-1'><span className='fw-bold'>maminiainaIndrafo@gmail.com</span></th>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                            <div className='col-4 d-flex align-items-center justify-content0-center'>
                                <img src={image.image4} className="" />
                            </div>
                        </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Modules choisis</h3>
                        <div className='d-flex flex-row gap-2 p-2'>
                            <span className='badge bg-primary p-2'>Module 1</span>
                            <span className='badge bg-primary p-2'>Module 2</span>
                            <span className='badge bg-primary p-2'>Module de formation 1ere</span>
                            <span className='badge bg-primary p-2'>Module choisi à s'informer</span>
                        </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Leçons parcourus</h3>
                       <div className='p-3'>
                       <div className="list-group list-group-flush list-group-hoverable">
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Exercices parcourus</h3>
                       <div className='p-3'>
                       <div className="list-group list-group-flush list-group-hoverable">
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Localisation actuelle du candidat</h3>
                       <div className='p-3'>
                        <p>Données sur adresse de localisation du candidat</p>
                      </div>
                    </div>
                </div>
            </div>
        </>
    )
}
