import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate()
  return (
    <div className="row justify-content-center align-items-center w-100 h-100">
      <div className="col-6 d-flex align-items-center justify-content-center vh-100">
        <div className="d-flex flex-column justify-content-center ">
          <p className="display-1 text-center">404</p>
          <h1 className="text-center">Page Non Trouvé</h1>
         
                  <button
                    type="button"
                    className={`text-center btn btn-sm btn-secondary py-2 px-3 rounded-5`}
                    onClick={() => navigate("/")}
                  >
                    Retour à l'accueil
                  </button>
             
        </div>
      </div>
    </div>
  );
}
