import { faReply, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import image from "../../assets/image/imgtest/image";
import {
  Contact_API,
  reponseContact_API,
  reponseByContact,
} from "../../service/config";
import socket from "../../service/Socket_Connection";
import { Button } from "react-bootstrap";

export default function ReponseNotifAdmin() {
  const [infocontact, setinfocontact] = useState({});
  const navigate = useNavigate()
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
    monthsShort:
      "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
    monthsParseExact: true,
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Aujourd’hui à] LT",
      nextDay: "[Demain à] LT",
      nextWeek: "dddd [à] LT",
      lastDay: "[Hier à] LT",
      lastWeek: "dddd [dernier à] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "dans %s",
      past: "il y a %s",
      s: "quelques secondes",
      m: "une minute",
      mm: "%d minutes",
      h: "une heure",
      hh: "%d heures",
      d: "un jour",
      dd: "%d jours",
      M: "un mois",
      MM: "%d mois",
      y: "un an",
      yy: "%d ans",
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? "er" : "e");
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === "M";
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "PD" : "MD";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });
  const location = useLocation();
  const user = localStorage.getItem("authToken");
  const [loadbutton,setloadButton] = useState(false)
  const [load, setload] = useState(false);
  const [loadM, setloadM] = useState(true);
  const { email } = jwtDecode(user);
  const [listrep, setlistrep] = useState([]);
  const [Objet,setOBjet] = useState({})
  const [data, setdata] = useState({
    object_repcont: "",
    desc_repcont: "",
  });
  const [reset, setreset] = useState({
    object_repcont: "",
    desc_repcont: "",
  });
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setdata({ ...data, [name]: value });
  };
  const postMessage = async (e) => {
    e.preventDefault();
    setloadButton(true)
    try {
      await axios
        .post(reponseContact_API, data)
        .then((response) => {
          socket.emit('envoyer',{to:"SuperAdmin",message:data.desc_repcont})
          toast.success("E-mail envoyé !");
          
          setdata(reset);
          getlistrep();
          setloadButton(false)
          console.log(response);
        })
        .catch((error) => {
          toast.error("Email pas envoyer");
          if (error.response.data.line === 261) {
            toast.error("Erreur, pas de connexion au réseau");
            setloadButton(false)
          }
          console.log(error);
          setloadButton(false)
        });
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const getlistrep = async () => {
    try {
      await axios
        .get(reponseByContact + "/" + infocontact.id_cont)
        .then((rep) => {
          console.log(rep);
          setlistrep(rep.data.data);
          setOBjet(rep.data.objet)
          setloadM(false)
        })
        .catch((err) => {
          console.log(err);
          setloadM(false)
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (location.state?.data) {
      setinfocontact(location.state?.data);
    }else {
      navigate('/admin/mes notifications')
     }
  }, []);
  useEffect(() => {
  
    console.log(infocontact);
    setdata({ ...data, id_cont: infocontact.id_cont });
    getlistrep();
   
  }, [infocontact]);

  return (
    <div className="container-fluid bg-light">
      {
        !loadM  ? <>
        <div className="container py-3">
        <div className="">
          <div className="d-flex gap-3 my-3 align-items-center justify-content-between">
          <h1 className="display-6 ">{Objet.object_cont}</h1>
          <div>réçu le <span className="text-muted">{moment(Objet.created_at).locale('fr').format('LL LT')}</span></div>
          </div>
          <h3>
            Reponse à {infocontact.email_cont && <>{infocontact.email_cont}</>}
          </h3>
        </div>{" "}
        <div className="row mb-5 mt-2">
          <div className="col-12 my-2">
            <div className="card p-3">
              <div
                className="row"
                style={{ maxheight: "700px", overflowY: "scroll" }}
              >
                <div className="col-12 my-2">
                    <div>
                     <div className="d-flex align-items-center">
                     <h3>
                        <span
                          className="avatar avatar-sm me-2"
                          style={{
                            backgroundImage: `url(${image.avatar})`,
                          }}
                        ></span>
                        {infocontact.email_cont}
                      </h3>
                      <span className="fst-italic ms-3 fs-5">posté {moment(infocontact.created_at).locale('fr').format('LL LT')}</span>
                     </div>
                      <h5>{infocontact.object_cont}</h5>
                      <p className="my-2">
                        {infocontact.desc_cont}
                      </p>
                    </div>
                  </div>
                {listrep.map((list, index) => (
                  <div key={index} className="col-12 my-2 ps-4">
                    <div>
                      <div className="d-flex align-items-center">
                      <h3>
                        <span
                          className="avatar avatar-sm me-2"
                          style={{
                            backgroundImage: `url(${image.avatar})`,
                          }}
                        ></span>
                        {list.email_repcont}
                        
                      </h3>
                      <span className="fst-italic ms-3 fs-5">posté le {moment(list.created_at).locale('fr').format('LL LT')}</span>
                      </div>
                      <h5>{list.object_repcont}</h5>
                      <p className="my-2">
                        {list.desc_repcont}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="w-100">
              <form onSubmit={postMessage}>
                <div className="my-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={data.object_repcont}
                    name="object_repcont"
                    placeholder="Sujet"
                    required
                  />
                </div>
                <div className="my-3">
                  <textarea
                    type="text"
                    rows="5"
                    onChange={handleChange}
                    value={data.desc_repcont}
                    name="desc_repcont"
                    className="form-control"
                    placeholder="Description du message"
                    required
                  />
                </div>
                <div className="d-flex justify-content-end my-3">
                  <Button
                    type="submit"
                    className={"btn btn-primary px-3 " + (loadbutton && "disabled")}
                  >
                    Envoyer {loadbutton && <FontAwesomeIcon  spinPulse icon={faSpinner} className="ms-2 white" />}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </> : 
        <div className="display-6 text-center py-5">
          Chargement du message
        </div>
      }
    </div>
  );
}
