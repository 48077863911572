import {
  faArrowDown,
  faArrowUp,
  faClockFour,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import image from "../../../assets/image/imgtest/image";
import { Fade } from "react-awesome-reveal";
import axios from "axios";
import { ACTU_API, file_url } from "../../../service/config";
import { Link } from "react-router-dom";
import moment from "moment";
import RetourComp from "../../../outils/RetourComp";

export default function TousActu() {
  const test =
    " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut oditaut fugit sed quia.";
  const test1 =
    " Nemo enim ipsam voluptatem quia ipsam voluptatem quia ipsam voluptatem ipsam voluptatem quia ipsam voluptatem quia  quia  voluptas sit aspernatur aut oditaut fugit sed quia.";
  const [filter, setfilter] = useState("desc");
  const [actual, setactual] = useState([]);
  const abortController = new AbortController();
  const [load, setLoad] = useState(true);
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }
  function truncate(str) {
    return str.length > 10 ? str.substring(0, 150) + "..." : str;
  }
  const getACTU = async () => {
    setLoad(true);
    try {
      axios
        .get(ACTU_API + `?order${filter}&limit=`, {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse);
          setactual(reponse.data.data);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getACTU();
    return () => {
      abortController.abort();
    };
  }, [filter]);
  return (
    <div className="container-fluid actualite bg-light pt-1 pb-0" id="actu">
      <div className="container-lg container-fluid-md">
        <div className="my-4 card py-2 d-flex align-items-center">
          <p className="h1 fst-italic">
          <RetourComp/>
            Actualités
          </p>
        </div>
        <div className="row pb-4">
          {load ? (
            <>
              {chiffre.map((io, i) => (
                <div
                  key={i}
                  className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-3"
                >
                  <div className="card effet rounded-4 placeholder">
                    <div
                      className="card-header placeholder rounded-top-4 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                      style={{
                        position: "relative",
                        zIndex: "20",
                        backgroundSize: "cover",
                      }}
                    >
                      <span className="avatar avatar-md bg-azure text-white mb-3 placeholder avatar-rounded">
                        A'
                      </span>
                    </div>
                    <div className="card-body placeholder">
                      <Link to="/details/2">
                        <h5 className="card-title text-center placeholder">
                          ishdhsufhsfuisd
                        </h5>
                      </Link>
                      <p className="card-text fw-light text-center fs-4 pb-4 placeholder">
                        Lorem nfuiwhweurgwh uy hh uegwe yugewyu gweu gweuwg
                        ueygw uygweuygeywu
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : actual.length === 0 ? (
            <div className="w-100  my-5 p-5">
              <p className="display-5 fw-light text-secondary text-center">
                Aucun Actualité pour le moment
              </p>
            </div>
          ) : (
            actual.map((element, index) => (
              <Fade
                triggerOnce
                key={index}
                className="col-lg-3 col-md-4 col-sm-4 col-12 mb-3 d-flex align-items-stretch"
              >
                <div className="card effet  position-relative w-100 h-auto rounded-3">
                  <div className="anime-effet m-0 p-0">
                    <div
                      className="img-responsive img-responsive-21x9 card-img-top"
                      style={{
                        height: "200px",
                        backgroundImage:
                          "url(" + file_url + element.img_actu + ")",
                      }}
                    ></div>
                    <div className="ombre"></div>
                  </div>
                  <div className="card-body">
                    <Link
                      to={"/détails actualités/" + element.id_actu}
                      className="text-decoration-none"
                    >
                      <h3 className="card-title mb-2">{element.titre_actu}</h3>
                    </Link>
                    <div className="my-2 d-flex gap-1">
                      {Separer(element.tag_actu)}
                    </div>
                    <p className="lead">
                      {" "}
                      {truncate(
                        element.resum_actu === null ? "" : element.resum_actu
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p
                      className="m-3 text-secondary fst-italic"
                      style={{ fontSize: "12px" }}
                    >
                      <FontAwesomeIcon
                        icon={faClockFour}
                        color="white"
                        className="bg-dark rounded-circle"
                      />{" "}
                      {moment(element.created_at).locale("fr").fromNow()}
                    </p>
                  </div>
                </div>
              </Fade>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
