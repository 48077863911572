import {
  faAdd,
  faCheckCircle,
  faCheckSquare,
  faEdit,
  faEraser,
  faFilter,
  faHouse,
  faRotate,
  faTrash,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form, Overlay } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  CATEGORIE_API,
  file_url,
  candidat_API,
  organisme_API,
} from "../../../service/config";

export default function Candidats() {
  const columns = [
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom_cand} {row.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.tel_cand,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_cand,
      sortable: true,
    },
    {
      name: "Date d'inscription",
      selector: (row) => row.date_insc_cand,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-cyan">{row.active_cand}</span>
      ),
      sortable: true,
    },
    {
      width: "105px",
      cell: (row) => (
        <div className="">
          <button
            className="btn btn-primary"
            onClick={() => {
              // alert(JSON.stringify(row));
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}/${row.photo_cand}`);
              setcertificat(`${file_url}/${row.scan_certifica_cand}`);
              setcin(`${file_url}/${row.scan_cin_can}`);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const columns_valid = [
    {
      name: "Nom et prenom",
      selector: (row) => <>{row.nom_cand} {row.prenom_cand}</>,
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.tel_cand,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_cand,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-cyan">{row.active_cand}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button className="btn btn-primary" onClick={() => {let mis =candidatSelect.filter((el)=>el.id_cand!=row.id_cand);setcandidatSelect(mis)}}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([
    {
      nom: "Razafindrakoto",
      prenom: "Tiana",
      adresse: "2 Rue des Baobabs, Antsirabe",
      telephone: "+261 34 98 765 43",
      email: "tiana.razafindrakoto@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-03",
    },
    {
      nom: "Rakoto",
      prenom: "Jean",
      adresse: "12 Rue des Fleurs, Antananarivo",
      telephone: "+261 32 12 345 67",
      email: "jean.rakoto@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-02-05",
    },
    {
      nom: "Raharisoa",
      prenom: "Tina",
      adresse: "4 Rue des Coquelicots, Antsiranana",
      telephone: "+261 34 23 456 78",
      email: "tina.raharisoa@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2021-12-31",
    },
    {
      nom: "Andrianasolo",
      prenom: "Luc",
      adresse: "1 Rue des Lilas, Antananarivo",
      telephone: "+261 32 87 654 32",
      email: "luc.andrianasolo@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
    {
      nom: "Randriamanantena",
      prenom: "Sandra",
      adresse: "8 Rue des Roses, Fianarantsoa",
      telephone: "+261 34 56 789 01",
      email: "sandra.randriamanantena@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-01-23",
    },
    {
      nom: "Ratovonirina",
      prenom: "Feno",
      adresse: "7 Rue de la Mer, Toamasina",
      telephone: "+261 33 45 678 90",
      email: "feno.ratovonirina@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-02-01",
    },
    {
      nom: "Ratsimba",
      prenom: "Fara",
      adresse: "5 Rue des Narcisses, Antsirabe",
      telephone: "+261 32 34 567 89",
      email: "fara.ratsimba@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
  ]);
  const [dataOrg, setdataorg] = useState([]);
  const [show, setShow] = useState(false);
  const [ModalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const [showvalid, setshowvalid] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [categorie, setcategorie] = useState([]);
  const [load, setLoad] = useState(false);
  const [rotation,setRotation] = useState(0)
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };
  const [formValues, setFormValues] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: "",
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    active_cand: "",
    province_cand: "",
    cp_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    date_insc_cand: "",
    scan_certifica_cand: "",
    scan_cin_can: "",
  });

  const [vide, setvide] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    fonction_memb: "",
    adresse_cand: "",
    ville_cand: "",
    active_cand: "",
    province_cand: "",
    cp_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    date_insc_cand: "",
  });

  const ModalShowclose = () => setModalShow(!ModalShow);
  const handleRotate = () => {
    setRotation(rotation + 90);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const handleValidation = () => setshowvalid(!showvalid);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [imagefull, setimagefull] = useState(null);
  const [cin, setcin] = useState(null);
  const [certificat, setcertificat] = useState(null);

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      if (e.target.name == "scan_certifica_cand") {
        setcertificat(URL.createObjectURL(e.target.files[0]));
      } else if (e.target.name == "scan_cin_can") {
        setcin(URL.createObjectURL(e.target.files[0]));
      } else {
        setImage(URL.createObjectURL(e.target.files[0]));
      }
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
  };

  const handleSubmit = () => {
    if (!formValues.nom_cand) {
      setError({ ...error, nom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_cand) {
      setError({ ...error, prenom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_cand) {
      setError({ ...error, cin_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.sexe_cand) {
      setError({ ...error, sexe_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.naiss_cand) {
      setError({ ...error, naiss_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_cand) {
      setError({ ...error, nationalite_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_cand) {
      setError({ ...error, email_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("email_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_cand) {
      setError({ ...error, ville_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_cand) {
      setError({ ...error, province_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("province_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_cand) {
      setError({ ...error, adresse_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_cand) {
      setError({ ...error, cp_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_cand) {
      setError({ ...error, tel_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.categ_permis) {
      setError({ ...error, categ_permis: "ce champs est réquis !" });
      const input = document.getElementsByName("categ_permis")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.date_insc_cand) {
      setError({ ...error, date_insc_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("date_insc_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.photo_cand === null) {
      setError({ ...error, photo_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("photo_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(candidat_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("enregistrement reussi !");
          getCandidats();
          setImage(null);
          setcin(null);
          setcertificat(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_cand = msg.nom_cand;
            apiErrors.prenom_cand = msg.prenom_cand;
            apiErrors.cin_cand = msg.cin_cand;
            apiErrors.photo_cand = msg.photo_cand;
            apiErrors.sexe_cand = msg.sexe_cand;
            apiErrors.naiss_cand = msg.naiss_cand;
            apiErrors.nationalite_cand = msg.nationalite_cand;
            apiErrors.fonction_memb = msg.fonction_memb;
            apiErrors.adresse_cand = msg.adresse_cand;
            apiErrors.ville_cand = msg.ville_cand;
            apiErrors.active_cand = msg.active_cand;
            apiErrors.province_cand = msg.province_cand;
            apiErrors.cp_cand = msg.cp_cand;
            apiErrors.pays_cand = msg.pays_cand;
            apiErrors.tel_cand = msg.tel_cand;
            apiErrors.email_cand = msg.email_cand;
            apiErrors.categ_permis = msg.categ_permis;
            apiErrors.date_insc_cand = msg.date_insc_cand;
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
            setImage(null);
          }
          if (error.response.status == 401) {
            toast.info("Vous n'êtes pas autorisée a effectuer cette action !");
          }
        });
      setError(apiErrors);
    }
  };
  const handleUpdate = () => {
    if (!formValues.nom_cand) {
      setError({ ...error, nom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_cand) {
      setError({ ...error, prenom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_cand) {
      setError({ ...error, cin_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_cand === "") {
      setError({ ...error, sexe_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_cand === true) {
      setFormValues({ ...formValues, sexe_cand: "1" });
    } else if (formValues.sexe_cand === false) {
      setFormValues({ ...formValues, sexe_cand: "0" });
    } else if (!formValues.naiss_cand) {
      setError({ ...error, naiss_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_cand) {
      setError({ ...error, nationalite_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_cand) {
      setError({ ...error, email_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("email_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_cand) {
      setError({ ...error, ville_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_cand) {
      setError({ ...error, province_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("province_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_cand) {
      setError({ ...error, adresse_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_cand) {
      setError({ ...error, cp_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_cand) {
      setError({ ...error, tel_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.categ_permis) {
      setError({ ...error, categ_permis: "ce champs est réquis !" });
      const input = document.getElementsByName("categ_permis")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.date_insc_cand) {
      setError({ ...error, date_insc_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("date_insc_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (typeof formValues.photo_cand === "string") {
      const { photo_cand, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    } else if (typeof formValues.scan_certifica_cand === "string") {
      const { scan_certifica_cand, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    } else if (typeof formValues.scan_cin_can === "string") {
      const { scan_cin_can, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    }else {
      alert(JSON.stringify(formValues))
      // setLoad(true);
      // const apiErrorss = {};
      // axios
      //   .post(candidat_API + `/${formValues.id_cand}?_method=PUT`, formValues, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     setLoad(false);
      //     setShow(false);
      //     toast.success("Modification reussi !");
      //     getCandidats();
      //     setImage(null);
      //     setcin(null);
      //     setcertificat(null);
      //     setFormValues(vide);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     const msg = error.response.data;
      //     setLoad(false);
      //     if (error.response.status == 401) {
      //       toast.info("Vous n'êtes pas autorisée a effectuer cette action !");
      //     }
      //     if (msg) {
      //       apiErrorss.nom_cand = msg.nom_cand;
      //       apiErrorss.prenom_cand = msg.prenom_cand;
      //       apiErrorss.cin_cand = msg.cin_cand;
      //       apiErrorss.photo_cand = msg.photo_cand;
      //       apiErrorss.sexe_cand = msg.sexe_cand;
      //       apiErrorss.naiss_cand = msg.naiss_cand;
      //       apiErrorss.nationalite_cand = msg.nationalite_cand;
      //       apiErrorss.fonction_memb = msg.fonction_memb;
      //       apiErrorss.adresse_cand = msg.adresse_cand;
      //       apiErrorss.ville_cand = msg.ville_cand;
      //       apiErrorss.active_cand = msg.active_cand;
      //       apiErrorss.province_cand = msg.province_cand;
      //       apiErrorss.cp_cand = msg.cp_cand;
      //       apiErrorss.pays_cand = msg.pays_cand;
      //       apiErrorss.tel_cand = msg.tel_cand;
      //       apiErrorss.email_cand = msg.email_cand;
      //       apiErrorss.categ_permis = msg.categ_permis;
      //       apiErrorss.date_insc_cand = msg.date_insc_cand;
      //     }
      //   });
      // setError(apiErrorss);
    }
  };

  const getCandidats = () => {
    setLoad(true);
    axios
      .get(candidat_API + "?order=asc&limit=")
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllOrganisme = () => {
    axios
      .get(organisme_API + "?order=asc&limit=")
      .then((rep) => {
        console.log(rep);
        setdataorg(rep.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCateg = () => {
    axios
      .get(CATEGORIE_API)
      .then((rep) => {
        console.log(rep);
        setcategorie(rep.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Supprimer = () => {
    setsupp(false);

    for (let index = 0; index < selectedRows.length; index++) {
      const id = selectedRows[index];
      axios
        .delete(candidat_API + "/" + id)
        .then((rep) => {
          console.log(rep);

          if (index === selectedRows.length - 1) {
            toast.success("element(s) supprimé(s)");
            getAllOrganisme();
            handleClearRows();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401) {
            toast.info(err.response.data.message);
            handleClearRows();
          }
          toast.error("element(s) pas suppprimé");
        });
    }
  };
  useEffect(() => {
    getCandidats();
    getAllCateg();
    getAllOrganisme();
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => handleSubmit()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Les candidats{" "}
          </h2>
        </div>
        <div className="d-flex gap-1">
          <Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              setcin(null);
              setcertificat(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouvel candidat
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              setcin(null);
              setcertificat(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faFilter} className="" />
          </Button>
        </div>
      </div>

      <div className="">
        <DataTable
          title={<p className="fs-4">Liste des candidats</p>}
          columns={columns}
          data={data}
          selectableRows
          clearSelectedRows={toggledClearRows}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows.map((row) => row.id_cand));
            setcandidatSelect(rows.selectedRows.map((row) => row))
          }}
          progressPending={load}
          selectedRow={selectedRows}
          contextActions={
            <div className="d-flex">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  settoggledClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>
              <button
                className="btn btn-cyan me-2"
                onClick={() => {
                  handleValidation();
                  
                }}
              >
                <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                Valider
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-2">
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">Nom</label>
                      <input
                        className={
                          "form-control " + (error.nom_cand && "is-invalid")
                        }
                        name="nom_cand"
                        value={formValues.nom_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir nom "
                      />
                      {error.nom_cand ? (
                        <div className="invalid-feedback">{error.nom_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Prenom</label>
                      <input
                        className={
                          "form-control " + (error.prenom_cand && "is-invalid")
                        }
                        name="prenom_cand"
                        value={formValues.prenom_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir prenom"
                      />
                      {error.prenom_cand ? (
                        <div className="invalid-feedback">
                          {error.prenom_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">CIN</label>
                      <input
                        className={
                          "form-control " + (error.cin_cand && "is-invalid")
                        }
                        name="cin_cand"
                        value={formValues.cin_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir CIN"
                      />
                      {error.cin_cand ? (
                        <div className="invalid-feedback">{error.cin_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Sexe</label>
                      <select
                        className={
                          "form-control " + (error.sexe_cand && "is-invalid")
                        }
                        name="sexe_cand"
                        value={
                          formValues.sexe_cand === true
                            ? "1"
                            : formValues.sexe_cand === false
                            ? "0"
                            : formValues.sexe_cand === ""
                            ? formValues.sexe_cand
                            : formValues.sexe_cand
                        }
                        onChange={handleChange}
                      >
                        <option>choisir sexe ...</option>
                        <option value={0}>Femme</option>
                        <option value={1}>Homme</option>
                      </select>
                      {error.sexe_cand ? (
                        <div className="invalid-feedback">
                          {error.sexe_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Date de naissance</label>
                      <input
                        className={
                          "form-control " + (error.naiss_cand && "is-invalid")
                        }
                        name="naiss_cand"
                        value={formValues.naiss_cand}
                        onChange={handleChange}
                        type="date"
                        placeholder="Chosir date"
                      />
                      {error.naiss_cand ? (
                        <div className="invalid-feedback">
                          {error.naiss_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Nationalité</label>
                      <input
                        className={
                          "form-control " +
                          (error.nationalite_cand && "is-invalid")
                        }
                        name="nationalite_cand"
                        value={formValues.nationalite_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir son nationalité"
                      />
                      {error.nationalite_cand ? (
                        <div className="invalid-feedback">
                          {error.nationalite_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Email</label>
                    <input
                      className={
                        "form-control " + (error.email_cand && "is-invalid")
                      }
                      name="email_cand"
                      value={formValues.email_cand}
                      onChange={handleChange}
                      type="email"
                      placeholder="Saisir email"
                    />
                    {error.email_cand ? (
                      <div className="invalid-feedback">{error.email_cand}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3 row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">
                          Scan CIN du candidat: recto verso{" "}
                        </label>
                        <input
                          className={
                            "form-control " +
                            (error.scan_cin_can && "is-invalid")
                          }
                          name="scan_cin_can"
                          id="scan_cin_can"
                          type="file"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        {error.scan_cin_can && (
                          <div className="invalid-feedback">
                            {error.scan_cin_can}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                      {cin && (
                        <div
                          onClick={() => {
                            setimagefull(cin);
                            setModalShow(!ModalShow);
                          }}
                        >
                          <span
                            className="avatar avatar-md"
                            style={{ backgroundImage: "url(" + cin + ")" }}
                          ></span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="my-3 row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">
                          Scan certificat du candidat{" "}
                        </label>
                        <input
                          className={
                            "form-control " +
                            (error.scan_certifica_cand && "is-invalid")
                          }
                          name="scan_certifica_cand"
                          id="scan_certifica_cand"
                          type="file"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        {error.scan_certifica_cand && (
                          <div className="invalid-feedback">
                            {error.scan_certifica_cand}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                    {certificat && (
                        <div
                          onClick={() => {
                            setimagefull(certificat);
                            setModalShow(!ModalShow);
                          }}
                        >
                          <span
                            className="avatar avatar-md"
                            style={{ backgroundImage: "url(" + certificat + ")" }}
                          ></span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">Ville</label>
                      <input
                        className={
                          "form-control " + (error.ville_cand && "is-invalid")
                        }
                        name="ville_cand"
                        value={formValues.ville_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir ville"
                      />
                      {error.ville_cand ? (
                        <div className="invalid-feedback">
                          {error.ville_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Province</label>
                      <input
                        className={
                          "form-control " +
                          (error.province_cand && "is-invalid")
                        }
                        name="province_cand"
                        value={formValues.province_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir province"
                      />
                      {error.province_cand ? (
                        <div className="invalid-feedback">
                          {error.province_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12">
                      <label className="form-label">Pays</label>
                      <input
                        className={
                          "form-control " + (error.pays_cand && "is-invalid")
                        }
                        name="pays_cand"
                        value={formValues.pays_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir adresse du candidat"
                      />
                      {error.pays_cand ? (
                        <div className="invalid-feedback">
                          {error.pays_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12 col-lg-8">
                      <label className="form-label">Adresse</label>
                      <input
                        className={
                          "form-control " + (error.adresse_cand && "is-invalid")
                        }
                        name="adresse_cand"
                        value={formValues.adresse_cand}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir adresse du candidat"
                      />
                      {error.adresse_cand ? (
                        <div className="invalid-feedback">
                          {error.adresse_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className=" col-12 col-lg-4">
                      <label className="form-label">Code postal</label>
                      <input
                        className={
                          "form-control " + (error.cp_cand && "is-invalid")
                        }
                        name="cp_cand"
                        value={formValues.cp_cand}
                        onChange={handleChange}
                        type="number"
                        placeholder="son code postal"
                      />
                      {error.cp_cand ? (
                        <div className="invalid-feedback">{error.cp_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Telephone (*)</label>
                    <InputMask
                      onKeyUp={(e) => {
                        console.log(e.target.value);
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            tel_cand: ["le champs telephone est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, tel_cand: [] });
                        }
                      }}
                      onChange={handleChange}
                      mask="+261 39 99 999 99"
                      type="text"
                      className={
                        "form-control " +
                        (error.tel_cand?.length > 0 && " is-invalid")
                      }
                      value={formValues.tel_cand}
                      name="tel_cand"
                      placeholder="Telephone"
                    />

                    {error.tel_cand &&
                      error.tel_cand.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          * {element}
                        </p>
                      ))}
                  </div>
                  <div className="row my-3">
                    <div className="col-lg-6">
                      <label className="form-label">Catégorie de permis</label>
                      <select
                        className={
                          "form-control " + (error.categ_permis && "is-invalid")
                        }
                        name="categ_permis"
                        value={formValues.categ_permis}
                        onChange={handleChange}
                      >
                        <option>Chosir catégorie permis ...</option>
                        {categorie.map((el, index) => (
                          <option value={el.categ}>{el.categ}</option>
                        ))}
                      </select>
                      {error.categ_permis ? (
                        <div className="invalid-feedback">
                          {error.categ_permis}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">
                        Date d'inscription du candidat
                      </label>
                      <input
                        className={
                          "form-control " +
                          (error.date_insc_cand && "is-invalid")
                        }
                        name="date_insc_cand"
                        value={formValues.date_insc_cand}
                        onChange={handleChange}
                        type="date"
                        placeholder="Chosir date"
                      />
                      {error.date_insc_cand ? (
                        <div className="invalid-feedback">
                          {error.date_insc_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">PHOTO </label>
                        <input
                          className={
                            "form-control " + (error.photo_cand && "is-invalid")
                          }
                          name="photo_cand"
                          id="photo_cand"
                          type="file"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        {error.photo_cand && (
                          <div className="invalid-feedback">
                            {error.photo_cand}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                      {image && (
                        <div
                          onClick={() => {
                            setimagefull(image);
                            setModalShow(!ModalShow);
                          }}
                        >
                          <span
                            className="avatar avatar-md"
                            style={{ backgroundImage: "url(" + image + ")" }}
                          ></span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => (mode ? handleUpdate() : handleSubmit())}
          >
            {mode ? <>Modifier</> : <>Enregistrer</>}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showvalid}
        onHide={()=>{handleValidation();
            handleClearRows()
     }}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Confirmation d'envoi des candidatures aux examens </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex align-item-stretch">
              <div className="w-100 my-2 px-3">
                <p>Epreuve précedemment raté pour ceux qui fait le repassage</p>
                <select
                  className={"form-control " + (error.nom_org && "is-invalid")}
                  name="nom_org"
                >
                  <option value="">Epreuve du 10-10-22</option>
                  <option value="">Epreuve du 10-12-22</option>
                  <option value="">Epreuve du 9-03-22</option>
                </select>
                {error.nom_org ? (
                  <div className="invalid-feedback">{error.nom_org}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="">
              <DataTable
                title={
                  <p className="fs-2">Liste des candidats sélectionnées</p>
                }
                columns={columns_valid}
                data={candidatSelect}
                contextActions={
                  <button
                    className="btn btn-cyan me-2"
                    onClick={() => {
                      handleValidation();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                }}
                pagination
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleValidation(); handleClearRows()}}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => (mode ? handleUpdate() : handleSubmit())}
          >
            {mode ? <>Modifier</> : <>Valider</>}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={ModalShow} onHide={ModalShowclose} size="md">
        <Modal.Body>
          <img
            src={imagefull} onError={ModalShowclose}
            alt={"version grandeur nature"}
            style={{ width: "100%", height: "100%", transform: `rotate(${rotation}deg)` }}
          />
          <div className="w-100">
                <button type="button" onClick={handleRotate} className="btn btn-primary">Tourner <FontAwesomeIcon className="ms-2" icon={faRotate}/></button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
