import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { mdpOublierByEmail } from "../../service/config";
import * as YUP from "yup";
import { toast } from "react-toastify";

export default function RecuperationMDP() {
  const schemaForm = YUP.object().shape({
    email: YUP.string()
    .email("Veuillez renseigner un email valide !")
    .required("Ce champs est obligatoire !"),
  });
  const [show, setshow] = useState(false);
  const [load, setLoad] = useState(false);
  const [form, setform] = useState({
    email: "",
  });
  const [error, seterror] = useState({
    email: "",
  });
  const handleshow = () => {
    setshow(!show);
  };
  const handleOptionChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setform({ ...form, [name]: value });
    seterror({...error,[name]: ""});
  };
  const verif = async () => {
    seterror({});
    const champs = Object.keys(form);
    for (let i = 0; i < champs.length; i++) {
      const name_champs = champs[i];
      try {
        await schemaForm
          .validateAt(name_champs, form, { abortEarly: true })
          .then(() => {
         
             Recoverpass()

          });
      } catch (error) {
       
        if (error.path === name_champs) {
          seterror({ [name_champs]: error.message });
          return;
        }
      }
    }
  };
  const Recoverpass = async () => {
    let apiErrors = {};
    axios
    .post(mdpOublierByEmail, form)
    .then((rep) => {
      console.log(rep);
      toast.success("nouveau mot de passe envoyer !");
      setshow(false)
      setLoad(false);
    })
    .catch((e) => {
      const msg = e.response.data;
      setLoad(false);
      if (msg) {
        let champs = Object.keys(msg);
        for (let i = 0; i < champs.length; i++) {
          const element = champs[i];
          if (msg[element]) {
            apiErrors[element] = msg[element];
          }
        }
      }
      toast.error("un erreur est survenu !");
      seterror(apiErrors)
    });
  };

  return (
    <>
      <p className="text-muted text-center fs-5" onClick={() => handleshow()}>
        mot de passe oublié ?
      </p>
      <Modal show={show} size="sm" onHide={handleshow}>
        <Modal.Header closeButton>Récuperer mon mot de passe</Modal.Header>
        <Modal.Body>
          <p className="">
            En remplissant ce champs, votre nouveau mot de passe sera envoyé sur votre adresse email :{" "}
          </p>
          <div className="d-flex flex-column gap-3">
            <div>
              <input
                type="email"
                name="email"
                placeholder="saisir ici l'adresse email liée à votre compte"
               onChange={handleOptionChange}
                className={"form-control " + (error.email && "is-invalid")}
                id=""
              />
              <div className="invalid-feedback">{error.email}</div>
            </div>
            <Button variant="primary text-end" disabled={load} onClick={() => verif()}>
              Envoyer le code
            </Button>
          </div>
          {/* <div>
          <label>
            <input
              type="radio"
              value="phone"
              checked={form === 'phone'}
              onChange={handleOptionChange}
              className='form-control'
            />
            téléphone : Si vous souhaitez recevoir votre code de récupération sur votre téléphone 
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="email"
              checked={form === 'email'}
              onChange={handleOptionChange}
              className='form-control'
            />
            Envoyer par e-mail : Si vous préférez recevoir votre code de récupération par e-mail
          </label>
        </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
