import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Link, useNavigate } from "react-router-dom";
import { file_url } from "../../../service/config";

import { register } from "swiper/element/bundle";
import { useRef } from "react";
import sar1 from "./arrow-back-ios-new-rounded.svg";
import sar2 from "./arrow-forward-ios.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { truncate, truncateTitle } from "../../../outils/Truncate";
import './stylecard.css'
// register Swiper custom elements
register();

export default function Carrousel(props) {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      slidesPerView: "auto",
      pagination: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      injectStyles: [
        `
     
        .swiper-button-next,
          .swiper-button-prev {
           
            background-position: center;
            background-size: 25px;
            background-repeat: no-repeat;
            color: #22577A;
          }

          .swiper-button-prev {
            background-image: url(${sar1});
            background-color: #41A9E0;
            border-radius:1px;
            transform:translate(-5px,0px);
          }

          .swiper-button-next {
            background-image: url(${sar2});
            background-color: #41A9E0;
            border-radius:1px;
            transform:translate(5px,0px);
          }

          .swiper-button-next::after,
          .swiper-button-prev::after {
            content: "";
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }

  const data = props.data;
  const sortByProperty = (array, property) =>
    [...array].sort((a, b) =>
      typeof a[property] === 'string'
        ? a[property].localeCompare(b[property])
        : a[property] - b[property]
    );
 
  return (
    <div className="">
      {props.type == "actu" ? (
        <swiper-container className="" ref={swiperRef} init="false">
          {props.data?.map((element, index) => (
            <swiper-slide key={index} className="mb-3">
              <div
                style={{ height: "98%", position: "relative" }}
                onClick={() =>
                  navigate("/détails actualités/" + element.id_actu)
                }
                className="bg-card m-1 d-flex flex-column align-items-stretch card effet position-relative  rounded-3"
              >
                <span
                  className="fst-italic"
                  style={{
                    fontSize: "10px",
                    position: "absolute",
                    bottom: "8px",
                    right: "5px",

                    color: "gray",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClockFour}
                    color="gray"
                    className="bg-dark rounded-circle"
                  />{" "}
                  {moment(element.created_at).locale("fr").fromNow()}
                </span>
                <div className=" m-0 p-0">
                  <div
                    className="img-responsive card-img-top"
                    style={{
                      height: "90px !important",
                      width: "100%",
                      backgroundSize:'cover',
                      backgroundImage:
                        "url(" + file_url + element.img_actu + ")",
                    }}
                  >
                    {" "}
                  </div>
                  <div className="ombre"></div>
                </div>
                <div className="card-body">
                  <h3 className="card-title fw-bold mb-2 text-truncate">
                    {element.titre_actu}
                  </h3>

                  <div className="my-2 d-flex flex-wrap gap-1">
                    {Separer(element.tag_actu)}
                  </div>
                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-4-lines"
                     
                    >
                    {element.resum_actu &&
                     
                        element.resum_actu === null ? "" : element.resum_actu
                      }
                  </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      ) : props.type == "sensi" ? (
        <swiper-container className="" ref={swiperRef} init="false">
          {props.data?.map((element, index) => (
            <swiper-slide
              style={{ height: "90%" }}
              key={index}
              className="mb-3"
            >
              <div
                style={{ height: "90%" }}
                className="card bg-card effet position-relative rounded-3 m-1 "
              >
                <div className="anime-effet m-0 p-0">
                  <div
                    className="img-responsive img-responsive-21x9 card-img-top"
                    style={{
                      height: "200px",
                      backgroundSize:'cover',
                      backgroundImage:
                        "url(" + file_url + element.img_sens + ")",
                    }}
                  ></div>
                  <div className="ombre"></div>
                </div>
                <div className="card-body">
                  <Link
                    state={props.data}
                    to={"/détails sensibilisation/" + element.id_sens}
                    className="text-decoration-none"
                  >
                    <h3 className="card-title mb-2 text-truncate">
                      {element.titre_sens}
                    </h3>
                  </Link>
                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-4-lines"
                     
                    >
                    {element.desc_sens}
                  </p>
                  </div>
                </div>
              
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      ) : props.type == "exe" ? (
        <swiper-container className="" ref={swiperRef} init="false">
          {props.data?.map((element, index) => (
            <swiper-slide key={index} className="mb-3">
              <div
                style={{ height: "90%" }}
                className="card bg-card effet  position-relative  rounded-3 m-1"
              >
                <div className="anime-effet m-0 p-0">
                  <div
                    className="img-responsive img-responsive-21x9 card-img-top"
                    style={{
                      position: "relative",
                      height: "140px",
                        backgroundSize:'cover',
                      backgroundImage:
                        "url(" + file_url + element.img_mod + ")",
                    }}
                  >
                    <Tag
                      className="bg-info"
                      style={{ position: "absolute", top: "4px", right: "3px" }}
                    >
                      {element.lang_mod}
                    </Tag>
                  </div>
                  <div className="ombre"></div>
                </div>
                <div className="card-body pb-0">
                  <Link
                    to={"/exercice/" + element.id_mod}
                    state={{ module: element }}
                    className="text-decoration-none"
                  >
                    <h3 className="card-title mb-2 text-truncate">{element.titre_mod}</h3>
                  </Link>

                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-3-lines mb-0"
                     
                    >
                    {element.objectif_mod}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <p
                    className="me-3 fw-bold text-secondary fst-italic"
                    style={{ fontSize: "12px" }}
                  >Nombre d'exercices : {element.num_questionnaires}</p>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      ) : props.type == "lesson" ? (
        <swiper-container className="" ref={swiperRef} init="false">
          {props.data?.map((element, index) => (
            <swiper-slide key={index} className="mb-3">
              <div
                style={{ height: "90%" }}
                className="card border-0 bg-card effet position-relative rounded-3 m-1"
              >
                <div className="anime-effet m-0 p-0">
                  <div
                    className="img-responsive img-responsive-21x9 card-img-top"
                    style={{
                      position: "relative",
                      height: "150px",
                      minHeight:'150px',
                      maxHeight: "150px",
                      backgroundSize:'cover',
                      backgroundImage:
                        "url(" + file_url + element.img_les + ")",
                    }}
                  ></div>
                  <div className="ombre"></div>
                </div>
                <div className="card-body">
                  <Link
                    to={`/lessons/${element.id_les}`}
                    state={{ rech: "oui", lessonrech: element.id_les }}
                    className="text-decoration-none"
                  >
                    <h3 className="card-title mb-2 text-truncate">
                      {element.titre_les}
                    </h3>
                  </Link>

                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-3-lines"
                     
                    >
                      {element.desc_les}
                    </p>
                    
                  </div>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      ) : (
        <swiper-container auto className="" ref={swiperRef} init="false">
          {sortByProperty(props.data, "categ")?.map((element, index) => (
            <swiper-slide key={index} className="mb-3 ">
              <div
                onClick={() =>
                  navigate("/exerciceBycateg/" + element.id_categ, {
                    state: { categorie: element },
                  })
                }
                className="card effet bg-card m-2"
                style={{ height: "90%" }}
              >
                <div className="card-header section2 d-flex justify-content-center align-items-center">
                <div className="border border-white border-2 p-2 rounded-5">
                <img
                        src={file_url + element.img_categ}
                        width={100}
                        height={20}
                        alt="Permis"
                        className="rounded "
                      />
                </div>
                </div>
                <div className="card-body p-md-2 p-sm-0 ">
                  <div className="px-2 w-100 ">
                    <div className="">
                      <h3 className="fw-bold mb-1 text-primary text-center">
                        Permis {element.categ}{" "}
                        {element.equi_categ && <>({element.equi_categ})</>}
                      </h3>
                    </div>
                   
                  </div>
                  <div className="mt-2 px-3">
                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-4-lines"
                     
                    >
                      {element.desc_categ}
                    </p>
                    {/* <p className="text-muted" >{truncate(el.desc_categ)}</p> */}
                  </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      )}
    </div>
  );
}
