import {
  faAdd,
  faEdit,
  faHouse,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { file_url, organisme_API } from "../../../service/config";

export default function Auto_Ecole() {
  const columns = [
    {
      name: "Nom",
      selector: (row) => row.nom,
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {width:'140px',
      name: "Téléphone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {width:'100px',
      name: "Agrement",
      selector: (row) => row.apprenant,
      sortable: true,
    },
    {
      width:'115px',
      name: "statut",
      selector: (row) => row.status,
      sortable: true,
    },  
    {
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
                // alert(JSON.stringify(row))
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}/${row.logo_org}`);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

 
  const [data,setdata]=useState(
    [
      {
        "nom": "Auto-Ecole Roulage",
        "adresse": "12 Rue des Fleurs, Antananarivo",
        "telephone": "+261 32 12 345 67",
        "email": "roulage@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      },
      {
        "nom": "Auto-Ecole de l'Avenir",
        "adresse": "2 Rue des Baobabs, Antsirabe",
        "telephone": "+261 34 98 765 43",
        "email": "avenir@example.com",
        "apprenant": "non valide",
        "status": "sanctionnee"
      },
      {
        "nom": "Auto-Ecole de la Route",
        "adresse": "7 Rue de la Mer, Toamasina",
        "telephone": "+261 33 45 678 90",
        "email": "laroute@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      },
      {
        "nom": "Auto-Ecole Les Conducteurs",
        "adresse": "4 Rue des Coquelicots, Antsiranana",
        "telephone": "+261 34 23 456 78",
        "email": "lesconducteurs@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      },
      {
        "nom": "Auto-Ecole Turbo",
        "adresse": "1 Rue des Lilas, Antananarivo",
        "telephone": "+261 32 87 654 32",
        "email": "turbo@example.com",
        "apprenant": "non valide",
        "status": "sanctionnee"
      },
      {
        "nom": "Auto-Ecole Accelere",
        "adresse": "8 Rue des Roses, Fianarantsoa",
        "telephone": "+261 34 56 789 01",
        "email": "accelere@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      },
      {
        "nom": "Auto-Ecole de l'Est",
        "adresse": "5 Rue des Narcisses, Antsirabe",
        "telephone": "+261 32 34 567 89",
        "email": "lest@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      },
      {
        "nom": "Auto-Ecole Flash",
        "adresse": "9 Rue des Tulipes, Mahajanga",
        "telephone": "+261 34 90 123 45",
        "email": "flash@example.com",
        "apprenant": "non valide",
        "status": "sanctionnee"
      },
      {
        "nom": "Auto-Ecole Pilote",
        "adresse": "6 Rue des Marguerites, Antsiranana",
        "telephone": "+261 32 76 543 21",
        "email": "pilote@example.com",
        "apprenant": "valide",
        "status": "non sanctionnee"
      }
  ])
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    nom_org: "",
    active_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    logo_org: null,
  });
  const [vide, setvide] = useState({
    nom_org: "",
    active_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    logo_org: null,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState(null);
 
  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFormValues({ ...formValues, logo_org: e.target.files[0] });
      setError({ ...error, logo_org: null });
    }
  };

  const handleSubmit = () => {
    if (!formValues.nom_org) {
      setError({ ...error, nom_org: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_org) {
      setError({ ...error, email_org: "ce champs est réquis !" });
      const input = document.getElementsByName("email_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_org) {
      setError({ ...error, adresse_org: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_org) {
      setError({ ...error, ville_org: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_org) {
      setError({ ...error, province_org: "ce champs est réquis !" });
      const input = document.getElementsByName("province_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_org) {
      setError({ ...error, cp_org: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.pays_org) {
      setError({ ...error, pays_org: "ce champs est réquis !" });
      const input = document.getElementsByName("pays_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.web_org) {
      setError({ ...error, web_org: "ce champs est réquis !" });
      const input = document.getElementsByName("web_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_org) {
      setError({ ...error, tel_org: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.type_org) {
      setError({ ...error, type_org: "ce champs est réquis !" });
      const input = document.getElementsByName("type_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.logo_org === null) {
      setError({ ...error, logo_org: "ce champs est réquis !" });
      const input = document.getElementsByName("logo_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(organisme_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("enregistrement reussi !");
          getAllOrganisme()
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_org = msg.nom_org;
            apiErrors.active_org = msg.active_org;
            apiErrors.email_org = msg.email_org;
            apiErrors.adresse_org = msg.adresse_org;
            apiErrors.ville_org = msg.ville_org;
            apiErrors.province_org = msg.province_org;
            apiErrors.cp_org = msg.cp_org;
            apiErrors.pays_org = msg.pays_org;
            apiErrors.web_org = msg.web_org;
            apiErrors.tel_org = msg.tel_org;
            apiErrors.type_org = msg.type_org;
            apiErrors.logo_org = msg.logo_org;
          }
          if (error.response.status == 500) {
            toast.error("information enregistrer mais email pas envoyer");
            setShow(false);
            setFormValues(vide);
            setImage(null);
          }
        });
      setError(apiErrors);
    }
  };
  const handleUpdate = () => {
    if (!formValues.nom_org) {
      setError({ ...error, nom_org: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_org) {
      setError({ ...error, email_org: "ce champs est réquis !" });
      const input = document.getElementsByName("email_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_org) {
      setError({ ...error, adresse_org: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_org) {
      setError({ ...error, ville_org: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_org) {
      setError({ ...error, province_org: "ce champs est réquis !" });
      const input = document.getElementsByName("province_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_org) {
      setError({ ...error, cp_org: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.pays_org) {
      setError({ ...error, pays_org: "ce champs est réquis !" });
      const input = document.getElementsByName("pays_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.web_org) {
      setError({ ...error, web_org: "ce champs est réquis !" });
      const input = document.getElementsByName("web_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_org) {
      setError({ ...error, tel_org: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.type_org) {
      setError({ ...error, type_org: "ce champs est réquis !" });
      const input = document.getElementsByName("type_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if(typeof formValues.logo_org ==='string'){
        const {logo_org, ...formfiltrer} = formValues
setFormValues(formfiltrer)
    } else {
        // alert(JSON.stringify(formValues))
      setLoad(true);
      const apiErrors = {};
      axios
        .post(organisme_API+`${formValues.id_org}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("Modification reussi !");
          getAllOrganisme()
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_org = msg.nom_org;
            apiErrors.active_org = msg.active_org;
            apiErrors.email_org = msg.email_org;
            apiErrors.adresse_org = msg.adresse_org;
            apiErrors.ville_org = msg.ville_org;
            apiErrors.province_org = msg.province_org;
            apiErrors.cp_org = msg.cp_org;
            apiErrors.pays_org = msg.pays_org;
            apiErrors.web_org = msg.web_org;
            apiErrors.tel_org = msg.tel_org;
            apiErrors.type_org = msg.type_org;
            apiErrors.logo_org = msg.logo_org;
          }
        });
      setError(apiErrors);
    }
  };

  const getAllOrganisme = () => {
    setLoad(true);
    axios
      .get(organisme_API + "?order=asc&limit=4")
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => handleSubmit()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Auto-école{" "}
          </h2>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouvel auto-école
          </Button>
        </div>
      </div>

      <div className="">
        <DataTable
          title={<p className="fs-4">Liste des auto-écoles</p>}
          columns={columns}
          data={data}
          selectableRows
          selectedRow={selectedRows}
          progressPending={load}
          contextActions={
            <button
              className="btn btn-danger me-2"
              onClick={() => {
                setsupp(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows);
          }}
          pagination
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="">
                    <label className="form-label">Nom</label>
                    <input
                      className={
                        "form-control " + (error.nom_org && "is-invalid")
                      }
                      name="nom_org"
                      value={formValues.nom_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir nom"
                    />
                    {error.nom_org ? (
                      <div className="invalid-feedback">{error.nom_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Adresse</label>
                    <input
                      className={
                        "form-control " + (error.email_org && "is-invalid")
                      }
                      name="email_org"
                      value={formValues.email_org}
                      onChange={handleChange}
                      type="email"
                      placeholder="Saisir adresse"
                    />
                    {error.email_org ? (
                      <div className="invalid-feedback">{error.email_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Télephone</label>
                    <input
                      className={
                        "form-control " + (error.adresse_org && "is-invalid")
                      }
                      name="adresse_org"
                      value={formValues.adresse_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir numero telephone"
                    />
                    {error.adresse_org ? (
                      <div className="invalid-feedback">
                        {error.adresse_org}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Email</label>
                    <input
                      className={
                        "form-control " + (error.ville_org && "is-invalid")
                      }
                      name="ville_org"
                      value={formValues.ville_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir email"
                    />
                    {error.ville_org ? (
                      <div className="invalid-feedback">{error.ville_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Apprenant</label>
                    <input
                      className={
                        "form-control " + (error.province_org && "is-invalid")
                      }
                      name="province_org"
                      value={formValues.province_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir apprenant"
                    />
                    {error.province_org ? (
                      <div className="invalid-feedback">
                        {error.province_org}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Status</label>
                    <input
                      className={
                        "form-control " + (error.province_org && "is-invalid")
                      }
                      name="province_org"
                      value={formValues.province_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir status"
                    />
                    {error.province_org ? (
                      <div className="invalid-feedback">
                        {error.province_org}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => (mode ? handleUpdate() : handleSubmit())}
          >
            {mode ? <>Modifier</> : <>Enregistrer</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
