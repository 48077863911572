import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import axios from "axios";
import { toast } from "react-toastify";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Pagination from "../../../outils/Pagination";
import { useRef } from "react";
import { MODULE_API, Questionnaire_API } from "../../../service/config";

const animatedComponents = makeAnimated();

export default function Modifquestionnaire(props) {
  const [show, setshow] = useState(false);
  const [block, setblock] = useState(true);
  const handleshow = () => {
    setshow(!show);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
   try {
    
   } catch (error) {
    console.log(error)
    setshow(false)
   }

    
  };

  const ref = useRef(null);
  const [ID, setID] = useState(null);
  const [loadModule, setLoadModule] = useState(false);
  const [choix, setchoix] = useState(null);
  let dataLes = [];
  const [idLF, setidLF] = useState(null);
  const [load, setLoad] = useState(false);
  const [barcolor, setBarcolor] = useState("blue");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [error, setError] = useState([]);
  const [options, setOptions] = useState([]);
  const [questionnaire, setquestionnaire] = useState([]);
  const [affichageRep, setaffichageRep] = useState(null);
  const [lesson, setLesson] = useState({
    qcm_ques: "",
    dure_ques: null,
  });
  const vide = {
    qcm_ques: "",
    dure_ques: null,
    id_mod: "",
  };
  const [formValues, setFormValues] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const [image, setImage] = useState(null);
  function truncate(str) {
    if (str) {
      return str.length > 10 ? str.substring(0, 150) + "..." : str;
    }
  }

  const [ito, setIto] = useState([]);
  const change = (choice) => {
   if (choice) {
    setIto(choice);
    setFormValues({ ...formValues, id_mod: choice.value });
   }
  };




  const getAllModule = async () => {
    setBarcolor("blue");
    const getModules = [];
    try {
      await axios
        .get(MODULE_API)
        .then((response) => {
          console.log(response);
          const data = response.data.data;

          data.forEach((element) => {
            getModules.push({
              value: element.id_mod,
              label: element.titre_mod,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
      setOptions(getModules);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
   
    setLoad(true);
    const apiErrors = {};

    try {
      await axios
        .post(Questionnaire_API + "/" + formValues.id_ques + "?_method=PUT", formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          toast.success("modification reussi");
          setLoad(false);
          setshow(false)
          props.load()
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est sruvenu");
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.qcm_ques = msg.qcm_ques;
            apiErrors.dure_ques = msg.dure_ques;
            apiErrors.id_mod = msg.id_mod;
          }

          console.log(error);
        });
      setError(apiErrors);
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  // calls whenever state will be change , another approach
  useEffect(() => {
    getAllModule();
  }, []);
  useEffect(()=>{
setFormValues(props.exo)
  },[props.exo])

  useEffect(()=>{
   if (show) {
    let ioo = options.filter((el)=>el.value == props.exo.id_mod)
    change(ioo[0])
   }
  },[show])
 
  



  return (
    <div className="">
        <Button variant="info" onClick={()=>{if (props.mode=='create') {
       
        handleshow();
      }else{
        handleshow();
      }}}> <FontAwesomeIcon icon={faEdit}/></Button>
      <Modal
        show={show}
        onHide={handleshow}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h4 col-9">
            <h3>{props.type == 'reponse' ? 'Question Reponse': 'Choix Multiple'}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="my-3">
                          <label className="form-label">Question du qcm</label>
                          <CKEditor
                            editor={Editor}
                            data={formValues.qcm_ques}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log("ato");
                              // console.log( { event, editor, data } );
                              console.log(data);
                              if (data === "") {
                                setError({
                                  ...error,
                                  qcm_ques: "la question du qcm est réquise !",
                                });
                              } else {
                                setFormValues({ ...formValues, qcm_ques: data });
                                setError({ ...error, qcm_ques: null });
                              }
                            }}
                          />
                          {error.qcm_ques && (
                            <p className="text-danger">{error.qcm_ques}</p>
                          )}
                          {/* <input
                            className={
                              "form-control " + (error.qcm_ques && "is-invalid")
                            }
                            type="text"
                            name="qcm_ques"
                            value={lesson.qcm_ques}
                            onChange={handleChange}
                            placeholder="Saisir le question du questionnaire"
                            required
                          />
                          {error.qcm_ques && (
                            <p className="invalid-feedback">{error.qcm_ques}</p>
                          )} */}
                        </div>
                        <div className="my-3">
                          <label className="form-label">
                            Durée du qcm (en secondes)
                          </label>
                          <input
                            className={
                              "form-control " +
                              (error.dure_ques && "is-invalid")
                            }
                            type="text"
                            name="dure_ques"
                            value={formValues.dure_ques}
                            onChange={handleChange}
                            placeholder="Durée du questionnaire"
                            required
                          />
                          {error.dure_ques && (
                            <p className="invalid-feedback">
                              {error.dure_ques}
                            </p>
                          )}
                        </div>
                        <div className="my-3 row">
                          <div className="col">
                            <label className="form-label">Modules</label>
                            <Select
                              closeMenuOnSelect
                              components={animatedComponents}
                              defaultValue={[options[4], options[5]]}
                              name="id_mod"
                              placeholder="choix module"
                              options={options}
                              value={ito}
                              className="is-invalid"
                              onChange={(choice) => change(choice)}
                              noOptionsMessage={() => "plus d'options"}
                            />
                            {error.id_mod && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {error.id_mod}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                       {props.type == 'reponse' && <div className="my-3">
                      <label className="form-label">Réponse</label>
                      <input
                        className={
                          "form-control " + (error.val_ques && "is-invalid")
                        }
                        type="number"
                        name="val_ques"
                        value={formValues.val_ques}
                        onChange={handleChange}
                        placeholder="Saisir la réponse du questionnaire"
                        required
                      />
                      {error.val_ques && (
                        <p className="invalid-feedback">{error.val_ques}</p>
                      )}
                    </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshow(false)}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={load}
          onClick={()=>handleUpdate()}
          >
           Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
