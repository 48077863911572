import {
  faAdd,
  faBookBookmark,
  faEdit,
  faHouse,
  faRightToBracket,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  chapitres,
  chapitresByLesson,
  file_url,
  organisme_API,
} from "../../../service/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useLocation, useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BlockUI } from "primereact/blockui";

export default function GererChapitre() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [handleClearRow, sethandleClearRow] = useState(true);
  const clearRow = () => sethandleClearRow(!handleClearRow);
  const columns = [
    {
      name: "Titre",
      selector: (row) => row.titre_chap,
      sortable: true,
    },
    {
      width: "140px",
      name: "Ordre",
      selector: (row) => row.order_chap,
      format: (row) => <Tag>{row.order_chap}</Tag>,
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              getChapterByID(row);
              setError({});
              setmode(true);
              setFormValues(row);
              setShow(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState({ liste: true, button: false, form: false });
  const [formValues, setFormValues] = useState({
    id_les: state.id_les,
  });
  const [vide, setvide] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState(null);

  const handleSubmit = () => {
    setLoad({ ...load, button: true });
    const apiErrors = {};
    axios
      .post(chapitres, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setLoad({ ...load, button: false });
        setShow(false);
        toast.success("enregistrement reussi !");
        getAllChapter();
        setImage(null);
        setFormValues({ id_les: state.id_les });
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        setLoad({ ...load, button: false });
        if (msg) {
          setError(msg);
        }
        if (error.response.status == 500) {
          toast.error("information enregistrer mais email pas envoyer");
          setShow(false);
          setFormValues(vide);
          setImage(null);
        }
      });
  };
  const handleUpdate = () => {
    setLoad({ ...load, button: true });
    axios
      .post(chapitres + `/${formValues.id_chap}?_method=PUT`, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setLoad({ ...load, button: false });
        setShow(false);
        handleClose();
        toast.success("Modification reussi !");
        getAllChapter();
        setImage(null);
        setFormValues({ id_les: state.id_les });
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        handleClose();
        setLoad({ ...load, button: false });
        if (msg) {
          setError(msg);
        }
      });
  };
  const deleteChap = () => {
    setLoad({ ...load, button: true });
    selectedRows.map((el, i) => {
      axios
        .delete(chapitres + "/" + el.id_chap)
        .then((rep) => {
          toast.success("chapitre supprimé");
          setLoad({ ...load, button: false });
          handlesup();
          clearRow();
        })
        .catch((err) => {
          toast.error("un erreur est survenu !");
          handlesup();
          setLoad({ ...load, button: false });
          clearRow();
        });
    });
  };
  const getChapterByID = (row) => {
    setLoad({ ...load, form: true });
    axios
      .get(chapitres + "/" + row.id_chap)
      .then((rep) => {
        console.log(rep);

        setFormValues(rep.data.data);
        setLoad({ ...load, form: false });
      })
      .catch((err) => {
        console.log(err);
        setLoad({ ...load, form: false });
      });
  };
  const getAllChapter = () => {
    setLoad({ ...load, liste: true });
    axios
      .get(chapitresByLesson + state.id_les)
      .then((rep) => {
        console.log(rep);

        setdata(rep.data.data);
        setLoad({ ...load, liste: false });
      })
      .catch((err) => {
        console.log(err);
        setLoad({ ...load, liste: false });
      });
  };
  useEffect(() => {
    if (state == null) {
      navigate("/admin/gerer_lesson");
    } else {
      getAllChapter();
    }
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        scrollable
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load.button}
            onClick={() => deleteChap()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex">
        <h1 className="mb-3">{state?.titre_les}</h1>
      </div>

      <div className="">
        <DataTable
          title={
            <div className="py-3 d-flex flex-row align-items-center justify-content-between">
              <div className="">
                <h3 className="text-muted">
                  <FontAwesomeIcon icon={faBookBookmark} className="me-1" />
                  Liste des Chapitre{" "}
                </h3>
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    setmode(false);
                    setFormValues({ id_les: state?.id_les });
                    setImage(null);
                    handleShow();
                    setError({});
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} className="me-2" />
                  Nouveau chapitre
                </Button>
              </div>
            </div>
          }
          columns={columns}
          data={data}
          clearSelectedRows={handleClearRow}
          selectableRows
          selectedRow={selectedRows}
          progressPending={load.liste}
          contextActions={
            <button
              className="btn btn-danger me-2"
              onClick={() => {
                setsupp(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows);
          }}
          pagination
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <BlockUI blocked={load.form}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Formulaire </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <label className="form-label">Nom</label>
              <input
                className={"form-control " + (error.titre_chap && "is-invalid")}
                name="titre_chap"
                value={formValues.titre_chap}
                onChange={handleChange}
                type="text"
                placeholder="Saisir titre"
              />
              {error.titre_chap ? (
                <div className="invalid-feedback">{error.titre_chap}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Ordre :</label>
              <input
                className={"form-control " + (error.order_chap && "is-invalid")}
                name="order_chap"
                value={formValues.order_chap}
                onChange={handleChange}
                type="number"
                placeholder="précise l'ordre d'apparition"
              />
              {error.order_chap ? (
                <div className="invalid-feedback">{error.order_chap}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3 dansles">
              <label className="form-label">Détails de l'Actualité</label>
              <CKEditor
                editor={Editor}
                data={formValues?.desc_chap ? formValues?.desc_chap : ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                  // editor.enableReadOnlyMode("editor")
                  // const toolbar = editor.ui.view.toolbar.element;
                  // toolbar.style.display ='none'
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log("ato");
                  // console.log( { event, editor, data } );
                  console.log(data);
                  if (data === "") {
                    setError({
                      ...error,
                      desc_chap: "le details est réquis !",
                    });
                  } else {
                    setFormValues({ ...formValues, desc_chap: data });
                    setError({ ...error, desc_chap: null });
                  }
                }}
              />
              <p id="desc_chap"></p>
              {error.desc_chap && (
                <p name="desc_chap" className="text-danger">
                  {error.desc_chap}
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              disabled={load.button}
              onClick={() => (mode ? handleUpdate() : handleSubmit())}
            >
              {mode ? <>Modifier</> : <>Enregistrer</>}
            </Button>
          </Modal.Footer>
        </BlockUI>
      </Modal>
    </div>
  );
}
