import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SelectPower({
  formValues,
  className,
  setFormValues,
  setError,
  error,
  name,
  label,
  value,
  options,
  load,
  choix,
  placeholder,
}) {
  const animatedComponents = makeAnimated();
  const [data, setData] = useState([]);
  let text = "";
  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var nb = 0;
    text = "";
    setIto(choice)
    choice?.forEach((element) => {
      nb++;
      text += element.value + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, [name]: text });
        setError({ ...error, [name]: null });
      }
    });
  };
  const fetchData = () => {
    let optionsValue = [];

    if (options) {
      if (options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          const element = options[i];
          optionsValue.push({ value: element[value], label: element[label] });
        }
        setData(optionsValue);
      }
    }
  };

useEffect(()=>{
fetchData()
},[])
  useEffect(() => {
    if (choix) {
      let categ = choix?.split(",");
      let valueCat = [];
      for (let i = 0; i < categ.length; i++) {
        const element = categ[i];
        if (element) {
          valueCat.push({ value: element, label: element })
        }
      }
      console.log(valueCat)
      setIto(valueCat);
    } else {
      setIto(null);
    }
  }, [choix]);

  return (
    <div className="select-power">
      <Select
        closeMenuOnSelect
        components={animatedComponents}
        placeholder={placeholder}
        options={data}
        name={name}
        value={ito}
        className={"w-100"}
        onChange={(choice) => change(choice)}
        noOptionsMessage={() => "plus d'options"}
        isDisabled={load}
        isLoading={load}
        isMulti
      />
      {error[name] &&
        error[name].map((element) => (
          <p key={element} className="text-danger">
            * {element}
          </p>
        ))}
    </div>
  );
}
