import {
  faCheck,
  faEdit,
  faUser,
  faUserAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import image from "../../../assets/image/imgtest/image";
import { PROFILE_API } from "../../../service/config";
import EditAccount from "./EditAccount";
import EditProfileInfo from "./EditProfileInfo";

export default function Compte() {
  const [user, setUser] = useState({});
  const [refresh,setrefresh] = useState(null)
  const [load, setLoad] = useState(true);
  const [edit,setedit] = useState(false);
  // const [seed, setSeed] = useState(1);
  // const reset = () => {
  //      setSeed(Math.random());
  //  }

  const profile = async () => {
    setLoad(true);
    try {
      await axios
        .get(PROFILE_API)
        .then((response) => {
          console.log(response.data);
          setUser(response.data);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    profile();
  }, [refresh]);

  return (
    edit ? <EditAccount data={user} setedit={setedit}/> :<div className="container-fluid py-2">
      <div className="container-lg container-fluid">
        <div className="py-3 rounded-2 h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-9">
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h2>
                      Mon compte
                    </h2>
                  </div>
                  {load ? (
                    <div className="col-12 d-flex justify-content-center my-5">
                      <Bars
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="card-body">
                     <div className="row g-5">
                          <div className="col-4">
                            <FontAwesomeIcon icon={faUserCircle} className=" h-100 w-100 object-fit-cover"/>
                          </div>
                          <div className="col-lg col-12">
                          <table className="border-none">
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              Status :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={
                                "fw-bold " +
                                (user.status_util === "Activer" &&
                                  "text-success ") +
                                (load && "placeholder")
                              }
                            >
                              {user.status_util}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              Nom et prenom :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={"fw-bold " + (load && "placeholder")}
                            >
                              {user.nom_util} {user.prenom_util}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              CIN :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={"fw-bold " + (load && "placeholder")}
                            >
                              {user.cin_util ? user.cin_util : 'pas fournis'}{" "}
                             
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              email :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={"fw-bold " + (load && "placeholder")}
                            >
                              {user.email}{" "}
                              {user.is_email_verified &&
                                (user.is_email_verified ? (
                                  <FontAwesomeIcon
                                    color="green"
                                    icon={faCheck}
                                  />
                                ) : (
                                  <button className="btn btn-danger">
                                    email pas encore verifier, verifier
                                  </button>
                                ))}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              Type :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={"fw-bold " + (load && "placeholder")}
                            >
                              {user.type_util}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-3">
                            <span className="fw-light text-muted me-2">
                              Telephone :
                            </span>
                          </th>
                          <th className="pb-3">
                            <span
                              className={"fw-bold " + (load && "placeholder")}
                            >
                              {user.tel_util}
                            </span>
                          </th>
                        </tr>
                      </table>
                          </div>
                     </div>
                    </div>
                  )}
                  <div className="card-footer">
                    <div className="d-flex gap-2 justify-content-end">
                      <button className="btn btn-secondary" onClick={()=>setedit(true)}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        Changer Mot de passe
                      </button>
                      {load ? null : <EditProfileInfo user={user} setrefresh={setrefresh}/>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
