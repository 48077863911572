import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import image from "../../assets/image/imgtest/image";
import { lien_vers_reseautage, LOGIN_API } from "../../service/config";
import AuthApi from "../../service/auth/AuthApi";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isconnected from "../../service/isconnected";
import RecuperationMDP from "./RecuperationMDP";
export default function Login(props) {
  props.funcNav(false);

  const location = useLocation();
  let typeOrg = [
    "Auto-écoles",
    "CIM",
    "Examinateurs",
    "ATT",
    "MTM",
    "PTF",
    "Autre",
  ];
  const [showdp, setshowdp] = useState(false);
  const url = location.state?.url;
  const mailFormat =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
  console.log(url);
  const navigate = useNavigate();
  const [util, setUtil] = useState({
    email: "",
    password: "",
  });

  const [load, setLoad] = useState(false);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setUtil({ ...util, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoad(true);
    const apiErrors = {};
    if (util.email === "") {
      setLoad(false);
      setError({ ...error, email: ["votre email est requis"] });
    } else if (util.password === "") {
      setLoad(false);
      setError({ ...error, password: ["votre mot de passe est requis"] });
    } else {
      const id = toast.loading("connexion... ");
      try {
        await axios
          .post(LOGIN_API, util)
          .then((response) => {
            const data = response.data.token;
            setUtil({
              email: "",
              password: "",
            });
            toast.update(id, {
              render: "Connexion réussi !",
              type: "success",
              isLoading: false,
              autoClose: 1000,
            });
            window.localStorage.setItem("authToken", data);
            AuthApi.setAxiosToken(data);
            const test_token = jwtDecode(data);
            console.log(test_token);
            if (test_token) {
              if (test_token.type_util === "SuperAdmin") {
                navigate("/admin", { replace: true });
              } else if (typeOrg.includes(test_token.type_util)) {
                window.location.href = lien_vers_reseautage + `?red=${data}`;
              } else {
                navigate("/", { replace: true });
              }
            }
            return false;
          })
          .catch((error) => {
            const msgErreur = error.response.data;
            console.log(msgErreur.message);
            console.log(error);
            const msg = msgErreur;
            // console.log(msgErreur)
            if (msgErreur.message) {
              apiErrors.msg = msgErreur.message;
            }
            if (msg) {
              apiErrors.email = msg.email;
              apiErrors.password = msg.password;
            }
            toast.update(id, {
              render: "Echec de connexion !",
              type: "error",
              isLoading: false,
              autoClose: 1000,
            });
          });

        setError(apiErrors);
        setLoad(false);

        //TODO flash success
      } catch (error) {
        toast.update(id, {
          render: "Un probleme est survenu, verifiez votre connexion !",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        setLoad(false);
      }
    }
  };

  return (
    <div className="hero-sec container-fluid h-100">
      <div className="row h-100 d-flex align-items-center justify-content-center" style={{minHeight:'100vh'}}>
        <div className="  col-12 " style={{ maxWidth: "450px" }}>
          <div className="card border-0 p-4 pt-4 py-5 px-lg-5 rounded-4" style={{zIndex:8}}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-center">
                <img
                  alt="logo"
                  src={image.mtm}
                  style={{ width: "60px", height: "auto" }}
                />
              </div>
              <h3 className="text-center pt-3">Connexion</h3>
              <p className="text-center pb-2">
                Connecter vous avec votre adresse e-mail ou telephone
              </p>
              <div className="my-3">
                {/* eslint-disable-next-line  */}
                <input
                  onKeyUp={(e) => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        email: ["le champs email est requis"],
                      });
                    } else if (e.target.value !== "") {
                      setError({ ...error, email: [] });
                    }
                  }}
                  onChange={handleChange}
                  type="text"
                  className={
                    "form-control " + (error.email?.length > 0 && " is-invalid")
                  }
                  value={util.email}
                  name="email"
                  placeholder="Adresse email ou numero de télephone"
                />
                {error.email &&
                  error.email.map((element) => (
                    <p className="invalid-feedback p-0 m-1">* {element}</p>
                  ))}
              </div>
              <div className="my-3">
                <div className="input-group ">
                  <input
                    onKeyUp={(e) => {
                      if (e.target.value === "") {
                        setError({
                          ...error,
                          password: ["le mot de passe est requis"],
                        });
                      } else if (e.target.value !== "") {
                        setError({ ...error, msg: false });
                        setError({ ...error, password: false });
                      }
                    }}
                    type={showdp ? "text" : "password"}
                    onChange={handleChange}
                    name="password"
                    className={
                      "form-control " +
                      ((error.password && " is-invalid") ||
                        (error.msg && " is-invalid"))
                    }
                    value={util.password}
                    placeholder="Mot de passe"
                  />
                  <div
                    className="input-group-append "
                    onClick={() => setshowdp(!showdp)}
                  >
                    <span className="input-group-text h-100 ">
                      <FontAwesomeIcon icon={showdp ? faEye : faEyeSlash} />
                    </span>
                  </div>
                  {error.password &&
                    error.password.map((element) => (
                      <p className="invalid-feedback p-0 m-1">* {element}</p>
                    ))}
                  {error.msg && (
                    <p className="invalid-feedback p-0 m-1">
                      * Le mot de passe est incorrect. Essayer a nouveau !
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between gap-2 my-3 mb-2">
              <button
                  type="button"
                  className={`btn btn-sm btn-secondary w-75 py-2 rounded-5  ${
                    load ? "disabled" : ""
                  }`}
onClick={()=> navigate('/')}
                >
                  Retour à l'accueil
                </button>
                <button
                  type="submit"
                  className={`btn btn-sm btn-primary w-75 py-2 rounded-5  ${
                    load ? "disabled" : ""
                  }`}
                >
                  Se connecter
                </button>
                
              </div>
              <RecuperationMDP/>
              <div className="d-flex justify-content-center my-3">
                <p className="text-muted">
                  Pas de compte ?,{" "}
                  <Link
                    to="/inscription"
                    className="text-decoration-none text-primary"
                  >
                    Inscrivez-vous gratuitement
                  </Link>
                </p>
          
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
