import {
  faBookOpenReader,
  faBoxArchive,
  faCarSide,
  faChartPie,
  faFemale,
  faGraduationCap,
  faHouse,
  faListNumeric,
  faMale,
  faRectangleXmark,
  faSquare,
  faSquareCaretUp,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import DonutChart from "./DonutChart";
import NavStat from "./NavStat";

export default function StatGeneral() {
  return (
    <div className="container-lg container-fluid py-4">
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-nowrap justify-content-between w-100 align-items-center">
          <div>
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
            suivi{" "}
          </h2>
          <h4>Statistique générale</h4>
          </div>
          <div>
          <NavStat/>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h2>Filtres</h2>
          <div className="row">
            <div className="col-lg-2 col-12">
              <div className="my-2">
                <label className="form-label">Années</label>
                <input
                  type={"date"}
                  className="form-control w-100"
                  name="annéé"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="my-2">
                <label className="form-label">Region</label>
                <select className="form-control w-100" name="autoecole">
                  <option value="">Freddy Ampasambazaha Fianarantdoz</option>
                  <option value="">Havana auto, Ambositra</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-12 align-self-end">
              <div className="my-2 d-flex">
                <button className="btn btn-cyan w-100 mx-1">Valider</button>
                <div className="vr w-1 bg-secondary h-100"></div>
                <button className="btn btn-success w-100 mx-1">Exporter</button>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6 col-12">
             <div className="w-100 d-flex justify-content-center" style={{maxHeight:'280px'}}>
             <DonutChart taille={2} label={['Homme','Femme']}/>
             </div>
            </div>
            <div className="col-lg-6 col-12 h-100">
              <div className="row">
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">6561659</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faMale} size={"2x"} />
                      </div>
                      <div className="text-center fw-bold">Hommes</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">6568979</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faFemale} size={"2x"} />
                      </div>
                      <div className="text-center fw-bold">Femmes</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">656169</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faSquarePlus} size={"2x"} />
                      </div>
                      <div className="text-center fw-bold">Total</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 p-1 h-100">
                  <div className="card py-3">
                    <div className="card-body">
                      <div className="row text-center">
                        <div className="col-lg-6 col-12">
                          <div className="">
                            <h3>Taux de reussite des candidats</h3>
                            <div className="display-6 fw-bold">78%</div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div>
                            <h3>Total candidat</h3>
                            <div className="display-6 fw-bold">685464</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
