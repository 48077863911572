import axios from "axios";
import { PROFILE_API } from "../config";

const profile = async () => {

    try {
      await axios
        .get(PROFILE_API)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  export  {
profile
  }