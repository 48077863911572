import {
  faCheckCircle,
  faClipboardList,
  faEdit,
  faEraser,
  faFilePdf,
  faHouse,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { typeExam } from "../../../service/config";
import { toast } from "react-toastify";
import SelectPower from "../../../service/Select/SelectPower";
import { useSelector } from "react-redux";

export default function ExamenType() {
  const abortController = new AbortController();
  const categorie = useSelector(state => state.categ)
  // console.log(categorie)
  const [choix,setchoix] = useState(null)
  const columns = [
    // {
    //   name: "id",
    //   width: "12%",
    //   selector: (row) => row.id_typeexam,
    //   sortable: true,
    // },
    {
      name: "Type",
      selector: (row) => row.type_exam,
      sortable: true,
    },
    {
      name: "Déscription",
      selector: (row) => row.desc_exam,
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-secondary"
            onClick={() => {
              setformvalue(row);
              setchoix(row.categ_perm)
              handleValidation();
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setformvalue(row);
              handlesup();
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const columns_valid = [
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom} {row.prenom}
        </>
      ),
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-secondary">{row.status}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button className="btn btn-primary" onClick={() => {}}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [load, setload] = useState(false);
  const [formvalue, setformvalue] = useState({
    type_exam: "",
    desc_exam: "",
  });
  const [vide, setvide] = useState({
    type_exam: "",
    desc_exam: "",
  });
  const [data, setdata] = useState([]);
  const [showvalid, setshowvalid] = useState(false);
  const [supp, setsupp] = useState(false);
  const [error, setError] = useState({
    type_exam: "",
  });
  const handleValidation = () => setshowvalid(!showvalid);
  const handlesup = () => setsupp(!supp);
  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setformvalue({ ...formvalue, [name]: value });
    setError({ ...error, [name]: null });
  };
  const handleSubmit = () => {
    setload(true);
    try {
      axios
        .post(typeExam, formvalue)
        .then((reponse) => {
          console.log(reponse);
          toast.success("type d'examen ajouté !");
          gettype();
          setload(false);
          setformvalue(vide);
          handleValidation();
        })
        .catch((err) => {
          console.log(err);
          setload(false);
          toast.error("un erreur est survenu");
          if (err.response.data) {
            setError(err.response.data);
          }
        });
    } catch (error) {
      setload(false);
      toast.error("un erreur interne est survenu");
    }
  };
  const handleupdate = async () => {
    setload(true);
    try {
      if (formvalue.type_exam) {
        await axios
          .put(typeExam + `/${formvalue.id_typeexam}`, formvalue)
          .then((reponse) => {
            console.log(reponse);
            toast.success("type d'examen modifier !");
            gettype();
            setload(false);
            setformvalue(vide);
            handleValidation();
          })
          .catch((err) => {
            console.log(err);
            setload(false);
            toast.error("un erreur est survenu");
          });
      }
    } catch (error) {
      setload(false);
      toast.error("un erreur interne est survenu");
    }
  };

  const gettype = () => {
    setload(true);
    try {
      axios
        .get(typeExam, {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse);
          setdata(reponse.data.data);
          setload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const supprimer = async () => {
    setload(true);
    try {
      if (formvalue.type_exam) {
        await axios
          .delete(typeExam + `/${formvalue.id_typeexam}`)
          .then((reponse) => {
            console.log(reponse);
            toast.success("type d'examen supprimer !");
            gettype();
            setload(false);
            setformvalue(vide);
            handlesup();
          })
          .catch((err) => {
            console.log(err);
            setload(false);
            toast.error("un erreur est survenu");
          });
      }
    } catch (error) {
      setload(false);
      toast.error("un erreur interne est survenu");
    }
  };

  useEffect(() => {
    gettype();
    return () => {
      abortController.abort();
    };
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button variant="danger" disabled={load} onClick={() => supprimer()}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire type d'examen</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="my-2">
                <label className="form-label">Type d'examen</label>
                <input
                  onChange={handleChange}
                  type="text"
                  className={
                    "form-control " +
                    (error.type_exam?.length > 0 && " is-invalid")
                  }
                  value={formvalue.type_exam}
                  name="type_exam"
                  placeholder=""
                />
                {error.type_exam &&
                  error.type_exam.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      * {element}
                    </p>
                  ))}
              </div>
              <div className="my-2">
              <label className="form-label">Catégorie de permis de l'examen</label>
                <SelectPower
                  setFormValues={setformvalue}
                  formValues={formvalue}
                  error={error}
                  label={"categ"}
                  value={"categ"}
                  options={categorie}
                  name={'categ_perm'}
                  load={false}
                  setError={setError}
                  placeholder={'choisir catégorie'}
                  choix={choix}

                />
              </div>
              <div className="my-2">
                <label className="form-label">Déscription de l'examen</label>
                <input
                  onChange={handleChange}
                  type="text"
                  className={
                    "form-control " +
                    (error.desc_exam?.length > 0 && " is-invalid")
                  }
                  value={formvalue.desc_exam}
                  name="desc_exam"
                  placeholder=""
                />
                {error.desc_exam &&
                  error.desc_exam.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      * {element}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleValidation}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            type="button"
            onClick={() =>
              formvalue.id_typeexam ? handleupdate() : handleSubmit()
            }
          >
            {formvalue.id_typeexam ? "Modifier" : "Enregistrer"}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faClipboardList} className="me-1" /> Examen pratique{" "}
          </h2>
          <h4>Les types d'examen pratique</h4>
        </div>
        <Button
          variant="primary"
          disabled={load}
          type="button"
          onClick={() => {
            setchoix(null)
            setformvalue(vide);
            handleValidation();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Nouveau
        </Button>
      </div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card">
            <div className="card-body">
              <div className="">
                <DataTable
                  columns={columns}
                  data={data}
                  progressPending={load}
                  contextActions={
                    <button className="btn btn-cyan me-2">
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      Valider
                    </button>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
