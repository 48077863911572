import { faAdd, faCancel, faEdit, faEye, faFileImage, faMapPin, faSchool, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Pagination from '../../../outils/Pagination';

const animatedComponents = makeAnimated();
const options = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' }
]
const langue = [
    { value: 'Malagasy', label: 'Malagasy' },
    { value: 'Français', label: 'Français' }
]
export default function Pointers() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const list = [{
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, , {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }, {
        id: "1",
        nom: "Hery",
        prenom: "Mamy",
        sexe: "Homme",
        datenes: "11/11/98",
        cin: "41202578945"
    }]

    const [image, setImage] = useState(null)

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }
    const [ito, setIto] = useState([])
    const change = (choice) => {
        setIto(choice)


    }
    const [lg, setLg] = useState([])
    const changeLg = (choice) => {
        setLg(choice)


    }
    function PrintMyState(ito) {
        console.log(ito)
    }
    var myModalEl = document.getElementById('exampleModal')
    if (myModalEl) {
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            setImage(null)
            setIto([])
            setLg([])
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedInvoices = Pagination.getData(list, currentPage, itemsPerPage);

    // calls whenever state will be change , another approach
    useEffect(() => {} ,[])

    return (
        <>
            <div className='container-lg container-fluid py-4'>
                <div className="modal" tabIndex="-1" id='exampleModal'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black'>
                                <h2 className='p-2'>Formulaire module</h2>
                                <button className="btn-close p-0" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body py-0">
                                <form className='my-3'>
                                    <div className='my-3'>
                                        <label className="form-label">Titre</label>
                                        <input className='form-control' type="text" placeholder='Saisir titre du module' />
                                    </div>
                                    <div className='my-3 row gap-2'>
                                        <div className='col-lg-3 col-12'>
                                            <label className="form-label">Langue</label>
                                            <Select
                                                closeMenuOnSelect
                                                components={animatedComponents}
                                                defaultValue={[langue[0]]}
                                                isMulti={false}
                                                name='langue'
                                                placeholder="choix langue"
                                                options={langue}
                                                value={lg}
                                                onChange={(choice) => changeLg(choice)}
                                            />
                                        </div>
                                        <div className='col-lg col-12'>
                                            <label className="form-label">Categorie</label>
                                            <Select
                                                closeMenuOnSelect
                                                components={animatedComponents}
                                                defaultValue={[options[4], options[5]]}
                                                isMulti
                                                name='categorie'
                                                placeholder="choix catégorie"
                                                options={options}
                                                value={ito}
                                                onChange={(choice) => change(choice)}
                                                noOptionsMessage={() => "plus d'options"}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <label className="form-label">Image de representation du module</label>
                                        <div className='row'>

                                            <div className='col-lg-6 col-12 mb-2'>

                                                <input type="file" className='form-control' name="image" onChange={onImageChange} placeholder='choisir un image' accept="image/png, image/gif, image/jpeg" />

                                            </div>
                                            <div className='col-lg-6 col-12 d-flex justify-content-center border rounded-2 align-items-center'>
                                                {image ? (<img src={image} height="150" width={150} alt="preview" />) : (<FontAwesomeIcon icon={faFileImage} height="150" width={150} />)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <label className="form-label">Description du module</label>
                                        <textarea rows={10} className="form-control" name="description" placeholder='saisir description'></textarea>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer">
                                <div className="w-100">
                                    <div className="row justify-content-between">
                                        <div className="col d-flex justify-content-start"><a href="/#" className="btn" data-bs-dismiss="modal">
                                            <FontAwesomeIcon icon={faCancel} className="me-2" />   Annuler
                                        </a></div>
                                        <div className="col d-flex justify-content-end"><a href="/#" className="btn btn-primary " data-bs-dismiss="modal">
                                            <FontAwesomeIcon icon={faAdd} className="me-2" /> Ajouter
                                        </a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2>Candidats</h2>
                    <p className=''>Liste des candidats</p></div>
                <div className='my-2 d-flex flex-row justify-content-between gap-2'>
                    <input className='form-control' type="text" placeholder='Filtrer les données' name="filter" />
                    <button className='btn btn-secondary'>Rechercher</button>
                </div>
                <div>
                </div>
                <div className='overflow-auto d-flex flex-column justify-content-between table-ty'>
                    <table className='table table-hover table-bordered table-striped table-light '>
                        <thead>
                            <tr className=''>
                                <th><input type={'checkbox'}/></th>
                                <th>Nom et Prénom</th>
                                <th>adresse</th>
                                <th>télephone</th>
                                <th>Email</th>
                                <th>Date d'inscription</th>
                                <th className='col-2'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedInvoices.map((element,index) => <tr key={index}>
                                <td><input type={'checkbox'}/></td>
                                <td>{element.nom}{' '}{element.prenom}</td>
                                <td>ivory atsimo</td>
                                <td>+261 34 00 000 00</td>
                                <td>Mamy@gmail.com</td>
                                <td className='col-2 '>
                                    {element.datenes}
                                </td>
                                <td className='py-1 d-flex justify-content-center'><Link to="/organisme/cursus/12" className='btn btn-secondary'><FontAwesomeIcon icon={faEye} size="sm" className="pe-2" />Pointer</Link></td>
                            </tr>)}
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end align-items-end'>
                        <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} onPageChanged={handlePageChange} length={list.length}
                        />
                    </div>


                </div>
            </div>
        </>
    )
}
