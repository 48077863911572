import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CATEGORIE_API, file_url, TypeLois_API } from "../../../service/config";
import LoadingBar from "react-top-loading-bar";
import Pagination from "../../../outils/Pagination";

export default function GererTypeLois() {
  // pagination
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // pagination
  const [id, setID] = useState(null);
  const ref = useRef(null);
  const [barColor, setBarColor] = useState("blue");
  const [mode, setMode] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    desc_type_lois: "",
  });
  const vide = {
    desc_type_lois: "",
  };
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFormValues({ ...formValues, img_categ: e.target.files[0] });
    }
  };

  const getCategorie = async () => {
    setBarColor("blue");
    ref.current.continuousStart();

    try {
      await axios
        .get(TypeLois_API)
        .then((response) => {
          const data = response.data.data;
          console.log(data);
          ref.current.complete();
          setCategorie(data);
        })
        .catch((error) => {
          setBarColor("red");
          console.log(error);
          console.log(error);
        });
    } catch (error) {
      toast.error("un erreur est survenu");
      setBarColor("red");
      console.log(error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const data = {};
    const msgErreur = {};

    data.desc_type_lois = formValues.desc_type_lois;

    setFormValues(data);
    if (mode) {
      try {
        await axios
          .post(TypeLois_API + "/" + id + "?_method=PUT", formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Categorie modifiée");
            setLoad(false);

            getCategorie();
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            console.log(msg);
            if (msg) {
              msgErreur.desc_type_lois = msg.desc_type_lois;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
        setError([]);
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    } else {
      try {
        await axios
          .post(TypeLois_API, formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Element Ajouté");
            setLoad(false);
            setError([]);
            getCategorie();
          })
          .catch((error) => {
            const msg = error.response.data;
            console.log(error);
            console.log(msg);
            if (msg) {
              msgErreur.desc_type_lois = msg.desc_type_lois;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    }
  };

  const fetchUpdate = async (element) => {
    setError([]);
    setMode(true);
    setID(element.id_type_lois);
    const updt = {};
    updt.desc_type_lois = element.desc_type_lois;

    setFormValues(updt);
  };
  const initialiser = () => {
    setMode(false);
    setFormValues(vide);
    setImage(null);
    setError([]);
  };

  const supprimer = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      await axios
        .delete(TypeLois_API + "/" + id)
        .then((response) => {
          toast.success("Element " + id + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getCategorie();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Element pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginatedInvoices = Pagination.getData(
    categorie,
    currentPage,
    itemsPerPage
  );

  useEffect(() => {
    getCategorie();
  }, []);

  return (
    <>
      <LoadingBar color={barColor} ref={ref} />
      <div className="container-lg container-fluid py-4">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h2>Types de Textes en vigueur</h2>
            <p className="">Liste des Types de Textes en vigueur</p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => initialiser()}
              className="btn btn-primary px-4 py-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal-type"
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouveau Type
            </button>
          </div>
        </div>
        <div className="row">
          <div className="overflow-auto">
            <table className="table table-hover table-bordered table-striped table-light ">
              <thead>
                <tr className="">
                  <th>ID</th>
                  <th>Type</th>
                  <th className="col-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedInvoices.map((element) => (
                  <tr key={element.id_type_lois}>
                    <td>{element.id_type_lois}</td>
                    <td>{element.desc_type_lois}</td>
                    <td className="d-flex gap-2 justify-content-center">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => fetchUpdate(element)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal-type"
                      >
                        <FontAwesomeIcon color="blue" icon={faEdit} />
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => setID(element.id_type_lois)}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-type-danger"
                      >
                        <FontAwesomeIcon color="red" icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                onPageChanged={handlePageChange}
                length={categorie.length}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-blur"
        id="modal-type-danger"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon mb-2 text-danger icon-lg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9v2m0 4v.01"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>
              <h3>vous etes sur ?</h3>
              <div className="text-muted">
                vous-voulez vraiment supprimer ? c'est irréversible.
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="fermerdel"
                      className="btn w-100"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={supprimer}
                      className={"btn btn-danger w-100 " + (load && "disabled")}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal modal-blur" tabIndex="-1" id="exampleModal-type">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <form
              className=""
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                <h2 className="p-2">Formulaire Type</h2>
                <button
                  className="btn-close p-0"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body py-0">
                <div className="my-3">
                  <label className="form-label">Type lois et texte</label>
                  <input
                    className={
                      "form-control " + (error.desc_type_lois && "is-invalid")
                    }
                    name="desc_type_lois"
                    value={formValues.desc_type_lois}
                    onChange={handleChange}
                    type="text"
                    placeholder="Saisir type"
                  />
                  {error.desc_type_lois ? (
                    <div className="invalid-feedback">
                      {error.desc_type_lois}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row justify-content-between ">
                    <div className="col-3">
                      <button
                        id="fermer"
                        type="button"
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        type="submit"
                        className={
                          "btn btn-primary w-100 " + (load && "disabled")
                        }
                      >
                        <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                        {mode ? <>Modifier</> : <>Ajouter</>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
