import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { CATEGORIE_API, file_url } from '../../../service/config';
import LoadingBar from 'react-top-loading-bar';
import { TailSpin } from 'react-loader-spinner';
import DataTable from 'react-data-table-component';




export default function Categories() {
    const abortController = new AbortController();
    const [id, setID] = useState(null)
    const ref = useRef(null)
    const [barColor, setBarColor] = useState("blue")
    const [mode, setMode] = useState(false)
    const [categorie, setCategorie] = useState([])
    const [load, setLoad] = useState(false)
    const [formValues, setFormValues] = useState({
        "categ": "",
        "equi_categ": "",
        "desc_categ": "",
        "img_categ": null
    })
    const vide = {
        "categ": "",
        "equi_categ": "",
        "desc_categ": "",
        "img_categ": null
    }
    const [error, setError] = useState([])
    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setFormValues({ ...formValues, [name]: value });
    };

    const [image, setImage] = useState(null)

    const onImageChange = (e) => {
        // const formData = [];
        // const files = e.target.files;
        // for (let i = 0; i < files.length; i++) {
        //     formData.push(files[i]);
        //   }
        console.log(e.target.files[0].size)
       
            if (e.target.files && e.target.files[0]) {
                setImage(URL.createObjectURL(e.target.files[0]));
                setFormValues({ ...formValues, img_categ: e.target.files[0] });
            }
        

    }

    const getCategorie = async () => {
        setBarColor("blue")
        ref.current.continuousStart()

        try {
             axios.get(CATEGORIE_API,{
                signal: abortController.signal,
                }).then((response) => {
                const data = response.data.data
                console.log(data);
                ref.current.complete()
                setCategorie(data);


            }).catch((error) => {
                setBarColor("red")
                console.log(error)
                console.log(error)
            })
        } catch (error) {
            toast.error("un erreur est survenu")
            setBarColor("red")
            console.log(error)
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoad(true)
        const data = {}
        const msgErreur = {};
        if (formValues.img_categ == null) {
            data.categ = formValues.categ
            data.equi_categ = formValues.equi_categ
            data.desc_categ = formValues.desc_categ
        } else {
            data.categ = formValues.categ
            data.equi_categ = formValues.equi_categ
            data.desc_categ = formValues.desc_categ
            data.img_categ = formValues.img_categ
        }
        setFormValues(data)
        if (mode) {
            try {
                await axios.post(CATEGORIE_API + "/" + id + "?_method=PUT", formValues, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    console.log(response);
                    document.getElementById('fermer').click();
                    toast.success("Categorie modifiée")
                    setLoad(false)

                    getCategorie();
                }).catch((error) => {
                    console.log(error)
                    const msg = error.response.data
                    console.log(msg)
                    if (msg) {
                        msgErreur.categ = msg.categ
                        msgErreur.equi_categ = msg.equi_categ
                        msgErreur.desc_categ = msg.desc_categ
                        msgErreur.img_categ = msg.img_categ
                    }
                    toast.error("un erreur survenu")
                    setLoad(false)
                    setError(msgErreur)
                })
                setError([])
            } catch (error) {
                console.log(error)
                toast.error("probleme liee au serveur")
                setLoad(false)
            }
        } else {
            try {

                await axios.post(CATEGORIE_API, formValues, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    console.log(response);
                    document.getElementById('fermer').click();
                    toast.success("Categorie Ajouté")
                    setLoad(false)
                    setError([])
                    getCategorie();
                }).catch((error) => {
                    const msg = error.response.data
                    console.log(error)
                    console.log(msg)
                    if (msg) {
                        msgErreur.categ = msg.categ
                        msgErreur.equi_categ = msg.equi_categ
                        msgErreur.desc_categ = msg.desc_categ
                        msgErreur.img_categ = msg.img_categ
                    }
                    toast.error("un erreur survenu")
                    setLoad(false)
                    setError(msgErreur)
                })
            } catch (error) {
                console.log(error)
                toast.error("probleme liee au serveur")
                setLoad(false)
            }
        }

    };

    const fetchUpdate = async (element) => {
        setError([])
        setMode(true);
        setID(element.id_categ)
        const updt = {};
        updt.categ = element.categ
        updt.equi_categ = element.equi_categ
        updt.desc_categ = element.desc_categ

        setFormValues(updt)
        setImage(`http://127.0.0.1:8000/storage/${element.img_categ}`)
    };
    const initialiser = () => {
        setMode(false);
        setFormValues(vide)
        setImage(null)
        setError([])
    };

    const supprimer = async (e) => {
        e.preventDefault();
        setLoad(true)
        try {
            await axios.delete(CATEGORIE_API + "/" + id).then((response) => {
                toast.success("categorie " + id + " supprimer")
                setLoad(false)
                document.getElementById('fermerdel').click();
                getCategorie();
            }).catch((error) => {
                console.log(error)
                toast.error("categorie pas supprimer!")
                setLoad(false)
            })
        } catch (error) {
            toast.error("probleme interne");
            setLoad(false)
        }
    }



    useEffect(() => {
        getCategorie();
        return () => {
            abortController.abort();
          };
    }, [])


    return (
        <>
            <LoadingBar color={barColor} ref={ref} />
            <div className='container-lg container-fluid py-4'>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <div>
                        <h2>Catégories</h2>
                        </div>
                    <div>
                        <button type="button" onClick={() => initialiser()} className='btn btn-primary px-4 py-2' data-bs-toggle="modal" data-bs-target="#exampleModal"><FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvelle categorie</button>
                    </div>
                </div>
                <div className='row my-2'>
            
                    <div className='overflow-auto'>
                        <table className='table table-hover table-bordered table-striped table-light '>
                            <thead>
                                <tr className=''>
                                    <th>ID</th>
                                    <th>nom</th>
                                    <th>équivalence</th>
                                    <th>déscription</th>
                                    <th>Image</th>
                                    <th className='col-2'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categorie.map((element) => <tr key={element.id_categ}>
                                    <td>{element.id_categ}</td>
                                    <td>{element.categ}</td>
                                    <td>{element.equi_categ}</td>
                                    <td>{element.desc_categ}</td>
                                    <td>   <span className="avatar avatar-sm" style={{ backgroundImage:  `url(${file_url}/${element.img_categ})` }}></span></td>
                                    <td className='d-flex gap-2 justify-content-center'>
                                        <button type='button' className="btn-action" onClick={() => fetchUpdate(element)} data-bs-toggle="modal" data-bs-target="#exampleModal" >
                                            <FontAwesomeIcon color='blue' icon={faEdit} />
                                        </button>
                                        <button type='button' className="btn-action" onClick={() => setID(element.id_categ)} data-bs-toggle="modal" data-bs-target="#modal-danger">
                                            <FontAwesomeIcon color='red' icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>


                    </div>
                </div>

            </div>


            <div className="modal modal-blur" id="modal-danger" tabIndex="-1" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-status bg-danger"></div>
                        <div className="modal-body text-center py-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
                            <h3>vous etes sur ?</h3>
                            <div className="text-muted">vous-voulez vraiment supprimer cette catégorie? c'est irréversible.</div>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col"><button type='button' id='fermerdel' className="btn w-100" data-bs-dismiss="modal">
                                        Annuler
                                    </button></div>
                                    <div className="col"><button type='button' onClick={supprimer} className={"btn btn-danger w-100 " + (load && "disabled")}>
                                        Supprimer catégorie
                                    </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-blur" tabIndex="-1" id='exampleModal'>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">

                    <div className="modal-content">
                        <form className='' onSubmit={handleSubmit} encType='multipart/form-data' >
                            <div className='d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black'>
                                <h2 className='p-2'>Formulaire catégorie</h2>
                                <button className="btn-close p-0" type='button' data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body py-0">

                                <div className='my-3'>
                                    <label className="form-label">Catégorie</label>
                                    <input className={"form-control " + (error.categ && "is-invalid")} name='categ' value={formValues.categ} onChange={handleChange} type="text" placeholder='Saisir nom categorie' />
                                    {error.categ ? <div className='invalid-feedback'>{error.categ}</div> : <></>}
                                </div>
                                <div className='my-3'>
                                    <label className="form-label">Equivalence</label>
                                    <input className={'form-control ' + (error.equi_categ && "is-invalid")} name='equi_categ' value={formValues.equi_categ} onChange={handleChange} type="text" placeholder="saisir l'equivalence du categorie" />
                                    {error.equi_categ && (<div className='invalid-feedback'>{error.equi_categ}</div>)}
                                </div>
                                <div className='my-3'>
                                    <label className="form-label">Image </label>
                                    <input className={'form-control ' + (error.img_categ && "is-invalid")} name='img_categ' id='img_categ' type="file" onChange={onImageChange} accept="image/*" />
                                    {error.img_categ && (<div className='invalid-feedback'>{error.img_categ}</div>)}
                                </div>
                                <div className='my-3'>
                                    {image && <span className="avatar avatar-sm" style={{ backgroundImage: "url(" + image + ")" }}></span>}

                                </div>
                                <div className='my-3'>
                                    <label className="form-label">Description du catégorie</label>
                                    <textarea rows={5} className={"form-control " + (error.desc_categ && "is-invalid")} name="desc_categ" value={formValues.desc_categ} onChange={handleChange} placeholder='saisir description'></textarea>
                                    {error.desc_categ && (<div className='invalid-feedback'>{error.desc_categ}</div>)}
                                </div>


                            </div>
                            <div className="modal-footer">
                                <div className="w-100">
                                    <div className="row justify-content-between ">
                                        <div className="col-3"><button id='fermer' type="button" className="btn w-100" data-bs-dismiss="modal">
                                            Annuler
                                        </button></div>
                                        <div className="col-3"><button type="submit" className={"btn btn-primary w-100 " + (load && "disabled")}>
                                            <FontAwesomeIcon icon={faAdd} className="me-2" /> {mode ? <>Modifier</> : <>Ajouter</>}
                                        </button></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}