import jwtDecode from "jwt-decode";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";
import HeadORG from "../../components/Admin/Organisme/HeadORG";
import Footer from "../../components/Footer/Footer";
import Head from "../../components/header/Head";
import Header from "../../components/header/Header";
import AuthContext from "../context/AuthContext";
import isconnected from "../isconnected";
import AuthApi from "./AuthApi";

const PrivateRoute = () => {
  let type_util = [
    "Grand public",
    "SuperAdmin",
    "Apprenants",
    "Candidats",
  ];
  const type_user = isconnected.user();
  const urlParams = new URLSearchParams(window.location.search);
  const red = urlParams.get("red");
  const navigate = useNavigate();
  console.log(red);
  if (red) {
    window.localStorage.setItem("authToken", red);
    AuthApi.setAxiosToken(red);
    navigate("/", { replace: true });
    const test_token = jwtDecode(red);
    if (test_token) {
      window.location.reload();
    }
    return false;
  }
  return type_util.includes(type_user.type_util) ? (
    <>
      <Outlet />{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

const AdminRoute = (props) => {
  const type_user = isconnected.user();
  console.log(type_user.type_util);
  return type_user.type_util === "SuperAdmin" ? (
    <>
      <Head setactif={props.setactif} />
      <div
        style={{ paddingTop: "3rem" }}
        data-bs-toggle="collapse"
        data-bs-target=".navbar-collapse.show"
      >
        <Outlet />
      </div>{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

const Public = () => {
 
  return (
    <>
      <Outlet />{" "}
    </>
  );
};
const OrganismeRoute = () => {
  let typeOrg = [
    "Auto-écoles",
    "CIM",
    "Examinateurs",
    "ATT",
    "MTM",
    "PTF",
    "Autre",
  ];
  const type_user = isconnected.user();

  return typeOrg.includes(type_user.type_util) ? (
    <>
      <HeadORG />
      <div
        className="pt-4"
        data-bs-toggle="collapse"
        data-bs-target=".navbar-collapse.show"
      >
        <Outlet />
      </div>{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};
export default { PrivateRoute, AdminRoute, OrganismeRoute,Public };
