import {
  faBookBookmark,
  faComment,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import image from "../../assets/image/imgtest/image";
import {
  CategorieByModule_API,
  commentmodule_API,
  FichierLesson_API,
  file_url,
  LESSON_API,
  moduleByLesson,
  MODULE_API,
  AvisModule_API,
  modulebylanguecateg_API,
  API_URL,
  ListLessonHateaos_API,
  lessonByModulesHateaos_API,
  lessonByModule,
} from "../../service/config";
import isconnected from "../../service/isconnected";
import "./canvas.css";
import Carrousel from "../Accueil/section/Carrousel";
import LoadingCard from "../Accueil/section/LoadingCard";
import RetourComp from "../../outils/RetourComp";

export default function Details_module(props) {
  // eslint-disable-next-line
  let { pathname } = useLocation();
  const [lessons, setLessons] = useState([]);
  const [listles,setlistLes] =useState([])
  const [loadles,setloadles] =useState(true)
  const [loadFich, setloadFich] = useState(true);
  const [loadcom, setloadcom] = useState(false);
  const [fichier, setFichier] = useState([]);
  const [rating, setRating] = useState(0);
  const [avis, setAvis] = useState({});
  const [modules, setmodules] = useState({});
  const [module, setmodule] = useState({});
  const abortController = new AbortController();
  const [comment, setComment] = useState({
    com_mod: "",
    id_mod: modules.id_mod,
  });
  const vide = useState({
    com_mod: "",
    id_mod: modules.id_mod,
  });
  const [content, setContent] = useState({
    id_les: "",
    titre_les: "",
    img_les: "",
    desc_les: "",
  });
  const [load, setLoad] = useState(true);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(65);
  let { id } = useParams();
  let dataLes = [];
  let dataAll = [];

  console.log(avis);
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours >= 1 && minutes >= 1) {
      return (
        <>
          {hours} heures et {minutes} minutes
        </>
      );
    } else if (hours >= 1 && minutes == 0) {
      return <>{hours} heures</>;
    } else if (minutes >= 1 && hours == 0) {
      return <>{minutes} minutes</>;
    } else if (hours == 0 && minutes == 0) {
      return <>{seconds} secondes</>;
    }
  }
  const getAvis =  (mod) => {
    try {
       axios
        .get(AvisModule_API + "/module/" + mod)
        .then((reponse) => {
          console.log(reponse.data.monAvis);
          setAvis(reponse.data.monAvis);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getlesson = () => {
    setloadles(true)
    try {
      axios.get(lessonByModule + id,{
        signal: abortController.signal,
        }).then((response) => {
        console.log(response);
        const database = response.data.data;
        setlistLes(database)
        setloadles(false)
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getModules =  () => {
    try {
      axios.get(MODULE_API,{
        signal: abortController.signal,
        }).then((response) => {
        setmodules(response.data.data);
        console.log(id);
        console.log(response)
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          if (element.id_mod == id) {
            setmodules(element);
            console.log(element)
            setLoad(false);
            setTimeout(() => {
              getAvis(element.id_mod);
            }, 1000);
            console.log(element);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };



  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setComment({ ...comment, [name]: value });
  };

  const addcommentaire = async (e) => {
    e.preventDefault();
    setloadcom(true);
    console.log("io lesy izy" + modules.id_mod);
    try {
      await axios
        .post(commentmodule_API, {
          com_mod: comment.com_mod,
          id_mod: modules.id_mod,
        })
        .then((response) => {
          console.log(response);
          toast.success("commentaire publier");
          setComment({ ...comment, com_mod: "" });
          setloadcom(false);
        })
        .catch((error) => {
          console.log(error);
          setloadcom(false);
          toast.warn(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
      setloadcom(false);
    }
  };

  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin ===null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map((element, index) => (
           element!=="" && <span key={index} className="badge bg-cyan px-2 rounded-3 mx-1 mb-1">
              {element}
            </span>
          ))}
        </>
      );
    }
  }
  const tooltipArraylist = [
    "très mauvais",
    "mauvais",
    "modérée",
    "très bon",
    "excellent",
  ];

  const addAvis = async (note, id) => {
    try {
      axios
        .post(AvisModule_API, {
          note_mod: note,
          id_mod: id,
        })
        .then((response) => {
          console.log(response);
          toast.success("avis ajouté !");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            axios
              .put(AvisModule_API + "/" + avis.id_avimod, {
                note_mod: note,
                id_mod: id,
              })
              .then((response) => {
                console.log(response);
                toast.success("avis ajouté !");
              });
          }
        });
    } catch (error) {}
  };
  // Catch Rating value
  const handleRating = (rate, id) => {
    // setRating(rate);
    addAvis(rate, id);
  };

  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };

  useEffect(() => {
    getModules();
    getlesson();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="container-fluid bg-light m-0 pt-2">
      {/* <div className="section2">
        <div className="container-lg container-fluid text-center p-3 p-lg-4">
          <p className="h2">Conduite supervisé</p>
        </div>
      </div> */}
      <div className="container-lg container-fluid">
        <div className="bg-white px-4 py-2 my-2">
          <div className="row flex-lg-row-reverse align-items-center py-2">
            <div
              className={
                "col-12 col-sm-8 col-lg-6 " + (load && "p-5 placeholder")
              }
            >
              <div className="w-100 h-100">
                {load ? null : <img style={{width:"100%",maxHeight:'370px'}} src={file_url + modules.img_mod} alt="module" />}
              </div>
            </div>
            <div className={"col-lg-6 py-3"}>
              {load ? (
                <div className="">
                  <div className="h4 placeholder">Titre</div>
                  <div className="d-flex gap-1">
                    <span className="p-2 px-2 my-2 me-1 placeholder"></span>{" "}
                    <span className="p-2 px-2 my-2 me-1 placeholder"></span>
                  </div>

                  <p className="lead placeholder">
                    Dans ce partie se trouve la Description du module qu'on
                    detaillera dans plusieurs ligne
                  </p>
                  <div className="mb-3">
                    <p className="mb-0 h-3 fw-bold placeholder">
                      Moyenne de ce module
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col">
                      <p
                        style={{ fontSize: "12px" }}
                        className="mb-1 fw-bold placeholder"
                      >
                        {progress}% de cours de ce modules terminer
                      </p>
                      <div
                        className="progress placeholder"
                        style={{ height: "10px" }}
                      >
                        <div
                          className="progress-bar placeholder"
                          role="progressbar"
                          style={{
                            width: progress.toString() + "%",
                            height: "100%",
                          }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <h1 className={"display-4" + (load && "placeholder display-4")}>
                  <RetourComp/>{modules?.titre_mod}
                  </h1>
                  <div
                    className={
                      "d-flex flex-wrap gap-1 my-2 w-75" +
                      (load && "placeholder")
                    }
                  >
                    {modules.categories?.map((element, index) => (
                      <span key={index} className="badge bg-cyan px-2 me-1">
                        {element.categ}
                      </span>
                    ))}
                  </div>
                  <div className="my-2">
                    <span className="fst-italic border px-2 rounded-3 me-1">
                      {modules.lang_mod}
                    </span>{" "}
                    <span>
                      <svg
                        style={{ width: "15px" }}
                        className="me-1 sc-ezWOiH bHaIHM MuiSvgIcon-root MuiSvgIcon-fontSizeMedium @openclassrooms/app-learning-content-2-@openclassrooms/app-learning-content19 @openclassrooms/app-learning-content-2-@openclassrooms/app-learning-content25"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="QueryBuilderIcon"
                      >
                        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
                      </svg>
                      <span className="fw-bold">
                        {modules.dure_mod} heures
                      </span>
                    </span>
                  </div>

                  <p
                    className={
                      "lead pe-3 pb-0 mb-0 my-1 " + (load && "placeholder")
                    }
                  >
                    <span className="fw-bold">Objectif :</span>{" "}
                    {modules?.objectif_mod}
                  </p>
                  <div className="my-2 text-wrap">
                    <span className="fw-bold">Méthodologie :</span>{" "}
                    {Separer(modules.method_mod)}
                  </div>
                  <div className="my-2 text-wrap">
                    <span className="fw-bold">Support :</span>{" "}
                    {Separer(modules.support_mod)}
                  </div>
                  <div className="mb-3">
                    <p className="mb-0 h-3 fw-bold">Moyenne de ce module</p>
                    <Rating
                      onClick={(rate) => handleRating(rate, modules?.id_mod)}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      showTooltip={true}
                      tooltipArray={tooltipArraylist}
                      tooltipDefaultText="votre note"
                      titleSeparator="sur"
                      initialValue={
                        avis == null || avis === {} ? 0 : avis.note_mod
                      }
                      readonly={false}
                      /* Available Props */
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col">
                      <p style={{ fontSize: "12px" }} className="mb-1 fw-bold">
                        {progress}% de cours de ce modules terminer
                      </p>
                      <div className="progress" style={{ height: "10px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: progress.toString() + "%",
                            height: "100%",
                          }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {load ? null : (
          <div className="px-2">
            <div className="row justify-content-center my-2">
              <div className="col-12 d-flex justify-content-center flex-column bg-white py-4 px-3 rounded-3">
                <h1>Description</h1>
                <p>{modules.desc_mod}</p>
              </div>
            </div>
            <div className="row justify-content-center my-2">
              <div className="col-12 d-flex justify-content-center flex-column bg-white py-4 p-3 rounded-3">
                <h1>Les leçons</h1>
                <p>
                Préparez-vous efficacement pour l'examen du code de la route grâce à notre plateforme d'apprentissage, qui vous offre des tests d'entraînement et des cours en ligne actualisés régulièrement.
                </p>
                {loadles ? <div><LoadingCard/></div> :  loadles == false && listles.length > 0 ? <Carrousel type="lesson" data={listles} load={load} module={id} /> : <div className="display-5 text-center py-3 fw-light">Aucune leçons pour l'instant</div>}
               {/* { listles.length > 0 && <div className="my-3 d-flex justify-content-end">
                  <Link
                    to={"/lessons/" + modules.id_mod}
                    className="text-decoration-none"
                  >
                    {" "}
                    <button className="btn btn-md btn-primary rounded-2 px-4 py-2">
                      Voir toutes
                    </button>
                  </Link>
                </div>} */}
              </div>
            </div>
            <div className="row justify-content-center my-2">
              <div className="col-12 d-flex justify-content-center flex-column bg-white py-4 p-3 rounded-3">
                <h1>Exercices</h1>
                <p>
                  Ameliorez vos compétence dans des series d'exercice aléatoire
                  qui testerons vos connaissance en la matiere de conduite d'une
                  voiture. A la fin une note vous sera distrubuée pour voir a
                  quel point votre niveau de connaissance se situe et ainsi
                  l'ameliorer au fur et a mesure
                </p>
                <div className="my-3 d-flex justify-content-end">
                  <Link
                    to={"/exercice/" + modules.id_mod}
                    className="text-decoration-none"
                    state={{ module: modules }}
                  >
                    {" "}
                    <button className="btn btn-md btn-primary rounded-2 px-4 py-2">
                      Commencer un exercice
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {modules.id_mod && (
          <div className="row">
            <div className="col-12 bg-white p-lg-5 p-md-3 p-sm-3 p-3 my-2">
              <form className="w-100" onSubmit={addcommentaire}>
                <div className="row gap-3 d-flex align-items-center">
                  <div className="col">
                    <h2 className="mb-3">Commentaire et avis</h2>
                    {isconnected.Authenticated() === false && (
                      <div className="alert alert-info">
                        <strong>Important!</strong> vous devez avoir un{" "}
                        <Link to="/inscription" className="alert-link">
                          <span>compte</span>
                        </Link>{" "}
                        et vous{" "}
                        <Link
                          to="/login"
                          state={{ url: pathname }}
                          className="alert-link"
                        >
                          <span>connecter</span>
                        </Link>{" "}
                        pour pouvoir ajouter un commentaire.
                      </div>
                    )}
                    <div>
                      <textarea
                        className={"form-control "}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        value={comment.com_mod}
                        placeholder="Votre commentaire ici..."
                        onChange={handleChange}
                        name="com_mod"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row gap-3">
                      <div className="col-12">
                        <Link
                          to={"/commentaires/" + modules.id_mod}
                          className="text-white text-decoration-none  w-100"
                        >
                          <button className="btn btn-primary text-white  w-100 py-2 text-nowrap">
                            Voir toutes les commentaires
                            <FontAwesomeIcon
                              icon={faComment}
                              className="ms-2"
                              color="white"
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="col-12">
                        {isconnected.Authenticated() === false ||
                        comment.com_mod.length < 2 ? (
                          <button
                            type="button"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 disabled"
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 " +
                              (loadcom && "disabled")
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
