import {
  faCancel,
  faCheck,
  faEdit,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import isconnected from "../../../service/isconnected";
import axios from "axios";
import { ChangerMDP_API } from "../../../service/config";
import { toast } from "react-toastify";

export default function EditAccount(props) {
  let user = props.data;
  const [type, settype] = useState(true);
  const [load,setload] = useState(false)
  const [formValues, setFormValues] = useState({
    email: type
      ? isconnected.user().email
      : isconnected.Authenticated().tel_util,
    password: "",
    newpassword: "",
    confpassword: "",
  });
  const [Error, setError] = useState({
    email: [],
    password: [],
    newpassword: [],
    confpassword: [],
  });
const handleChange = (e) =>{
e.preventDefault();
const {name,value} = e.target;
setFormValues({...formValues,[name]:value})
setError({ ...Error, [name]: [] });
}
  const verifier = () => {
    for (var prop in formValues) {
      if (formValues.hasOwnProperty(prop)) {
        if (formValues[prop] == "") {
          setError({ ...Error, [prop]: ["Ce champs est requis"] });
          return false;
        }else if (prop == 'confpassword'&& formValues[prop] != "" && formValues[prop] != formValues.newpassword) {
          setError({ ...Error, [prop]: ["nouveau mot de passe et confirmation mot de passe doit être identique !"] });
          return false;
        } 
        else {
          setError({ ...Error, [prop]: [] });
        }
      }
    }
    return true;
  };
  const Changer = async () => {
    if (verifier()) {
      setload(true)
      try {
        await axios.post(ChangerMDP_API,formValues)
        .then((e)=>{
          console.log(e)
          toast.success('compte mis à jour !')
          setload(false)
          props.setedit(false)
          
        })
        .catch((e)=>{
          console.log(e)
          setload(false)
          const msg = e.response.data
          if (e.response.status == 400) {
            for (var prop in msg) {
              if (msg.hasOwnProperty(prop)) {
                if (msg[prop].length > 0) {
                  setError({ ...Error, [prop]:msg[prop]});
                }
              }
            }
            return true;
          }
          else if (e.response.status == 401) {
            toast.error("Action non autorisée !")
            setError({...Error,password:["mot de passe non reconnu !"]})
          }
        })
      } catch (error) {
        console.log(error)
        setload(false)
      }
    }
    
  };
  return (
    <div className="container-fluid py-2">
      <div className="container-lg container-fluid">
        <div className=" p-3 rounded-2 h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-8">
              <div className="p-2">
                <div className="card">
                  <div className="card-header">
                    <h2>
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        size="xl"
                        className="me-2"
                      />
                      Modification de Mon compte
                    </h2>
                  </div>
                  <div className="card-body">
                    <table className="border-none w-100">
                      <tr>
                        <th className="pb-3">
                          <span className="fw-light text-muted me-2">
                            Mot de passe courrant :
                          </span>
                        </th>
                        <th className="pb-3" style={{width:'60%'}}>
                          <input
                            className={
                              "form-control " +
                              (Error.password?.length > 0 && " is-invalid")
                            }
                            name="password"
                            onChange={handleChange}
                            value={formValues.password}
                            placeholder="votre mot de passe courant"
                            type="password"
                          />
                           {Error.password.length > 0 && Error.password.map(el=>
                            <p className="invalid-feedback p-0 m-1">
                              {el}
                            </p>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-3">
                          <span className="fw-light text-muted me-2">
                            Nouveau mot de passe :
                          </span>
                        </th>
                        <th className="pb-3" style={{width:'60%'}}>
                          <input
                            className={
                              "form-control " +
                              (Error.newpassword?.length > 0 && " is-invalid")
                            }
                            name="newpassword"
                            onChange={handleChange}
                            value={formValues.newpassword}
                            placeholder="votre nouveau mot de passe"
                            type="password"
                          />
                           {Error.newpassword.length > 0 && Error.newpassword.map(el=>
                            <p className="invalid-feedback p-0 m-1">
                              {el}
                            </p>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-3">
                          <span className="fw-light text-muted me-2">
                            Confirmer nouveau mot de passe :
                          </span>
                        </th>
                        <th className="pb-3" style={{width:'60%'}}>
                          <input
                            className={
                              "form-control " +
                              (Error.confpassword?.length > 0 && " is-invalid")
                            }
                            name="confpassword"
                            onChange={handleChange}
                            value={formValues.confpassword}
                            placeholder="confirmer votre nouveau mot de passe"
                            type="password"
                          />
                          {Error.confpassword.length > 0 && Error.confpassword.map(el=>
                            <p className="invalid-feedback p-0 m-1">
                              {el}
                            </p>
                          )}
                        </th>
                      </tr>
                    </table>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-end gap-2">
                      <Button disabled={load} variant="primary" onClick={() => Changer()}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        Changer
                      </Button>
                      <Button
                        variant="secondary"
                        disabled={load}
                        onClick={() => props.setedit(false)}
                      >
                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                        Annuler
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
