import {
  faAdd,
  faBookBookmark,
  faCancel,
  faCommentAlt,
  faEdit,
  faEye,
  faFileImage,
  faQuestionCircle,
  faSchool,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../outils/Pagination";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import {
  CategorieByModule_API,
  CATEGORIE_API,
  file_url,
  lessonByModulesHateaos_API,
  ModuleCategorie_API,
  MODULE_API,
  AvisModule_API,
  moduleWithRatting,
  moduleWithRattingLessonQuestion,
} from "../../../service/config";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import TimePicker from "react-time-picker";
import ReactInputMask from "react-input-mask";
import { truncate } from "../../../outils/Truncate";
import AjoutModifierModule from "./AjoutModifierModule";
import Comment_Mod from "./Comment_Mod";
import { Rating } from "react-simple-star-rating";

const animatedComponents = makeAnimated();
const langue = [
  { value: "Malagasy", label: "Malagasy" },
  { value: "Français", label: "Français" },
];
export default function GererModule() {
  var text = "";
  const ref = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tags, settags] = useState([]);
  const [formtag, setformtag] = useState([]);
  const [loadcateg, setloadcateg] = useState(false);
  const [mode, setMode] = useState(false);
  const [id, setID] = useState(null);
  const [barColor, setBarColor] = useState("blue");
  const [options, setOptions] = useState([]);
  const [optionsInit, setOptionsInit] = useState([]);
  const [category, setCategory] = useState([]);
  const [load, setLoad] = useState(false);
  const [lg, setLg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let dataMod = [];
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState({
    titre_mod: "",
    dure_mod: null,
    method_mod: "",
    objectif_mod: "",
    support_mod: "",
    lang_mod: "",
    img_mod: null,
    desc_mod: "",
    id_categ: "",
  });
  const [vide, setVide] = useState({
    titre_mod: "",
    dure_mod: null,
    method_mod: "",
    objectif_mod: "",
    support_mod: "",
    lang_mod: "",
    img_mod: null,
    desc_mod: "",
    id_categ: "",
  });
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setModule({ ...module, [name]: value });
    setError({ ...error, [name]: null });
  };

  const getAvis = (mod) => {
    if (mod) {
      try {
        axios
          .get(AvisModule_API + "/module/" + mod)
          .then((reponse) => {
            console.log(reponse.data.monAvis);
            return reponse.data.monAvis.note_mod;
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  function manova() {
    text = "";
    module.method_mod = "";
    let nb = 0;
    tags.forEach((element) => {
      nb++;
      text += element + ";";
      if (nb === tags.length) {
        module.method_mod = text;
        setError({ ...error, method_mod: null });
      }
    });
    console.log(module.method_mod);
  }
  useEffect(() => {
    manova();
  }, [tags]);

  function manovaform() {
    text = "";
    module.support_mod = "";
    let nb = 0;
    formtag.forEach((element) => {
      nb++;
      text += element + ";";
      if (nb === formtag.length) {
        module.support_mod = text;
        setError({ ...error, support_mod: null });
      }
    });
    console.log(module.support_mod);
  }
  useEffect(() => {
    manovaform();
  }, [formtag]);

  const itemsPerPage = 10;

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    setError({ ...error, img_mod: null });

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setModule({ ...module, img_mod: e.target.files[0] });
    }
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    setIto(choice);
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.value + ",";
      if (nb === choice.length) {
        module.id_categ = text;
        setError({ ...error, id_categ: null });
      }
    });
  };

  const changeLg = (choice) => {
    setLg(choice);
    setModule({ ...module, lang_mod: choice.value });
    setError({ ...error, lang_mod: null });
  };

  const handleBlur = (e) => {
    // alert(e.target.value)
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiErrors = {};
    if (module.titre_mod === "") {
      apiErrors.titre_mod = ["le titre du module est réquis"];
      setError(apiErrors);
    } else if (module.objectif_mod === "") {
      apiErrors.objectif_mod = ["l'objectif du module est réquis"];
      setError(apiErrors);
    } else if (module.method_mod === "") {
      apiErrors.method_mod = ["la methodologie du module est réquis"];
      setError(apiErrors);
    } else if (module.support_mod === "") {
      apiErrors.support_mod = ["le support de formation du module est réquis"];
      setError(apiErrors);
    } else if (module.dure_mod === "" || module.dure_mod === null) {
      apiErrors.dure_mod = ["la durée du module est réquis"];
      setError(apiErrors);
    } else if (module.lang_mod === "" || module.lang_mod === null) {
      apiErrors.lang_mod = ["la langue du module est réquis"];
      setError(apiErrors);
    } else if (module.id_categ === "" || module.id_categ === null) {
      apiErrors.id_categ = ["la catégorie du module est réquis"];
      setError(apiErrors);
    } else if (module.img_mod === "" || module.img_mod === null) {
      apiErrors.img_mod = ["l'image du module est réquis"];
      setError(apiErrors);
    } else if (module.desc_mod === "" || module.desc_mod === null) {
      apiErrors.desc_mod = ["la déscription du module est réquis"];
      setError(apiErrors);
    } else {
      setLoad(true);
      try {
        await axios
          .post(MODULE_API, module, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success("insertion reussi");
            document.getElementById("fermerModuleModal").click();
            getModules();
            setModule(vide);
            setLoad(false);
            console.log(response);
          })
          .catch((error) => {
            toast.warn("un probleme est sruvenu");
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.lang_mod = msg.lang_mod;
              apiErrors.titre_mod = msg.titre_mod;
              apiErrors.img_mod = msg.img_mod;
              apiErrors.id_categ = msg.id_categ;
              apiErrors.desc_mod = msg.desc_mod;
              apiErrors.dure_mod = msg.dure_mod;
              apiErrors.method_mod = msg.method_mod;
              apiErrors.objectif_mod = msg.objectif_mod;
              apiErrors.support_mod = msg.support_mod;
            }

            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };
  function handleSelectAll() {
    const allOptions = options.map((option) => option.value);
    setSelectedOptions(allOptions);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();

    const apiErrors = {};
    console.log(module);
    if (module.titre_mod === "") {
      apiErrors.titre_mod = ["le titre du module est réquis"];
      setError(apiErrors);
    } else if (module.objectif_mod === "") {
      apiErrors.objectif_mod = ["l'objectif du module est réquis"];
      setError(apiErrors);
      // } else if (module.method_mod === "" || tags.length === 0) {
      // //   apiErrors.method_mod = ["la methodologie du module est réquis"];
      // //   setError(apiErrors);
      // } else if (module.support_mod === "" || formtag.length === 0) {
      //   apiErrors.support_mod = ["le support de formation du module est réquis"];
      //   setError(apiErrors);
    } else if (module.dure_mod === "" || module.dure_mod === null) {
      apiErrors.dure_mod = ["la durée du module est réquis"];
      setError(apiErrors);
    } else if (module.lang_mod === "" || module.lang_mod === null) {
      apiErrors.lang_mod = ["la langue du module est réquis"];
      setError(apiErrors);
    } else if (module.id_categ === "" || module.id_categ === null) {
      apiErrors.id_categ = ["la catégorie du module est réquis"];
      setError(apiErrors);
    } else if (module.desc_mod === "" || module.desc_mod === null) {
      apiErrors.desc_mod = ["la déscription du module est réquis"];
      setError(apiErrors);
    } else {
      setLoad(true);
      try {
        await axios
          .post(MODULE_API + "/" + id + "?_method=PUT", module, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success("modification reussi");
            document.getElementById("fermerModuleModal").click();
            getModules();
            setModule(vide);
            setLoad(false);
            console.log(response);
          })
          .catch((error) => {
            toast.warn("un probleme est sruvenu");
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.lang_mod = msg.lang_mod;
              apiErrors.titre_mod = msg.titre_mod;
              apiErrors.img_mod = msg.img_mod;
              apiErrors.id_categ = msg.id_categ;
              apiErrors.desc_mod = msg.desc_mod;
              apiErrors.dure_mod = msg.dure_mod;
              apiErrors.method_mod = msg.method_mod;
              apiErrors.objectif_mod = msg.objectif_mod;
              apiErrors.support_mod = msg.support_mod;
            }

            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };

  const GetCategorieByModule = (element, index, nb) => {
    const donnee = {};
    try {
      axios
        .get(CategorieByModule_API + element.id_mod)
        .then((response) => {
          const io = response.data.data;
          donnee.module = element;
          donnee.categ = io;

          dataMod.push(donnee);
          if (index === nb) {
            setModules(dataMod);
            //    console.log(modules)
            //    console.log(dataMod)

            ref.current.complete();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getModules = async () => {
    ref.current.continuousStart();
    dataMod = [];
    try {
      await axios
        .get(
          moduleWithRattingLessonQuestion +
            "?limit=100?order=asc?orderBy=titre_mod"
        )
        .then((response) => {
          console.log("module ----- :");
          console.log(response);
          const data = response.data.data;
          var i = 0;
          var nb = data.length;
          data.forEach((element) => {
            i++;
            GetCategorieByModule(element, i, nb);
          });
        })
        .catch((error) => {
          setBarColor("red");
          console.log(error);
        });
    } catch (error) {
      setBarColor("red");
      console.log(error);
    }
  };

  const supprimer = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      await axios
        .delete(MODULE_API + "/" + id)
        .then((response) => {
          toast.success("module numero " + id + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getModules();
        })
        .catch((error) => {
          console.log(error);
          toast.error("module pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };
  const GetCategorieByoneModule = async (categ) => {
    const GETIO = [];
    try {
      var i = 0;
      categ.forEach((element) => {
        i++;
        GETIO.push({ value: element.id_categ, label: element.categ });
        if (i === categ.length) {
          setIto(GETIO);
          change(GETIO);
          setloadcateg(false);
        }
      });
    } catch (error) {}
  };
  const getAllCategorie = async () => {
    const getCateg = [];
    try {
      const cat = await axios
        .get(CATEGORIE_API)
        .then((response) => {
          console.log(response);
          const data = response.data.data;

          data.forEach((element) => {
            getCateg.push({ value: element.id_categ, label: element.categ });
          });
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
      setOptions(getCateg);
      setCategory(cat);
      console.log(cat);
    } catch (error) {
      console.log(error);
    }
  };

  const paginatedInvoices = Pagination.getData(
    modules,
    currentPage,
    itemsPerPage
  );

  const fetchUpdate = async (categor, modul) => {
    setloadcateg(true);
    setError([]);
    setIto([]);
    setMode(true);
    setID(modul.id_mod);
    const updt = {};
    module.titre_mod = modul.titre_mod;
    module.desc_mod = modul.desc_mod;
    module.lang_mod = modul.lang_mod;
    module.dure_mod = modul.dure_mod;
    module.objectif_mod = modul.objectif_mod;
    module.support_mod = modul.support_mod;
    module.method_mod = modul.method_mod;
    var method = modul.method_mod;
    var mettremethod = method.split(";");
    mettremethod.pop();
    settags(mettremethod);
    var support = modul.support_mod;
    var mettresupport = support.split(";");
    mettresupport.pop();
    setformtag(mettresupport);
    setLg([{ value: modul.lang_mod, label: modul.lang_mod }]);
    GetCategorieByoneModule(categor);

    setImage(file_url + modul.img_mod);
  };
  const initialiser = () => {
    document.getElementById("reset_form").click();
    setID(null);
    setMode(false);
    setModule(vide);
    setImage(null);
    setIto([]);
    setLg([]);
    setError([]);
    settags([]);
    setformtag([]);
  };

  useEffect(() => {
    getModules();
    getAllCategorie();
  }, []);

  return (
    <>
      <LoadingBar ref={ref} color={barColor} />

      <div className="container-lg container-fluid py-4">
        <div
          className="modal modal-blur"
          id="modal-danger"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-danger"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon mb-2 text-danger icon-lg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9v2m0 4v.01"></path>
                  <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                </svg>
                <h3>vous etes sur ?</h3>
                <div className="text-muted">
                  vous-voulez vraiment supprimer cette module? c'est
                  irreversible.
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        id="fermerdel"
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={supprimer}
                        className={
                          "btn btn-danger w-100 " + (load && "disabled")
                        }
                      >
                        Supprimer module
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal modal-blur" tabIndex="-1" id="exampleModal">
          <div
            className="modal-dialog modal-lg"
            style={{ maxWidth: "900px" }}
            role="document"
          >
            <form
              onSubmit={id == null ? handleSubmit : handleUpdate}
              encType="multipart/form-data"
            >
              <div className="modal-content">
                <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                  <h2 className="p-2">Formulaire module</h2>
                  <button
                    type="reset"
                    className="p-0 m-0"
                    style={{ visibility: "hidden" }}
                    id="reset_form"
                  ></button>
                  <button
                    className="btn-close p-0"
                    type="button"
                    id="fermerModuleModal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body py-0">
                  <div className="my-3">
                    <div className="row">
                      <div className="col-lg-6 my-1">
                        <label className="form-label">Titre</label>
                        <input
                          className={
                            "form-control " + (error.titre_mod && "is-invalid")
                          }
                          onChange={handleChange}
                          name="titre_mod"
                          value={module.titre_mod}
                          type="text"
                          placeholder="Saisir titre du module"
                        />
                        {error.titre_mod && (
                          <p className="invalid-feedback">{error.titre_mod}</p>
                        )}
                      </div>
                      <div className="col-lg-6 my-1">
                        <label className="form-label">Objectif</label>
                        <input
                          className={
                            "form-control " +
                            (error.objectif_mod && "is-invalid")
                          }
                          onChange={handleChange}
                          name="objectif_mod"
                          value={module.objectif_mod}
                          type="text"
                          placeholder="Saisir objectif du module"
                        />
                        {error.objectif_mod && (
                          <p className="invalid-feedback">
                            {error.objectif_mod}
                          </p>
                        )}
                      </div>
                      <div className="col-12 my-1">
                        <label className="form-label">Méthodologie</label>
                        <TagsInput
                          classNames="input"
                          name="meth"
                          value={tags}
                          className={
                            "taginput form-control " +
                            (error.method_mod && "is-invalid")
                          }
                          onChange={settags}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              let val = e.target.value;
                              let nam = e.target.name;
                              settags([...tags, val]);
                              document.getElementsByName(nam)[0].value = "";
                            }
                          }}
                          separators={[";", ",", ""]}
                          placeHolder="séparez-les méthodologie par des points virgules (;) ou des virgules (,)"
                        />

                        <p
                          className={" " + (error.method_mod && "text-danger")}
                        >
                          {error.method_mod}
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <label className="form-label">
                          Support de formation
                        </label>
                        <TagsInput
                          value={formtag}
                          name="form"
                          className={
                            "taginput w-100 " +
                            (error.support_mod && "is-invalid")
                          }
                          onChange={setformtag}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              let val = e.target.value;
                              let nam = e.target.name;
                              setformtag([...formtag, val]);
                              document.getElementsByName(nam)[0].value = "";
                            }
                          }}
                          separators={[";", ","]}
                          placeHolder="séparez-les support de formation par des points virgules (;) ou des virgules (,)"
                        />
                        {error.support_mod && (
                          <p
                            className={
                              " " + (error.support_mod && "text-danger")
                            }
                          >
                            {error.support_mod}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-4 my-1">
                        <label className="form-label">Durée (en heure)</label>
                        <input
                          className={
                            "form-control " + (error.dure_mod && "is-invalid")
                          }
                          onChange={handleChange}
                          name="dure_mod"
                          value={module.dure_mod}
                          type="number"
                          placeholder="Saisir la durée"
                        />
                        {error.dure_mod && (
                          <p className="invalid-feedback">{error.dure_mod}</p>
                        )}
                      </div>
                      <div className="col-lg-3 my-1">
                        <label className="form-label">Langue</label>
                        <Select
                          closeMenuOnSelect
                          components={animatedComponents}
                          defaultValue={[langue[0]]}
                          isMulti={false}
                          name="lang_mod"
                          value={lg}
                          placeholder="choix langue"
                          options={langue}
                          onChange={(lg) => changeLg(lg)}
                        />
                        {error.lang_mod && (
                          <p className="text-danger fs-5">{error.lang_mod}</p>
                        )}
                      </div>
                      <div className="col-lg-5 my-1">
                        <label className="form-label">Categorie</label>

                        <div className="d-flex w-100 gap-2 align-items-center">
                          <Select
                            closeMenuOnSelect
                            components={animatedComponents}
                            isMulti
                            placeholder="choix catégorie"
                            options={options}
                            name="id_categ"
                            value={ito}
                            onChange={(choice) => change(choice)}
                            noOptionsMessage={() => "plus d'options"}
                            isDisabled={loadcateg}
                            isLoading={loadcateg}
                          />
                          {error.id_categ && (
                            <p className="text-danger fs-5">{error.id_categ}</p>
                          )}
                          <button
                            className="btn btn-cyan"
                            type="button"
                            onClick={() => change(options)}
                          >
                            toutes
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 my-2 ">
                        <label className="form-label">
                          Image de representation du module
                        </label>

                        <input
                          type="file"
                          className={
                            "form-control " + (error.img_mod && "is-invalid")
                          }
                          name="img_mod"
                          id="img_mod"
                          onChange={onImageChange}
                          placeholder="choisir un image"
                          accept="image/png, image/gif, image/jpeg"
                          multiple
                        />
                        {error.img_mod && (
                          <p className="invalid-feedback">{error.img_mod}</p>
                        )}

                        {image && (
                          <span
                            className="avatar avatar-lg my-3"
                            style={{ backgroundImage: `url(${image})` }}
                          ></span>
                        )}
                      </div>
                      <div className="col-lg-6 my-2 ">
                        <label className="form-label">
                          Description du module
                        </label>
                        <textarea
                          rows={5}
                          className={
                            "form-control " + (error.desc_mod && "is-invalid")
                          }
                          value={module.desc_mod}
                          name="desc_mod"
                          onChange={handleChange}
                          placeholder="saisir description"
                        ></textarea>
                        {error.desc_mod && (
                          <p className="text-danger fs-5">{error.desc_mod}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row justify-content-between">
                      <div className="col d-flex justify-content-start">
                        <button
                          type="button"
                          className="btn"
                          data-bs-dismiss="modal"
                        >
                          <FontAwesomeIcon icon={faCancel} className="me-2" />{" "}
                          Annuler
                        </button>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <button
                          type="submit"
                          className={"btn btn-primary " + (load && "disabled")}
                        >
                          <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                          {id == null ? <>Ajouter</> : <>Modifier</>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h2>Modules</h2>
            <p className="">Liste des modules</p>
          </div>
          <div>
            <AjoutModifierModule getModules={getModules} />
          </div>
        </div>

        <div className="overflow-auto">
          <table className="table table-hover table-bordered table-striped table-light ">
            <thead>
              <tr className="">
                <th>Titre</th>
                <th>Categorie</th>
                <th>Langue</th>
                <th>Description</th>
                <th>Durée</th>
                <th>Image</th>
                <th>Note Moyenne</th>
                <th>Les commentaires</th>
                <th>Leçons et exercices</th>
                <th className="col-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedInvoices.map((element, index) => (
                <tr key={index}>
                  <td>{element.module.titre_mod}</td>
                  <td className="text-center">
                    <div className="d-flex flex-wrap gap-1">
                      {element.categ.map((el, io) => (
                        <span key={io} className="badge bg-cyan">
                          {el.categ}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>{element.module.lang_mod}</td>
                  <td>
                    {truncate(
                      element.module.desc_mod
                        ? element.module.desc_mod
                        : "aucun"
                    )}
                  </td>
                  <td>
                    <span className="badge bg-cyan rounded-5">
                      {element.module.dure_mod} h
                    </span>
                  </td>
                  <td>
                    {" "}
                    <span
                      className="avatar avatar-sm"
                      style={{
                        backgroundImage: `url(${file_url}/${element.module.img_mod})`,
                      }}
                    ></span>
                  </td>
                  <td className="text-center fw-bold">
                    {" "}
                    <Rating
                      showTooltip={false}
                      /* tooltipArray={tooltipArraylist}*/
                      tooltipDefaultText={
                        Math.round(element.module.avg_note * 100) / 100
                      }
                      size={"20px"}
                      titleSeparator="sur"
                      initialValue={element.module.avg_note}
                      readonly={true}
                      /* Available Props */
                    />
                  </td>
                  <td>
                    <Comment_Mod id={element.module.id_mod} />
                  </td>
                  <td className="col-2 ">
                    <div className="d-flex">
                      <Link
                        to={"/admin/module lesson/" + element.module.id_mod}
                        state={{ lesson: element.module.modulelessons }}
                      >
                        ​
                        <span className="my-1 px-3  py-2 badge bg-cyan me-2">
                          <FontAwesomeIcon icon={faBookBookmark} /> Leçon :{" "}
                          {element.module.num_lessons
                            ? element.module.num_lessons
                            : 0}
                        </span>
                      </Link>
                      <Link
                        to={"/admin/module exercice/" + element.module.id_mod}
                      >
                        <span className="my-1 px-3 py-2 badge bg-cyan">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Exercice :{" "}
                          {element.module.num_questionnaires
                            ? element.module.num_questionnaires
                            : 0}
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td className="d-flex gap-2 justify-content-center">
                    <AjoutModifierModule
                      getModules={getModules}
                      id={element.module.id_mod}
                    />
                    <button
                      className="btn btn-danger"
                      onClick={() => setID(element.module.id_mod)}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChanged={handlePageChange}
              length={modules.length}
            />
          </div>
        </div>
      </div>
    </>
  );
}
