import p0 from "../../assets/svg/categorie/0.svg";
import p1 from "../../assets/svg/categorie/1.svg";
import p2 from "../../assets/svg/categorie/2.svg";
import p3 from "../../assets/svg/categorie/3.svg";
import p4 from "../../assets/svg/categorie/4.svg";
import p5 from "../../assets/svg/categorie/5.svg";
import p6 from "../../assets/svg/categorie/6_1.svg";
import p7 from "../../assets/svg/categorie/7.svg";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import {
  CategorieByModule_API,
  CATEGORIE_API,
  file_url,
  moduleByCategorie,
  modulebylanguecateg_API,
} from "../../service/config";
import { toast } from "react-toastify";
export default function List_module(props) {
  let { id } = useParams();
  function truncate(str) {
    if (str.length < 50) {
      return str;
    } else {
      return str.substring(0, 100) + "...";
    }
  }
  const [loadcateg, setloadcateg] = useState(true);
  const [categorie, setCategorie] = useState({});
  const location = useLocation();
  const state = [
    {
      id: "C",
      sary: "p0",
      data: " Véhicules de moins de 10 pièces et d'un poids  total en charge n'excedant pas 3 500kg (Fiarakodia misy toerana latsaky ny 10. Lanjany: latsaky ny 3 500kg)",
    },
    {
      id: "F",
      sary: "p1",
      data: " Véhicules de la catégorie B spécialement aménagés (Ireo Fiarakodia ao amin'ny karazana B, ka manana ny fampiasana azy manokana)",
    },
    {
      id: "D",
      sary: "p2",
      data: " Véhicules de transport en commun (plus de 9 places) (Fiarakodia mpitatitra olona misy toerana mihoatra ny 9)",
    },
    {
      id: "B",
      sary: "p3",
      data: " Véhicules de moins de 10 pièces et d'un poids  total en charge n'excedant pas 3 500kg (Fiarakodia misy toerana latsaky ny 10. Lanjany: latsaky ny 3 500kg)",
    },
    {
      id: "BE",
      sary: "p4",
      data: "Affecté au transport de personnes ou de marchandises. PTAC : Poids total en charge du véhicule ",
    },
    {
      id: "E",
      sary: "p5",
      data: "Véhicules des categories B,C,D attélés d'un remorque de plus de 750kg (Ireo Fiarakodia ao amin'ny karazana B,C,D mahazaka 'remorque' mihoatra ny 750 kg)",
    },
    {
      id: "A",
      sary: "p6",
      data: " Motocyclettes avec ou sans side-car, Tricycles à moteur de plus de 125 cm³ (Motosiklety na tricycle mihoatra ny 125 cm³)",
    },
    {
      id: "A'",
      sary: "p7",
      data: "Vélomoteurs et vehicles à moteur de cylindrée de 50 cm³ sans exceder 125 cm³ (Fiarakodia manomboka eo amin'ny 50 cm³ ka hatramin'ny 125 cm³)",
    },
  ];
  const [data, setData] = useState(null);
  let dataMod = [];
  const [load, setLoad] = useState(true);
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  const [modules, setModules] = useState([]);

  const getCategorie = async () => {
    setloadcateg(true);
    try {
      await axios
        .get(CATEGORIE_API)
        .then((reponse) => {
          setCategorie(reponse.data.data);
          console.log(reponse.data);
          reponse.data.data.map((element, ind) => {
            if (element.categ === id) {
              setCategorie(element);
              setloadcateg(false);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error("verifier votre connexion");
        });
    } catch (error) {
      console.log(error);
      toast.error("erreur interne!");
    }
  };
  const GetCategorieByModule = (element, index, nb) => {
    const donnee = {};
    try {
      axios
        .get(CategorieByModule_API + element.id_mod)
        .then((response) => {
          const io = response.data.data;
          donnee.module = element;
          donnee.categ = io;

          dataMod.push(donnee);
          if (index === nb) {
            setModules(dataMod);
            //    console.log(modules)
            //    console.log(dataMod)
            setLoad(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getModules = async () => {
    setLoad(true);
    dataMod = [];
    try {
      await axios
        .get(
          modulebylanguecateg_API + localStorage.getItem("langue") + "/" + id
        )
        .then((response) => {
          console.log(response);
          const data = response.data.data;
          setModules(data);
          setLoad(false)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const test = () => {
    state.forEach((element) => {
      if (element.id === id) {
        setData(element);
      }
    });
  };

  const styleCard = {
    position: "relative",
    zIndex: "20",
  };
  const styleBulle = {
    position: "absolute",
    top: "-25px",
    left: "41%",
    zIndex: "56",
  };
  useEffect(() => {
    getCategorie();
    console.log("mande");
  }, [props.load_categorie]);
  useEffect(() => {
    test();
    getModules();
  }, [props.test]);

  return (
    <div className="bg-light m-0 p-0 ">
      <div className="section1 m-0 p-0">
        <div className="container-lg container-fluid p-3">
          {loadcateg ? (
            <div className="row flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
              <div className="col-12 col-sm-8 col-lg-4 placeholder py-3">
                {loadcateg ? (
                  <div className="p-2"></div>
                ) : (
                  <img
                    src={file_url + categorie.img_categ}
                    className="d-block mx-lg-auto img-fluid text-center placeholder"
                    style={{ width: "35%" }}
                    alt="Bootstrap Themes"
                    loading="lazy"
                  />
                )}
              </div>
              <div className="col-lg-5">
                <h1 className="display-7 fw-bold lh-1 mb-3 placeholder p-3">
                  fdfd {categorie.categ}
                </h1>
                <p className="lead placeholder py-3"></p>
              </div>
            </div>
          ) : (
            <div className="row flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
              <div className="col-12 col-sm-8 col-lg-4">
                <img
                  src={file_url + categorie.img_categ}
                  className="d-block mx-lg-auto img-fluid text-center"
                  style={{ width: "35%" }}
                  alt="permis categorie"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-5">
                <h1 className="display-7 fw-bold lh-1 mb-3 text-primary">
                  Catégorie {categorie.categ}
                </h1>
                <p className="lead">{categorie.desc_categ}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="m-0 bg-light p-lg-5 py-lg-3 p-0 my-0 ">
        <div className="container-lg container-fluid">
          <div className="row">
            {load ? (
              <>
                {chiffre.map((io, i) => (
                  <div
                    key={i}
                    className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-3"
                  >
                    <div
                      className="card effet rounded-4 placeholder"
                      style={styleCard}
                    >
                      <div
                        className="card-header placeholder rounded-top-4 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                        style={{
                          position: "relative",
                          zIndex: "20",
                          backgroundSize: "cover",
                        }}
                      >
                        <span
                          className="avatar avatar-md bg-azure text-white mb-3 placeholder avatar-rounded"
                          style={styleBulle}
                        >
                          A'
                        </span>
                      </div>
                      <div className="card-body placeholder">
                        <Link to="/details/2">
                          <h5 className="card-title text-center placeholder">
                            ishdhsufhsfuisd
                          </h5>
                        </Link>
                        <p className="card-text fw-light text-center fs-4 pb-4 placeholder">
                          Lorem nfuiwhweurgwh uy hh uegwe yugewyu gweu gweuwg
                          ueygw uygweuygeywu
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : modules.length === 0 ? (
              <div className="w-100  my-5 p-5">
                <p className="display-5 fw-light text-secondary text-center">
                  Aucun modules
                </p>
              </div>
            ) : (
              modules.map((element, index) => (
                <div
                  key={index}
                  className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-3"
                >
                  <div className="card effet rounded-4 w-100">
                    <div
                      className="card-header rounded-top-4 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                      style={{
                        backgroundImage: `url(${file_url}${element.img_mod})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <p className="p-3"></p>
                    </div>
                    <div className="card-body">
                      <Link to={`/details/${element.id_mod}`}>
                        <h5 className="card-title">
                          {element.titre_mod}
                        </h5>
                      </Link>
                      <div className="card-subtitle d-flex flex-row flex-wrap gap-1">
                        {element.categories.map((el, ino) => (
                          <span key={ino} className="badge bg-cyan px-3">
                            {el.categ}
                          </span>
                        ))}
                        <span className="border fst-italic px-2 rounded-3">
                          {element.lang_mod}
                        </span>
                      </div>

                      <p className="card-text fw-light fs-4 pb-4">
                        {truncate(element.desc_mod)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
