import {
  faArrowAltCircleRight,
  faAward,
  faBlenderPhone,
  faCircleInfo,
  faFile,
  faFilePdf,
  faFileWord,
  faListDots,
  faNewspaper,
  faPersonWalkingArrowLoopLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ACTU_API, file_url, SENSI_API } from "../../../service/config";
import { Button } from "react-bootstrap";
import RetourComp from "../../../outils/RetourComp";

export default function Details_sens() {
  let { id } = useParams();
  const [load, setload] = useState(true);
  const [textlois, setTextlois] = useState({});
  const navigate = useNavigate();
  function Separer(strin) {
    if (strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }

  const getTextloi = async () => {
    setload(true);
    try {
      await axios
        .get(SENSI_API + "/" + id)
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.sensibilisation);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();
  }, []);

  return (
    <div className="container-lg container-fluid py-3 mt-2">
     
      <div className="card w-100 mb-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h1 className="fst-italic">
            <RetourComp />
            Message de sensibilisation
          </h1>
          <Button
            variant="primary"
            style={{ position: "relative", zIndex: "55" }}
            onClick={() => navigate("/tous les message de sensibilisation")}
          >
            Toutes les messages de sensibilisation{" "}
            <FontAwesomeIcon className="ms-2" icon={faListDots} />
          </Button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row  flex-lg-row-reverse justify-content-between g-5 py-4">
            <div className="col-12 col-sm-8 col-lg-4">
              {load === false && (
                <img
                  src={file_url + textlois.img_sens}
                  className="d-block mx-lg-auto img-fluid text-center align-self-end"
                  style={{ width: "100%" }}
                  alt="permis categorie"
                  loading="lazy"
                />
              )}
            </div>
            <div className="col-lg-8">
              <h1 className="display-7 fw-bold lh-1 mb-3 text-primary">
                {textlois.titre_sens}
              </h1>
              <div className="my-2 d-flex gap-1"></div>
              <p className="lead w-100">{textlois.desc_sens}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
