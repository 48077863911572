import { faEye, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { activeUser } from "../../../service/config";
import { toast } from "react-toastify";
import { Tag } from "primereact/tag";

export default function Details(props) {
  const [show, setshow] = useState(false);
  const handleShow = () => setshow(!show);
  const [load, setload] = useState(false);
  const [data, setdata] = useState({});
  const ExclureUser = () => {
    setload(true);
    axios
      .post(activeUser + data.id + "?_method=PUT",{
        active_user :"Bloquer"
      })
      .then((rer) => {
        console.log(rer);
        toast.success("utilisateur exclu !");
        handleShow();
        props.getUsers();
        setload(false);
      })
      .catch((err) => {
        toast.error("Un erreur est survenu !");
        console.log(err);
        handleShow();
        setload(false);
      });
  };
  useEffect(() => {
    setdata(props.data);
  }, []);
  return (
    <div>
      <div>
        <Button variant="primary" onClick={handleShow}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>
      <Modal show={show} size="lg" onHide={handleShow}>
        <Modal.Header className="row">
          <div className="d-flex align-items-center col-12 justify-content-between">
            <div className="text-center h3">Information de compte</div>
          <div className="d-flex align-items-center gap-2">
          Status:{" "}
              <Tag severity={data.status_util == "Bloquer" ? "danger" :data.status_util == "Activer" ? "success" :"warning"}>{data.status_util == "Bloquer" ? "Exclus" :data.status_util == "Activer" ? "Activé" :"Désactivé" }</Tag>
              {/* <span className="text-primary">changer</span> */}
            
          </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-3">
            <div className="col-6 ">
              <p>
                Type de compte :{" "}
                <span className="badge bg-cyan">{data?.type_util}</span>{" "}
              </p>
              <p>Nom: {data?.nom_util}</p>
              <p>Prenom : {data?.prenom_util}</p>
              <p>Email : {data?.email}</p>
              <p>Télephone : {data?.tel_util}</p>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <FontAwesomeIcon icon={faUserCircle} size="9x" color="gray" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-end">
           {data.status_util != "Bloquer" && <Button
              variant="danger"
              disabled={load}
              onClick={() => ExclureUser()}
            >
              Exclure
            </Button>}
            <Button variant="secondary" onClick={handleShow}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
