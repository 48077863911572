import {
  faBackward,
  faBookBookmark,
  faCirclePlay,
  faComment,
  faFile,
  faFilePdf,
  faFileWord,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { TailSpin } from "react-loader-spinner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import './scrollbarcustom.css'
import {
  CategorieByModule_API,
  commentmodule_API,
  FichierLesson_API,
  file_url,
  LESSON_API,
  moduleByLesson,
  MODULE_API,
  AvisModule_API,
  lessonByModulesHateaos_API,
  commentlesson_API,
  ListLessonHateaos_API,
  AvisLesson_API,
  Pourcentage_API,
  chapitresByLesson,
  chapitres,
} from "../../service/config";
import LessonContext from "../../service/context/LessonContext";
import isconnected from "../../service/isconnected";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Button } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { ScrollPanel } from "primereact/scrollpanel";
import RetourComp from "../../outils/RetourComp";

export default function Lessons(props) {
  const [loadChapter,setloadChapter] = useState(true)
  const navigate = useNavigate();
  const [Change, setChange] = useState(true);
  const [infolesson, setinfolesson] = useState({});
  function verifier(url) {
    var parts = url.split(".");
    var extension = parts.pop();
    return extension;
  }
  // eslint-disable-next-line
  const download = async (ur) => {
    const url = file_url + ur;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  let { pathname } = useLocation();
  const [avis, setAvis] = useState({});

  const [loadFich, setloadFich] = useState(true);
  const [loadcom, setloadcom] = useState(false);
  const [fichier, setFichier] = useState([]);
  const [rating, setRating] = useState(0);
  const [idmod, setidmod] = useState(null);
  const [modules, setmodules] = useState({});
  const [chapter, setchapter] = useState([]);
  const [current, setCurrent] = useState(null);
  const [Time, setTime] = useState(0);
  const [comment, setComment] = useState({
    com_mod: "",
    id_mod: idmod,
  });
  const vide = useState({
    com_mod: "",
    id_mod: idmod,
  });
  const [content, setContent] = useState({
    id_les: "",
    titre_les: "",
    img_les: "",
    desc_les: "",
  });
  const [listcontent, setlistcontent] = useState([]);
  const location = useLocation();
  const rech = location.state?.rech;
  const lessonrech = location.state?.lessonrech;
  const [module, setmodule] = useState([]);

  const [load, setLoad] = useState(true);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(65);
  let { id } = useParams();
  let dataLes = [];
  let dataAll = [];

  function comparer(a, b) {
    if (a.order_chap < b.order_chap) return -1;
    if (a.order_chap > b.order_chap) return 1;
    return 0;
}

  const AddPourcent = async (tps) => {
    try {
      await axios
        .post(Pourcentage_API, {
          temps_pour: tps,
          id_les: id,
        })
        .then((rp) => {
          console.log(rp);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };
  const getAvis = async (id) => {
    try {
      await axios
        .get(AvisLesson_API + "/lesson/" + id)
        .then((reponse) => {
          console.log(reponse.data.monAvis);
          setAvis(reponse.data.monAvis);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getcontent = (chap_id) => {
    const idtrouver = listcontent.find((item) => item.id_chap === chap_id);
    if (idtrouver) {
      setContent(idtrouver);
    } else {
      axios.get(chapitres + "/" + chap_id).then((rep) => {
        console.log(rep.data);
        setContent(rep.data.data);
        setlistcontent([...listcontent, rep.data.data]);
      });
    }
  };
  const getAllLesson = () => {
    setLoad(true);
    try {
      axios
        .get(ListLessonHateaos_API)
        .then((response) => {
          console.log(response);
          const database = response.data.data;
          let io = {};
          database.forEach((element) => {
            if (element.id_les == id) {
              setinfolesson(element);
              getChapterByLesson();
              io = element;
              getAvis(id);
              getAllFichier(element.id_les);
            }
          });
          io?.id_les ? setLoad(false) : navigate(-1);
        })
        .catch((error) => {
          console.log(error);
          navigate(-1);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getChapterByLesson = () => {
    setloadChapter(true)
    axios.get(chapitresByLesson + id).then((rep) => {
      console.log(rep)
      let chap = rep.data.data
      chap.sort(comparer);
      setchapter(chap);
      setloadChapter(false)
    });
  };

  const getAllFichier = (id_les) => {
    setloadFich(true);
    try {
      axios
        .get(FichierLesson_API + "/lesson/" + id_les)
        .then((response) => {
          setFichier(response.data.fichierl);
          console.log(response);
          setloadFich(false);
        })
        .catch((e) => {
          setloadFich(false);
          console.log(e);
        });
    } catch (error) {
      console.log(error);
      setloadFich(false);
    }
  };

  const addContent = (element) => {
    setContent(element);
    console.log(element);
    getAllFichier(element.id_les);
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setComment({ ...comment, [name]: value });
  };

  const addcommentaire = async (e) => {
    e.preventDefault();
    setloadcom(true);
    console.log("io lesy izy" + id);
    try {
      await axios
        .post(commentlesson_API, {
          com_les: comment.com_mod,
          id_les: id,
        })
        .then((response) => {
          console.log(response);
          toast.success("commentaire publier");
          setComment({ ...comment, com_mod: "" });
          setloadcom(false);
        })
        .catch((error) => {
          console.log(error);
          setloadcom(false);
        });
    } catch (error) {
      console.log(error);
      setloadcom(false);
    }
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);
  
    let timerId = setInterval(() => {
      AddPourcent(Time);
    }, 15000);
  
    return () => {
      clearInterval(intervalId);
      clearInterval(timerId);
    };
  }, []);

  const tooltipArraylist = [
    "très mauvais",
    "mauvais",
    "modérée",
    "très bon",
    "excellent",
  ];

  const addAvis = async (note, id) => {
    try {
      axios
        .post(AvisLesson_API, {
          note_les: note,
          id_les: id,
        })
        .then((response) => {
          console.log(response);
          toast.success("avis ajouté !");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            axios
              .put(AvisLesson_API + "/" + avis.id_avisles, {
                note_les: note,
                id_les: id,
              })
              .then((response) => {
                console.log(response);
                toast.success("avis ajouté !");
              });
          }
        });
    } catch (error) {}
  };

  const handleRating = (rate, id) => {
    // setRating(rate);
    addAvis(rate, id);
  };

  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };
  useEffect(() => {
    getAllLesson();
  }, [module]);

  return (
    <div className=" bg-light m-0 h-100 py-5">
      {load ? (
        <div className="h-100 align-items-center d-flex gap-2 justify-content-center align-items-center p-5 m-5">
          <div className="display-6 h-100">chargement de la leçon...</div>
        </div>
      ) : (
        <div className="container-lg container-fluid bg-light h-100">
          <div className="d-initial py-5 boutton-open-canvas">
            <button
              className="btn btn-primary "
              data-bs-toggle="offcanvas"
              style={{
                position: "fixed",
                zIndex: "88",
                bottom: "50%",
                left: "0",
              }}
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <FontAwesomeIcon icon={faBookBookmark} />
            </button>
          </div>
          <div className="row">
            <div
              className="col-3  offcanvas-md offcanvas-end tab"
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <h4 className="offcanvas-title" id="offcanvasExampleLabel">
                  Listes des leçons
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  data-bs-dismiss="offcanvasExample"
                  aria-label="Close"
                ></button>
              </div>
              <StickyBox offsetTop={20} offsetBottom={20}>
                <div className="p-3 bg-white rounded-3 h-100">
                  <h4>SOMMAIRES</h4>
                 {loadChapter ? 'Chargement des chapitres...' :  <ol className="list-lesson">
                    <li
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setContent({ ...content, id_chap: "" });
                        setCurrent(null);
                        setTime(0);
                      }}
                    >
                      <span
                        className={
                          !content.id_chap ? "w-100 text-primary" : "w-100"
                        }
                        data-bs-toggle="offcanvas"
                        data-bs-target=".offcanvas-md.show"
                      >
                        A propos
                      </span>
                    </li>
                    {chapter?.length == 0 ? (
                      <div>Aucun chapitre</div>
                    ) : (
                      chapter?.map((element, index) => (
                        <li
                          key={index}
                          id={`content${index}`}
                          onClick={() => {
                            getcontent(element.id_chap);
                            setCurrent(index);
                            window.scrollTo(0, 0);
                          }}
                          className={
                            element.id_chap == content.id_chap && "text-primary"
                          }
                        >
                          <span
                            className="w-100"
                            data-bs-toggle="offcanvas"
                            data-bs-target=".offcanvas-md.show"
                          >
                            {<>{element.titre_chap}</>}
                          </span>
                        </li>
                      ))
                    )}
                  </ol>}
                </div>
              </StickyBox>
            </div>
            <div className="col">
              <div className="container">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                  {!content.id_chap && (
                    <div className="col-12 col-sm-8 col-lg-6">
                      <img
                        src={file_url + infolesson.img_les}
                        className="d-block mx-lg-auto img-fluid text-center"
                        alt="Bootstrap Themes"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <div className={!content.id_chap ? "col-lg-6" : "col-lg-12 "}>
                    <h1 className="fw-bold lh-1 mb-3 fs-1">
                    <RetourComp/>{infolesson.titre_les}
                    </h1>

                   {!content.id_chap && <ScrollPanel className="custombar1" style={{ width: "100%",height: "200px",overflow:'auto' }}>
                      {!content.id_chap && (
                        <p className="lead text-justify">
                          {infolesson.desc_les}
                        </p>
                      )}
                    </ScrollPanel>}

                    <Rating
                      onClick={(rate) => handleRating(rate, infolesson?.id_les)}
                      className="mb-3"
                      size={"18px"}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      showTooltip={true}
                      tooltipClassName="fs-6"
                      tooltipArray={tooltipArraylist}
                      tooltipDefaultText="votre note"
                      titleSeparator="sur"
                      initialValue={
                        avis == null || avis === {} ? 0 : avis.note_les
                      }
                      readonly={false}
                      /* Available Props */
                    />
                    <div className="d-flex flex-wrap gap-2 ">
                      {infolesson?.modulelessons?.map((el, i) => (
                        <Tag className="px-3 py-2 fs-6">
                          {el.modules.titre_mod}
                        </Tag>
                      ))}
                    </div>
                  </div>
                  <div className="col-12">
                    {!content.id_chap ? (
                      <div className="gap-2 align-items-center d-flex justify-content-start my-3">
                        <Button
                          type="button"
                          disabled={chapter?.length > 0 ? false : true}
                          className="btn btn-primary w-50 btn-md px-4 me-md-2 rounded-1"
                          onClick={() => {
                            document.getElementById(`content0`).click();
                            setTime(0);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCirclePlay}
                            className="me-2"
                          />{" "}
                          Commencer ce cours
                        </Button>
                        <div
                          onClick={() => navigate(-1)}
                          className="text-primary btn btn-outline w-50"
                        >
                          retour
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12 bg-white">
                          <div className=" text-start p-2 my-2">
                            <h1>
                              <FontAwesomeIcon
                                className="text-info me-3"
                                icon={faBookBookmark}
                              />
                              {content?.titre_chap}
                            </h1>
                            <div
                              className="  non-edit"
                              style={{ borderStyle: "none" }}
                            >
                              <CKEditor
                                editor={Editor}
                                data={content?.desc_chap}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                  editor.enableReadOnlyMode("editor");
                                  const toolbar =
                                    editor.ui.view.toolbar.element;
                                  toolbar.style.display = "none";
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                }}
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( ev ent, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 pt-3">
                          <div className="p-3 bg-white">
                            <h4>Description Image</h4>
                            <div className="row">
                              {loadFich ? (
                                <div className="display-6 text-center">
                                  Chargement des fichiers....
                                </div>
                              ) : fichier.length === 0 ? (
                                <div className="display-6 text-center">
                                  Aucun fichier
                                </div>
                              ) : (
                                fichier.map((element, index) => {
                                  if (element.type_fichl === "Photo") {
                                    return (
                                      <div
                                        key={index}
                                        className="my-2 col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch p-2"
                                      >
                                        <div className="py-3">
                                          <h5>{element.titre_fichl}</h5>
                                          <img
                                            src={file_url + element.fichierl}
                                            height="100%"
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pt-3">
                          <div className="p-3 bg-white">
                            <h4>Description video</h4>
                            {loadFich ? (
                              <div className="display-6 text-center">
                                Chargement des fichiers....
                              </div>
                            ) : (
                              <div className="row gap-2">
                                {fichier.map((element, index) => {
                                  if (element.type_fichl === "Video") {
                                    return (
                                      <div
                                        key={index}
                                        className="col-lg-4 col-md-6 col-sm-12 col-12"
                                      >
                                        <h5 className="my-2">
                                          {element.titre_fichl}
                                        </h5>
                                        <div
                                          className="embed-responsive embed-responsive-16by9"
                                          style={{ height: "200px" }}
                                        >
                                          <iframe
                                            title={element.titre_fichl}
                                            className="embed-responsive-item w-100 h-100"
                                            src={file_url + element.fichierl}
                                            allowFullScreen
                                          ></iframe>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 pt-3">
                          <div className="p-3 bg-white">
                            <h4>Description Audio</h4>
                            {fichier.map((element, index) => {
                              if (element.type_fichl === "Audio") {
                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 col-12"
                                  >
                                    <h5 className="my-2">
                                      {element.titre_fichl}
                                    </h5>
                                    <figure>
                                      <audio
                                        controls
                                        src={file_url + element.fichierl}
                                      >
                                        <a href={file_url + element.fichierl}>
                                          Telecharger
                                        </a>
                                      </audio>
                                    </figure>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-12 pt-3">
                          <div className="p-3 bg-white">
                            <h4>Description document et autre</h4>
                            <div className="row gap-2">
                              {fichier.map((element, index) => {
                                if (element.type_fichl === "Document") {
                                  return (
                                    <div
                                      key={index}
                                      className="col-lg-4 col-md-6 col-sm-12 col-12"
                                    >
                                      <h5 className="my-2">
                                        {element.titre_fichl}
                                      </h5>
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          download(element.fichierl)
                                        }
                                      >
                                        Télécharger{" "}
                                        {verifier(element.fichierl) === "doc" ||
                                        verifier(element.fichierl) ===
                                          "docx" ? (
                                          <FontAwesomeIcon
                                            className="ps-2"
                                            size="2x"
                                            icon={faFileWord}
                                          />
                                        ) : verifier(element.fichierl) ===
                                          "pdf" ? (
                                          <FontAwesomeIcon
                                            className="ps-2"
                                            size="2x"
                                            icon={faFilePdf}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            className="ps-2"
                                            size="2x"
                                            icon={faFile}
                                          />
                                        )}
                                      </button>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pt-3">
                          <div className="p-3">
                            <div className="d-flex flex-row justify-content-end gap-1">
                              {current == 0 ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setContent({ id_chap: "" });
                                    window.scrollTo(0, 0);
                                    setTime(0);
                                  }}
                                  className={"btn btn-secondary px-4"}
                                >
                                  Précedent
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => {
                                    document
                                      .getElementById(`content${current - 1}`)
                                      .click();
                                    setTime(0);
                                  }}
                                  className={
                                    current > 0 && current != null
                                      ? "btn btn-secondary px-4"
                                      : "btn btn-secondary px-4 disabled"
                                  }
                                >
                                  Précedent
                                </button>
                              )}

                              <button
                                type="button"
                                onClick={() => {
                                  document
                                    .getElementById(`content${current + 1}`)
                                    .click();
                                  setTime(0);
                                }}
                                className={
                                  chapter.length - 1 > current
                                    ? " btn btn-primary px-4 "
                                    : " btn btn-primary px-4 disabled"
                                }
                              >
                                Suivant
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 bg-white p-lg-5 p-md-3 p-sm-3 p-3 my-5">
              <form className="w-100" onSubmit={addcommentaire}>
                <div className="row gap-3 d-flex align-items-center">
                  <div className="col">
                    <h2 className="mb-3">Commentaire et avis</h2>

                    {isconnected.Authenticated() === false && (
                      <div className="alert alert-info">
                        <strong>Important!</strong> vous devez avoir un{" "}
                        <Link to="/inscription" className="alert-link">
                          <span>compte</span>
                        </Link>{" "}
                        et vous{" "}
                        <Link
                          to="/login"
                          state={{ url: pathname }}
                          className="alert-link"
                        >
                          <span>connecter</span>
                        </Link>{" "}
                        pour pouvoir ajouter un commentaire.
                      </div>
                    )}
                    <div>
                      <textarea
                        className={"form-control "}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        value={comment.com_mod}
                        placeholder="Votre commentaire ici..."
                        onChange={handleChange}
                        name="com_mod"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row gap-3">
                      <div className="col-12">
                        <Link
                          to={"/commentaires lesson/" + id}
                          className="text-white text-decoration-none  w-100"
                        >
                          <button className="btn btn-primary text-white  w-100 py-2 text-nowrap">
                            Voir toutes les commentaires
                            <FontAwesomeIcon
                              icon={faComment}
                              className="ms-2"
                              color="white"
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="col-12">
                        {isconnected.Authenticated() === false ||
                        comment.com_mod.length < 2 ? (
                          <button
                            type="button"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 disabled"
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 " +
                              (loadcom && "disabled")
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
