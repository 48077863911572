import {
  faAdd,
  faCancel,
  faEdit,
  faEye,
  faFile,
  faSchool,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../outils/Pagination";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  FichierLesson_API,
  FichierLois_API,
  file_url,
  LESSON_API,
  ListLessonHateaos_API,
  moduleByLesson,
  MODULE_API,
  TexteLois_API,
  TypeLois_API,
} from "../../../service/config";
import axios from "axios";
import { toast } from "react-toastify";
import GererTypeLois from "./GererTypeLois";

const animatedComponents = makeAnimated();
const Fichier = [
  { value: "Video", label: "Video" },
  { value: "Photo", label: "Photo" },
  { value: "Document", label: "Document" },
];
export default function GererTexteLois() {
  const ref = useRef(null);
  const [ID, setID] = useState(null);
  const [loadModule, setLoadModule] = useState(false);
  const [type, setType] = useState([]);
  let dataLes = [];
  const [idLF, setidLF] = useState(null);
  const [load, setLoad] = useState(false);
  const [barcolor, setBarcolor] = useState("blue");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [error, setError] = useState([]);
  const [options, setOptions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState({
    titre_lois: "",
    desc_lois: "",
    details_lois: "",
    id_type_lois: "",
    img_lois: null,
  });
  const vide = {
    titre_lois: "",
    desc_lois: "",
    details_lois: "",
    id_type_lois: "",
    img_lois: null,
  };
  const [formValues, setFormValues] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    if (value === "" || value === null) {
      setError({ ...error, [name]: "ce champs est requis !" });
    } else {
      setError({ ...error, [name]: undefined });
    }
    setLesson({ ...lesson, [name]: value });
  };

  const [image, setImage] = useState(null);
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setError({ ...error, img_lois: undefined });
      setImage(URL.createObjectURL(e.target.files[0]));
      setLesson({ ...lesson, img_lois: e.target.files[0] });
    } else if (e.target.files === {}) {
      setError({ ...error, img_lois: "ce champs est réquis !" });
    }
  };
  const onChangefichier = (i, e) => {
    let formfichier = [...formValues];

    if (e.target.files && e.target.files[0]) {
      formfichier[i]["fichiert"] = e.target.files[0];
    }
    setFormValues(formfichier);
  };
  let handleChangeFichier = (i, e) => {
    let formfichier = [...formValues];
    formfichier[i][e.target.name] = e.target.value;
    formfichier[i]["index"] = i;
    setFormValues(formfichier);
    if (e.target.name === "type_ficht") {
      formValues.forEach((element, index) => {
        document.getElementById(`type${index}`).innerHTML = "";
        if (
          element.type_ficht === "" ||
          element.type_ficht === "choisir ici..."
        ) {
          document.getElementById(`type${index}`).innerHTML =
            "<p className='text-danger fs-5'>type fichier requis</p>";
        } else {
          document.getElementById(`type${index}`).innerHTML = "";
        }
      });
    }
  };
  const getType = async () => {
    try {
      await axios
        .get(TypeLois_API)
        .then((response) => {
          const data = response.data.data;
          console.log(data);
         
          setType(data);
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });
    } catch (error) {
      toast.error("un erreur est survenu");
      console.log(error);
    }
  };
  const addInput = (e) => {
    e.preventDefault();
    const nvInput = [
      ...formValues,
      { titre_ficht: "", type_ficht: "", fichiert: null, id_lois: idLF },
    ];
    setFormValues(nvInput);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var text = "";
    setIto(choice);
    choice.forEach((element) => {
      text += element.value + ",";
    });
    console.log(text);
    setLesson({ ...lesson, id_mod: text });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const ajoutLessonFichier = (e) => {
    e.preventDefault();
    var process = 0;
    setLoad(true);
    for (let index = 0; index < formValues.length; index++) {
      const element = formValues[index];
      document.getElementById(`type${index}`).innerHTML = "";
      if (
        element.type_ficht === "" ||
        element.type_ficht === "choisir ici..."
      ) {
        document.getElementById(`type${index}`).innerHTML =
          "<p className='text-danger fs-5'>type fichier requis</p>";
        setLoad(false);
        break;
      } else {
        document.getElementById(`type${index}`).innerHTML = "";
        if (index === formValues.length - 1) {
          formValues.forEach((element) => {
            process++;
            try {
              axios
                .post(FichierLois_API + "?_method=PUT", element, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response);
                  if (process === formValues.length) {
                    toast.success("Fichier ajouter avec success");
                    document.getElementById("fermerModal").click();
                    setLoad(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  const msg = error.response.data;
                  console.log(msg);
                });
              setError([]);
            } catch (error) {
              console.log(error);
              setLoad(false);
            }
          });
        }
      }
    }
  };

  const paginatedInvoices = Pagination.getData(
    lessons,
    currentPage,
    itemsPerPage
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
    console.log(lesson);

    const apiErrors = {};
    if (lesson.titre_lois === "") {
      apiErrors.titre_lois = ["ce champs est réquis"];
      setError(apiErrors);
    } else if (lesson.id_type_lois === "" || lesson.id_type_lois === null) {
      apiErrors.id_type_lois = ["ce champs est réquis"];
      setError(apiErrors);
    } else if (lesson.img_lois === "" || lesson.img_lois === null) {
      apiErrors.img_lois = ["ce champs est réquis"];
      setError(apiErrors);
    } else if (lesson.desc_lois === "") {
      apiErrors.desc_lois = ["ce champs est réquis"];
      setError(apiErrors);
    } else if (lesson.details_lois === "" || lesson.details_lois.length < 1) {
      apiErrors.details_lois = ["ce champs est réquis"];
      setError(apiErrors);
    } else {
      setLoad(true);
      try {
        await axios
          .post(TexteLois_API, lesson, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success("insertion reussi");
            getAllLesson();
            let id_lois = response.data.data.id_lois;
            setidLF(id_lois);

            setLesson(vide);
            setLoad(false);

            console.log(response);
            console.log(id_lois);
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.titre_lois = msg.titre_lois;
              apiErrors.desc_lois = msg.desc_lois;
              apiErrors.img_lois = msg.img_lois;
              apiErrors.details_lois = msg.details_lois;
              apiErrors.id_type_lois = msg.id_type_lois;
            }

            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };
  const GetModuleByoneLesson = async (module) => {
    const GETIO = [];
    try {
      var i = 0;
      module.forEach((element) => {
        i++;
        GETIO.push({
          value: element.modules.id_mod,
          label: element.modules.titre_mod,
        });
        if (i === module.length) {
          setIto(GETIO);
          change(GETIO);
        }
      });
    } catch (error) {}
  };

  const getAllLesson = async () => {
    ref.current.continuousStart();
    setBarcolor("blue");

    try {
      await axios
        .get(TexteLois_API)
        .then((response) => {
          console.log(response);
          setLessons(response.data.data);
          ref.current.complete();
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getAllModule = async () => {
    setBarcolor("blue");
    const getModules = [];
    try {
      await axios
        .get(MODULE_API)
        .then((response) => {
          console.log(response);
          const data = response.data.data;

          data.forEach((element) => {
            getModules.push({
              value: element.id_mod,
              label: element.titre_mod,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
      setOptions(getModules);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(TexteLois_API + "/" + ID + "?_method=PUT", lesson, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          document.getElementById("fermermodif").click();
          toast.success("modification reussi");
          getAllLesson();
          setLoad(false);
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est sruvenu");
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.titre_lois = msg.titre_lois;
            apiErrors.desc_lois = msg.desc_lois;
            apiErrors.img_lois = msg.img_lois;
            apiErrors.details_lois = msg.details_lois;
            apiErrors.id_type_lois = msg.id_type_lois;
          }

          console.log(error);
        });
      setError(apiErrors);
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  const supprimer = async () => {
    setLoad(true);
    try {
      await axios
        .delete(TexteLois_API + "/" + ID)
        .then((response) => {
          toast.success("element " + ID + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getAllLesson();
        })
        .catch((error) => {
          console.log(error);
          toast.error("element pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  const fetchUpdate = (les) => {
    document.getElementById("reset_form").click();
    setID(les.id_lois);
    setIto([]);

    const updt = {};
    setImage(file_url + les.img_lois);
    lesson.desc_lois = les.desc_lois;
    lesson.details_lois = les.details_lois;
    lesson.titre_lois = les.titre_lois;
    lesson.id_type_lois = les.id_type_lois;
  };

  const initialiser = () => {
    setIto([]);
    setLesson(vide);
    setFormValues([]);
    getType();
    setImage(null);
    setError(vide);
    setidLF(null);
    console.log("click");
    document.getElementById("reset_form").click();
  };

  // calls whenever state will be change , another approach
  useEffect(() => {
    getAllLesson();
    getType();
  }, []);
  return (
    <>
      <LoadingBar ref={ref} color={barcolor} />
      <div className="container-lg container-fluid py-5">
        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
              <li class="nav-item">
                <a
                  href="#tabs-home-ex1"
                  class="nav-link active"
                  data-bs-toggle="tab"
                >
                  Liste texte en vigueur
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#tabs-profile-ex1"
                  class="nav-link"
                  data-bs-toggle="tab"
                >
                  Type de texte en vigueur
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active show" id="tabs-home-ex1">
                <div
                  className="modal modal-blur"
                  id="modal-danger"
                  tabIndex="-1"
                  aria-modal="true"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-sm modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="modal-status bg-danger"></div>
                      <div className="modal-body text-center py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon mb-2 text-danger icon-lg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 9v2m0 4v.01"></path>
                          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                        </svg>
                        <h3>vous etes sur ?</h3>
                        <div className="text-muted">
                          vous-voulez vraiment supprimer cette texte en vigueur?
                          c'est irreversible.
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="w-100">
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                id="fermerdel"
                                className="btn w-100"
                                data-bs-dismiss="modal"
                              >
                                Annuler
                              </button>
                            </div>
                            <div className="col">
                              <button
                                type="button"
                                onClick={supprimer}
                                className={
                                  "btn btn-danger w-100 " + (load && "disabled")
                                }
                              >
                                Supprimer texte en vigueur
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal modal-blur"
                  tabIndex="-1"
                  id="exampleModal"
                >
                  <div
                    className="modal-dialog modal-lg"
                    style={{ maxWidth: "900px" }}
                    role="document"
                  >
                    <form
                      className="my-3"
                      onSubmit={
                        idLF == null ? handleSubmit : ajoutLessonFichier
                      }
                    >
                      <div className="modal-content">
                        <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                          <h2 className="p-2">Formulaire texte en vigueur</h2>

                          <button
                            className="btn-close p-0"
                            type="button"
                            data-bs-dismiss="modal"
                            id="fermerModal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <button
                          type="reset"
                          className="p-0 m-0"
                          style={{ visibility: "hidden" }}
                          id="reset_form"
                        ></button>
                        {idLF == null ? (
                          <div
                            className="modal-body py-0"
                            style={{ overflowY: "auto" }}
                          >
                            <div className="my-3">
                              <label className="form-label">Titre</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.titre_lois && "is-invalid")
                                }
                                type="text"
                                name="titre_lois"
                                value={lesson.titre_lois}
                                onChange={handleChange}
                                placeholder="Saisir titre "
                              />
                              {error.titre_lois && (
                                <p className="invalid-feedback">
                                  {error.titre_lois}
                                </p>
                              )}
                            </div>
                            <div className="my-3">
                              <label className="form-label">Type</label>
                              <select
                                className={
                                  "form-control " +
                                  (error.id_type_lois && "is-invalid")
                                }
                                type="text"
                                name="id_type_lois"
                                value={lesson.id_type_lois}
                                onChange={handleChange}
                                placeholder="Saisir titre "
                                required
                              >
                                <option>choisir ici...</option>
                                {type.map((element, index) => (
                                  <option
                                    key={index}
                                    value={element.id_type_lois}
                                  >
                                    {element.desc_type_lois}
                                  </option>
                                ))}
                              </select>
                              {error.id_type_lois && (
                                <p className="invalid-feedback">
                                  {error.id_type_lois}
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <label className="form-label">
                                Image de representation du texte en vigueur
                              </label>
                              <div className="row">
                                <div className="col-lg-6 col-12 mb-2">
                                  <input
                                    type="file"
                                    className={
                                      "form-control " +
                                      (error.img_lois && "is-invalid")
                                    }
                                    id="img_lois"
                                    onChange={onImageChange}
                                    placeholder="choisir un image"
                                    accept="image/png, image/gif, image/jpeg"
                                    multiple
                                  />
                                  {error.img_lois && (
                                    <p className="invalid-feedback">
                                      {error.img_lois}
                                    </p>
                                  )}
                                </div>

                                {image && (
                                  <span
                                    className="avatar avatar-sm"
                                    style={{ backgroundImage: `url(${image})` }}
                                  ></span>
                                )}
                              </div>
                            </div>
                            <div className="my-3">
                              <label className="form-label">Description </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.desc_lois && "is-invalid")
                                }
                                type="text"
                                name="desc_lois"
                                value={lesson.desc_lois}
                                onChange={handleChange}
                                placeholder="Saisir description "
                              />
                              {error.desc_lois && (
                                <p className="invalid-feedback">
                                  {error.desc_lois}
                                </p>
                              )}
                            </div>
                            <div className="my-3">
                              <label className="form-label">
                                Details de son contenu{" "}
                              </label>
                              <CKEditor
                                config={{
                                  image: {
                                    toolbar: [
                                      "imageStyle:block",
                                      "imageStyle:side",
                                      "|",
                                      "toggleImageCaption",
                                      "imageTextAlternative",
                                    ],
                                  },
                                }}
                                editor={Editor}
                                data=""
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  console.log("ato");
                                  // console.log( { event, editor, data } );
                                  console.log(data);
                                  if (data === "") {
                                    setError({
                                      ...error,
                                      details_lois: "le details est réquis !",
                                    });
                                  } else {
                                    setLesson({
                                      ...lesson,
                                      details_lois: data,
                                    });
                                    setError({ ...error, details_lois: null });
                                  }
                                }}
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                              />
                              {error.details_lois && (
                                <p className="text-danger">
                                  {error.details_lois}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="modal-body py-0"
                            style={{ height: "550px", overflowY: "auto" }}
                          >
                            <p className="lead my-2">
                              S'il y a des fichier qui doivent etre enregistrés
                              avec le texte en vigueur veuillez les ajouter ici,
                              sinon cliquer sur le bouton{" "}
                              <span className="fw-bold text-primary">
                                Terminer
                              </span>
                            </p>
                            <div className="mt-1">
                              <div className="row my-3">
                                <div className="col d-flex align-items-center gap-3">
                                  <label className="form-label">
                                    Fichier rattaché
                                  </label>{" "}
                                  <button
                                    className="btn btn-primary"
                                    onClick={addInput}
                                  >
                                    <FontAwesomeIcon
                                      icon={faAdd}
                                      className="pe-2"
                                    />
                                    Ajouter un champs
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                {formValues.map((element, index) => (
                                  <div
                                    className="col-6 d-flex gap-2 my-2"
                                    key={index}
                                  >
                                    <div className="card p-3 w-100">
                                      <div className="mt-1">
                                        <label className="form-label">
                                          Fichier du texte en vigueur{" "}
                                        </label>
                                        <input
                                          type="file"
                                          name="fichiert"
                                          id={`fichier_les${index}`}
                                          className="form-control"
                                          onChange={(e) =>
                                            onChangefichier(index, e)
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="mt-3">
                                        <label className="form-label">
                                          Titre du fichier
                                        </label>
                                        <input
                                          value={element.titre_ficht}
                                          className="form-control"
                                          name="titre_ficht"
                                          onChange={(e) =>
                                            handleChangeFichier(index, e)
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="my-3">
                                        <label className="form-label">
                                          Type du fichier
                                        </label>
                                        <select
                                          className="form-control"
                                          value={element.type_ficht}
                                          name="type_ficht"
                                          id="type_ficht"
                                          onChange={(e) =>
                                            handleChangeFichier(index, e)
                                          }
                                          required
                                        >
                                          <option>choisir ici...</option>
                                          <option value="Photo">Photo</option>
                                          <option value="Audio">Audio</option>
                                          <option value="Document">
                                            Document
                                          </option>
                                          <option value="Autre">Autre</option>
                                        </select>
                                        <div
                                          style={{ color: "red" }}
                                          id={`type${index}`}
                                        ></div>
                                      </div>
                                      {index ? (
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            removeFormFields(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="modal-footer">
                          <div className="w-100">
                            <div className="row justify-content-between">
                              <div
                                className={"col d-flex justify-content-start"}
                              >
                                {idLF == null ? (
                                  <button
                                    type="button"
                                    className="btn"
                                    data-bs-dismiss="modal"
                                  >
                                    <FontAwesomeIcon
                                      icon={faCancel}
                                      className="me-2"
                                    />{" "}
                                    Annuler
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn"
                                    data-bs-dismiss="modal"
                                  >
                                    <FontAwesomeIcon
                                      icon={faCancel}
                                      className="me-2"
                                    />
                                    Terminer
                                  </button>
                                )}
                              </div>
                              <div className="col d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary " + (load && "disabled")
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    className="me-2"
                                  />{" "}
                                  {idLF == null ? <>Suivant</> : <>Ajouter</>}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal modal-blur" tabIndex="-1" id="modif">
                  <div
                    className="modal-dialog modal-lg"
                    style={{ maxWidth: "900px" }}
                    role="document"
                  >
                    <form className="my-3" onSubmit={handleUpdate}>
                      <div className="modal-content">
                        <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                          <h2 className="p-2">Formulaire texte en vigueur</h2>
                          <button
                            className="btn-close p-0"
                            type="button"
                            data-bs-dismiss="modal"
                            id="fermermodif"
                            aria-label="Close"
                          ></button>
                        </div>
                        <button
                          type="reset"
                          className="p-0 m-0"
                          style={{ visibility: "hidden" }}
                          id="reset_form"
                        ></button>
                        <div
                          className="modal-body py-0"
                          style={{ overflowY: "auto" }}
                        >
                          <div className="my-3">
                            <label className="form-label">Titre</label>
                            <input
                              className={
                                "form-control " +
                                (error.titre_lois && "is-invalid")
                              }
                              type="text"
                              name="titre_lois"
                              value={lesson.titre_lois}
                              onChange={handleChange}
                              placeholder="Saisir titre "
                            />
                            {error.titre_lois && (
                              <p className="invalid-feedback">
                                {error.titre_lois}
                              </p>
                            )}
                          </div>
                          <div className="my-3">
                            <label className="form-label">Type</label>
                            <select
                              className={
                                "form-control " +
                                (error.id_type_lois && "is-invalid")
                              }
                              type="text"
                              name="id_type_lois"
                              value={lesson.id_type_lois}
                              onChange={handleChange}
                              placeholder="Saisir titre "
                              required
                            >
                              <option>choisir ici...</option>
                              {type.map((element, index) => (
                                <option
                                  key={index}
                                  value={element.id_type_lois}
                                >
                                  {element.desc_type_lois}
                                </option>
                              ))}
                            </select>
                            {error.id_type_lois && (
                              <p className="invalid-feedback">
                                {error.id_type_lois}
                              </p>
                            )}
                          </div>
                          <div className="mt-3">
                            <label className="form-label">
                              Image de representation du texte en vigueur
                            </label>
                            <div className="row">
                              <div className="col-lg-6 col-12 mb-2">
                                <input
                                  type="file"
                                  className={
                                    "form-control " +
                                    (error.img_lois && "is-invalid")
                                  }
                                  id="img_lois"
                                  onChange={onImageChange}
                                  placeholder="choisir un image"
                                  accept="image/png, image/gif, image/jpeg"
                                  multiple
                                />
                                {error.img_lois && (
                                  <p className="invalid-feedback">
                                    {error.img_lois}
                                  </p>
                                )}
                              </div>

                              {image && (
                                <span
                                  className="avatar avatar-sm"
                                  style={{ backgroundImage: `url(${image})` }}
                                ></span>
                              )}
                            </div>
                          </div>
                          <div className="my-3">
                            <label className="form-label">Description </label>
                            <input
                              className={
                                "form-control " +
                                (error.desc_lois && "is-invalid")
                              }
                              type="text"
                              name="desc_lois"
                              value={lesson.desc_lois}
                              onChange={handleChange}
                              placeholder="Saisir description "
                            />
                            {error.desc_lois && (
                              <p className="invalid-feedback">
                                {error.desc_lois}
                              </p>
                            )}
                          </div>
                          <div className="my-3">
                            <label className="form-label">
                              Details de son contenu{" "}
                            </label>
                            <CKEditor
                              editor={Editor}
                              data={lesson.details_lois}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                             
                                if (data === "") {
                                  setError({
                                    ...error,
                                    details_lois: "le details est réquis !",
                                  });
                                } else {
                                  setLesson({ ...lesson, details_lois: data });
                                  setError({ ...error, details_lois: null });
                                }
                              }}
                              // onBlur={ ( event, editor ) => {
                              //     console.log( 'Blur.', editor );
                              // } }
                              // onFocus={ ( event, editor ) => {
                              //     console.log( 'Focus.', editor );
                              // } }
                            />
                            {error.details_lois && (
                              <p className="text-danger">
                                {error.details_lois}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="modal-footer">
                          <div className="w-100">
                            <div className="row justify-content-between">
                              <div
                                className={"col d-flex justify-content-start"}
                              >
                                <button
                                  type="button"
                                  className="btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <FontAwesomeIcon
                                    icon={faCancel}
                                    className="me-2"
                                  />{" "}
                                  Annuler
                                </button>
                              </div>
                              <div className="col d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary " + (load && "disabled")
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    className="me-2"
                                  />
                                  Modifier
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <h2>texte en vigueur</h2>
                    <p className="">Liste des texte en vigueur</p>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => initialiser()}
                      className="btn btn-primary px-4 py-2"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouveau texte en vigueur
                    </button>
                  </div>
                </div>
                <div className="overflow-auto">
                  <table className="table table-hover table-bordered table-striped table-light ">
                    <thead>
                      <tr className="">
                        <th>ID</th>
                        <th>Titre</th>
                        <th>id Type</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Fichier</th>
                        <th className="col-1">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedInvoices.map((element, index) => (
                        <tr key={index}>
                          <td>{element.id_lois}</td>
                          <td>{element.titre_lois}</td>
                          <th>{element.id_type_lois}</th>
                          <td>{element.desc_lois}</td>
                          <td>
                            {" "}
                            <span
                              className="avatar avatar-sm"
                              style={{
                                backgroundImage: `url(${file_url}/${element.img_lois})`,
                              }}
                            ></span>
                          </td>

                          <td className="text-center">
                            <Link
                              to={`/admin/fichier_texte/${element.id_lois}`}
                              className="nav-link "
                            >
                              <span className="badge bg-info py-2 px-2">
                                <FontAwesomeIcon
                                  icon={faFile}
                                  className="me-2"
                                />
                                Gérer
                              </span>
                            </Link>
                          </td>
                          <td className="d-flex gap-2 justify-content-center">
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#modif"
                              onClick={() => fetchUpdate(element)}
                              className="btn btn-primary"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              type="button"
                              onClick={() => setID(element.id_lois)}
                              data-bs-toggle="modal"
                              data-bs-target="#modal-danger"
                              className="btn btn-danger"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <Pagination
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      onPageChanged={handlePageChange}
                      length={lessons.length}
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tabs-profile-ex1">
                <GererTypeLois/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
