import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function NavStat() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  return (
    <div className="nav">
      <div
        className={
          location.pathname === "/organisme/statistique de suivi"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/organisme/statistique de suivi")}
          className="nav-link"
        >
          accueil
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/organisme/statgen"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/organisme/statgen")}
          className="nav-link"
        >
          générales
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/organisme/statmodule"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/organisme/statmodule")}
        >
          modules
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/organisme/statautoecole"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/organisme/statautoecole")}
        >
          auto-école
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/organisme/statcandidat"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/organisme/statcandidat")}
        >
          Candidats
        </div>
      </div>
    </div>
  );
}
