import {
  faBell,
  faBookOpenReader,
  faCommentSms,
  faHistory,
  faHome,
  faLayerGroup,
  faMessage,
  faRightToBracket,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../header/style.css";
import avatar from "../../assets/image/avatar.jpg";
import HeaderAdmin from "../Admin/HeaderAdmin";
import isconnected from "../../service/isconnected";
import axios from "axios";
import image from "../../assets/image/imgtest/image";
import { Contact_API, LOGOUT } from "../../service/config";
import { toast } from "react-toastify";
import socket from "../../service/Socket_Connection";

export default function Head(props) {
  const [info, setInfo] = useState([]);
  const [notif, setnotif] = useState("");
  const navigate = useNavigate();
  const [nombrmes, setnombrmes] = useState(0);
  const logout = async () => {
    const dec = toast.loading("Déconnexion !");
    try {
      await axios
        .post(LOGOUT)
        .then((response) => {
          delete axios.defaults.headers["Authorization"];
          navigate("/login");

          toast.update(dec, {
            render: "Session déconnecter!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
          window.localStorage.removeItem("authToken");
          props.setactif(false);
        })
        .catch((error) => {
          console.log(error);
          toast.update(dec, {
            render: "Echec de déconnexion !",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        });
    } catch (error) {
      toast.update(dec, {
        render: "Echec de déconnexion !",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const initialize = () => {
    setInfo(isconnected.user());
  };

  useEffect(() => {
    initialize();
  }, []);

  socket.on("message prive", (data) => {
    setnotif(data.message);
    console.log(data);
  });
  const getList = async () => {
    try {
      await axios
        .get(Contact_API + "?order=desc&limit=10")
        .then((reponse) => {
          console.log(reponse);
          let i = 0;
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            if (element.is_repondre === false) {
              i++;
            }
          }
          setnombrmes(i);
          setTimeout(() => {
            getList();
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const Notifcomponent = () => {
    return (
      <span
        className="avatar avatar-sm  rounded-circle"
        style={{ height: "15px", width: "15px" }}
      >
        {nombrmes}
      </span>
    );
  };

  useEffect(() => {
    Notifcomponent()
  }, [nombrmes]);

  useEffect(() => {
    getList();
  }, []);
  return (
    <div className="mb-5">
      <div className="m-0 p-0 fixed-top" style={{ zIndex: "88" }}>
        <nav
          id="nav-home"
          className="navbar navbar-expand-md bg-light  border border-bottom "
        >
          <div className="container-lg container-fluid">
            <Link to="/" className="navbar-brand me-5" href="/#">
              <img
                src={image.mtm}
                alt=""
                width="35"
                height="auto"
                className="d-inline-block align-text-top"
              />{" "}
            </Link>

            <div className="navbar-nav ">
              {!isconnected.Authenticated() ? (
                <div className="d-flex flex-row">
                  <Link
                    to="/inscription"
                    className="btn btn-outline-primary me-3 rounded-1 py-2 align-self-center"
                    type="submit"
                  >
                    Inscription
                  </Link>

                  <Link
                    to="/login"
                    className="text-white btn btn-primary m-0 rounded-1 py-2 align-self-center"
                    type="submit"
                  >
                    Connexion
                  </Link>
                </div>
              ) : (
                <div className="d-flex">
                  <div
                    onClick={() => {
                      setnotif(false);
                      navigate("/admin/mes notifications");
                    }}
                    className="navbar-brand me-2"
                  >
                    <FontAwesomeIcon icon={faBell} color="#9DA9B9" />{" "}
                    <Notifcomponent/>
                  </div>

                  <div className="dropdown p-2">
                    <a
                      href="/#"
                      className="nav-link d-flex lh-1 text-reset p-0"
                      data-bs-toggle="dropdown"
                      aria-label="Open user menu"
                      aria-expanded="false"
                    >
                      <span
                        className="avatar avatar-sm"
                        style={{ backgroundImage: "url(" + avatar + ")" }}
                      ></span>
                      <div className="ps-2 d-lg-block d-none">
                        <div> {info.nom_util}</div>
                        <div className="mt-1 small text-muted">
                          {info.type_util}
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animate slideIn">
                      <Link className="dropdown-item mt-2" to="/admin/profile">
                        <FontAwesomeIcon
                          icon={faUserGear}
                          color="#9DA9B9"
                          className="pe-2"
                        />
                        Mon compte
                      </Link>
                      <Link className="dropdown-item " to="/admin/historique">
                        <FontAwesomeIcon
                          icon={faHistory}
                          color="#9DA9B9"
                          className="pe-2"
                        />
                        Historiques
                      </Link>
                      <Link to="/" className="dropdown-item" type="submit">
                        <FontAwesomeIcon
                          icon={faHome}
                          color="blue"
                          className="pe-2"
                        />
                        Passer en tant qu'apprenant
                      </Link>
                      <div
                        onClick={() => logout()}
                        className="dropdown-item"
                        type="submit"
                      >
                        <FontAwesomeIcon
                          icon={faRightToBracket}
                          color="red"
                          className="pe-2"
                        />
                        Deconnecter
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
        {isconnected.Authenticated() && <HeaderAdmin />}
      </div>
    </div>
  );
}
