import {
    faBookOpenReader,
    faBoxArchive,
    faCarSide,
    faChartPie,
    faCheckCircle,
    faCommentAlt,
    faEdit,
    faEraser,
    faFemale,
    faGraduationCap,
    faHouse,
    faListNumeric,
    faMale,
    faRectangleXmark,
    faSquare,
    faSquareCaretUp,
    faSquarePlus,
    faTrashAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useState } from "react";
  import { Button, Modal } from "react-bootstrap";
  import { Doughnut } from "react-chartjs-2";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import { Link } from "react-router-dom";
  import { Rating } from "react-simple-star-rating";
  import image from "../../../../assets/image/imgtest/image";
  import DonutChart from "./DonutChart";
  import NavStat from "./NavStat";
  
  export default function StatAutoecole() {
    const columns = [
      // {
      //   name: "ID",
      //   selector: (row) => <>{row.id}</>,
      //   sortable: true,
      //   width: "7%",
      // },
      {
        name: "Nom",
        selector: (row) => row.nom,
        sortable: true,
       
      },
      {
        name: "Adresse",
        selector: (row) => row.adresse,
        sortable: true,
       
      },
      {
        name: "Région",
        selector: (row) => row.region,
        sortable: true,
      
      },
      {
        name: "CP",
        selector: (row) => row.cp,
        sortable: true,
       
      },
      {
        name: "Taux réussite",
        selector: (row) => row.tr,
        sortable: true,
      },
      {
        name: "Nombre de Candidats",
        selector: (row) => row.nb,
        sortable: true,
      }
    ];
  
    const data = [
        {
            "id": 1,
            "nom": "Auto Ecole A",
            "adresse": "Rue de l'Indépendance, Antananarivo",
            "region": "Analamanga",
            "cp": "101",
            "tr": "85%",
            "nb": 200
        },
        {
            "id": 2,
            "nom": "Auto Ecole B",
            "adresse": "Rue de l'Université, Antsirabe",
            "region": "Vakinankaratra",
            "cp": "110",
            "tr": "70%",
            "nb": 150
        },
        {
            "id": 3,
            "nom": "Auto Ecole C",
            "adresse": "Avenue de l'Indépendance, Toamasina",
            "region": "Atsinanana",
            "cp": "501",
            "tr": "80%",
            "nb": 180
        },
        {
            "id": 4,
            "nom": "Auto Ecole D",
            "adresse": "Rue Colbert, Mahajanga",
            "region": "Boeny",
            "cp": "401",
            "tr": "75%",
            "nb": 170
        },
        {
            "id": 5,
            "nom": "Auto Ecole E",
            "adresse": "Rue de la Liberté, Toliara",
            "region": "Atsimo-Andrefana",
            "cp": "601",
            "tr": "90%",
            "nb": 210
        }
    ];
    const [show, setshow] = useState(false);
    const handle = () => setshow(!show);
    return (
      <div className="container-lg container-fluid py-4">
        <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-nowrap justify-content-between w-100 align-items-center">
            <div>
              <h2>
                <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
                suivi{" "}
              </h2>
              <h4>Statistiques Auto-écoles</h4>
            </div>
            <div>
              <NavStat />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h2>Filtres</h2>
            <div className="row">
              <div className="col-lg-2 col-12">
                <div className="my-2">
                  <label className="form-label">Années</label>
                  <input
                    type={"date"}
                    className="form-control w-100"
                    name="annéé"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="my-2">
                  <label className="form-label">Auto-écoles</label>
                  <select className="form-control w-100" name="autoecole">
                    <option value="">Freddy 1</option>
                    <option value="">Dolly 2</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-12 align-self-end">
                <div className="my-2 d-flex">
                  <button className="btn btn-cyan w-100 mx-1">Valider</button>
                  <div className="vr w-1 bg-secondary h-100"></div>
                  <button className="btn btn-success w-100 mx-1">Exporter</button>
                </div>
              </div>
            </div>
            <div className="row my-3 align-items-center">
              <div className="col-lg-6 col-12">
                <div
                  className="w-100 d-flex justify-content-center"
                  style={{ maxHeight: "280px",width:'100%' }}
                >
                  <DonutChart taille={3} label={["Freddy", "Dolly"]} />
                </div>
              </div>
              <div className="col-lg-6 col-12 h-100">
                <div className="row">
                  <div className="col-12 p-1 h-100">
                    <div className="card py-3">
                      <div className="card-body">
                        <div className="row text-center">
                          <div className="col-lg-6 col-12">
                            <div className="">
                              <h3>Taux de reussite auto-école</h3>
                              <div className="display-6 fw-bold">78%</div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div>
                              <h3>Total des auto-écoles</h3>
                              <div className="display-6 fw-bold">105</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-2">
          <DataTable
            columns={columns}
            data={data}
            contextActions={
              <button className="btn btn-cyan me-2">
                <FontAwesomeIcon icon={faCheckCircle} className="pe-2" /> Valider
              </button>
            }
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={{
              singular: "élement",
              plural: "élements",
              message: "sélectionnées",
            }}
            pagination
          />
        </div>
      </div>
    );
  }
  