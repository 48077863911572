import { faHourglass3, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import image from "../../../assets/image/imgtest/image";
import { Pourcentage_API } from "../../../service/config";
import { Link } from "react-router-dom";
import { BlockUI } from "primereact/blockui";

export default function Evolution() {
  const [evoluti, setEvoluti] = useState({});
  const [load, setload] = useState(true);
  const [moyenne, setmoyenne] = useState(0);
  function toHoursAndMinutes(totalSeconds) {
    const duration = moment.duration(parseInt(totalSeconds), "seconds");

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return (
      <>
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </>
    );
  }
  const getpour = () => {
    setload(true);
    axios
      .get(Pourcentage_API)
      .then((rep) => {
        console.log(rep);
        setEvoluti(rep.data);
        setload(false);
        let moy = 0;
        for (let i = 0; i < rep.data.exercice?.length; i++) {
          const element = rep.data.exercice[i];
          moy += Number(element.resultat_curs);
        }
        setmoyenne(moy / rep.data.exercice?.length);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getpour();
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <div className="p-0 m-0">
        <div>
          <h2>Mon évolution</h2>
        </div>
        <div style={{ position: "relative" }}>
          {load && (
            <FontAwesomeIcon
              style={{
                position: "absolute",
                zIndex: "49999999",
                top: "45%",
                left: "45%",
                transform: "translate(-50%, -50%)",
              }}
              size="4x"
              color="white"
              spinPulse
              className="ms-2"
              icon={faSpinner}
            />
          )}
          <BlockUI blocked={load}>
            <div className=" rounded-2">
              <div className="row g-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <img src={image.avatar} />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-12 ">
                  <div className="row g-2">
                    <div className="col-12 col-lg-4 col-md-6 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.totalModuleUser}
                          </div>
                          <p>Modules parcourus</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.resteModuleUser}
                          </div>
                          <p>Modules non parcourus</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.totalModule}
                          </div>
                          <p>Total Module</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 my-2">
                    <div className="col-lg-7 col-md-7 col-12 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body">
                          <p>Note et resultats hypothétique</p>
                          <div className="display-6 fw-bold text-center">
                            {evoluti.resultatHyp}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12 d-flex align-items-stretch">
                      <div className="card w-100 ">
                        <div className="card-body d-flex align-items-center justify-content-center">
                          <div className="display-5 fw-bold text-center">
                            {evoluti.meanResult
                              ? parseFloat(evoluti.meanResult).toFixed(2)
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card rounded-3 my-3 p-3 py-3 mt-3">
              <h3>Modules choisis</h3>
              <div className="d-flex flex-wrap flex-row gap-2 p-2">
                {evoluti.moduleChoisi?.map((element, index) => (
                  <Link to={"/details/" + element.id_mod}>
                    <span
                      key={index}
                      className="badge bg-primary p-2 text-wrap"
                    >
                      {element.titre_mod}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
            <div className="card rounded-3 my-3 p-3 py-3 mt-3">
              <h3>Modules non choisis</h3>
              <div className="d-flex flex-wrap flex-row gap-2 p-2">
                {evoluti.modulesNonChoisis?.length == 0
                  ? "Aucun"
                  : evoluti.modulesNonChoisis?.map((element, index) => (
                      <Link to={"/details/" + element.id_mod}>
                        <span
                          key={index}
                          className="badge bg-primary p-2 text-wrap"
                        >
                          {element.titre_mod}
                        </span>
                      </Link>
                    ))}
              </div>
            </div>

            <div className="card rounded-3 my-3 p-3 py-3 mt-3">
              <h3>Leçons parcourus</h3>
              <div className="p-3">
                <div className="list-group list-group-flush list-group-hoverable">
                  {evoluti.lessons?.map((element, index) => (
                    <Link
                      to={`/lessons/${element.id_mod}`}
                      state={{ rech: "oui", lessonrech: element.id_les }}
                      className="text-decoration-none"
                    >
                      <div key={index} className="list-group-item p-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                          <p className="w-75">{element.titre_les}</p>
                          <span className="badge bg-secondary border-secondary">
                            <FontAwesomeIcon
                              className="pe-1"
                              icon={faHourglass3}
                            />
                            {toHoursAndMinutes(element.temps_pour)}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="card rounded-3 my-3 p-3 py-3 mt-3">
              <h3>Exercices parcourus</h3>
              <div className="p-3">
                <div className="list-group list-group-flush list-group-hoverable">
                  {evoluti.exercice?.map((element, index) => (
                    <div key={index} className="list-group-item p-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="w-75">{element.titre_mod}</p>
                        <div className="w-25 d-flex flex-wrap  flex-row align-items-center justify-content-between">
                          <p className="fs-5">{element.resultat_curs}/{element?.total_curs ? element?.total_curs:10 }</p>
                          <span className="badge bg-secondary border-secondary">
                            <FontAwesomeIcon
                              className="pe-1"
                              icon={faHourglass3}
                            />
                            {toHoursAndMinutes(element.temps)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </BlockUI>
        </div>
      </div>
    </div>
  );
}
