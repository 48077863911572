import { faEye, faTrash, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function Supprimer(props) {
  const [show, setshow] = useState(false);
  const handleShow = () => setshow(!show);
  const [data, setdata] = useState({});
  useEffect(() => {
    setdata(props.data);
  }, []);
  return (
    <div>
      <div>
        <Button variant="danger" onClick={handleShow}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
      <Modal show={show} size="sm" onHide={handleShow}>
        <Modal.Header>
        <div className="text-center h3">Suppression de compte</div>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center">
Vous-voulez vraiment supprimer cette compte ? c'est sera irréversible !
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-end">
            <Button variant="danger">Supprimer</Button>
            <Button variant="secondary" onClick={()=>{handleShow();props.deselected(true)}}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
