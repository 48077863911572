import { faMailBulk, faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ResendEmail_API } from "../../service/config";
import isconnected from "../../service/isconnected";
import RetourComp from "../../outils/RetourComp";

export default function AccountDeactivated() {
  const location = useLocation();
  const email = isconnected.user().email;
  const [load, setload] = useState(false);
  console.log(email);
  const resend = async () => {
    setload(true);
    try {
      await axios
        .post(ResendEmail_API + email)
        .then((response) => {
          toast.success("E-mail envoyé !");
          setload(false);
          console.log(response)
        })
        .catch((error) => {
          toast.error("erreur interne, veuillez réessayer plus tard !");
          setload(false);
        });
    } catch (error) {
      console.log(error);
      setload(false);
      toast.error("verifier votre connexion et réessayer !");
    }
  };
  return (
    <div className="h-100 py-2">
      <div className="row align-items-center justify-content-center h-100 my-4">
        <div className="col-10">
          <div className="card rounded-4">
            <div className="card-header flex justify-content-center">
              <h1 className="me-auto">
                <RetourComp url={"/"} />
              </h1>
            </div>
            <FontAwesomeIcon
              className="mx-auto mt-2"
              icon={faSadTear}
              size="4x"
            />
            <div className="card-body">
              <p className="text-center display-5">Compte pas encore activé</p>
            </div>
            <div className="my-2 text-center">
              <h3>
                Il est impossible pour vous d'accéder a cette fonctionnalité !
              </h3>
              <div className="d-flex my-3 flex-row flex-wrap align-items-center justify-content-center gap-1">
                <button
                  className={"btn btn-success fw-bold " + (load && "disabled")}
                  type="button"
                  onClick={() => resend()}
                >
                  Activer mon compte en m'envoyant un email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
