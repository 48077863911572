import {
  faTimes,
  faTimesCircle,
  faTimesSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  dixquestion_API,
  file_url,
  list4moduleHateaosByLangue,
  modulequestionrep,
  Questionnaire_API,
} from "../../service/config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function Aléatoire() {
  const [exercice, setExercice] = useState([]);
  const [faux, setfaux] = useState([]);
  const [step, setstep] = useState(0);
  const [module, setmodule] = useState([]);
  const [load, setLoad] = useState(true);
  const [exenotfound, setexenotfound] = useState(true);
  const [Fin, setFin] = useState(0);
  const [notfound, setnotfound] = useState(false);
  const [terminer, setterminer] = useState(false);
  const [show, setshow] = useState(false);
  let { id } = useParams();
  const addhtml = (el, htmlcontent) => {
    const io = document.getElementById(el);
    console.log(el);
    console.log(htmlcontent);

    if (htmlcontent == null || io === null) {
    } else {
      io.innerHTML =
        htmlcontent === null || htmlcontent === undefined ? "" : htmlcontent;
    }
  };
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours >= 1 && minutes >= 1 && seconds >= 1) {
      return (
        <>
          {hours < 10 ? <>0{hours}</> : { hours }}:
          {minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours >= 1 && minutes == 0) {
      return (
        <>
          {hours}:00:{seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (minutes >= 1 && hours == 0) {
      return (
        <>
          00:{minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours == 0 && minutes == 0) {
      return <>00:00:{seconds < 10 ? <>0{seconds}</> : seconds}</>;
    }
  }
  const getModules = async () => {
    setLoad(true);
    try {
      await axios
        .get(dixquestion_API)
        .then((response) => {
          console.log(response);
          const data = response.data.data;
          setmodule(data);
          if (data.length === 0) {
          } else if (data.length > 0) {
            setexenotfound(false);
          }
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [questionnaire, setquestionnaire] = useState([]);
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(0);
  const [run, setrun] = useState(true);
  function TimeExo(modul) {
    let ti = 0;
    for (let i = 0; i < modul?.length; i++) {
      const element = modul[i];
      ti += element.dure_ques;
    }

    return ti;
  }
  useEffect(() => {
    let intervalId = 0;
    if (show) {
      if (terminer === false) {
        intervalId = setInterval(() => {
          setTime((time) => time + 1);
        }, 1000);
        return () => clearInterval(intervalId);
      } else {
        setTime(time);
      }
    }
  }, [show, terminer]);
  const reset = () => {
    module.map((element) => {
      element.reponses.map((rep) => {
        rep.selected = "false";
      });
      element.fichierq.map((rep) => {
        rep.selected = "false";
      });
    });
  };
  let handleChange = (e, reponse, question, indexques, indexrep) => {
    if (question.fichierq.length > 0) {
      const valiny = e.target.id;
      let moyen = 0;
      let rps = [];

      for (let i = 0; i < question.fichierq.length; i++) {
        const element = question.fichierq[i];
        if (element.type_repq == true) {
          moyen++;
          rps.push(element);
        }
      }
      console.log(moyen);
      console.log(rps);
      const iscorrect = rps.every((ans) =>
        ans.desc_repq.includes(e.target.value)
      );
      if (e.target.checked === true) {
        if (iscorrect || reponse.type_repq === true) {
          module[indexques].fichierq[indexrep].selected = "true";
          if (module[indexques].note === undefined) {
            module[indexques].note = 1 / moyen;
          } else {
            module[indexques].note += 1 / moyen;
          }
        } else {
          module[indexques].fichierq[indexrep].selected = "true";
          if (
            module[indexques].note === undefined ||
            module[indexques].note === 0
          ) {
            module[indexques].note = 0;
          } else {
            module[indexques].note -= 1 / moyen;
          }
        }
      } else {
        if (iscorrect || reponse.type_repq === true) {
          module[indexques].fichierq[indexrep].selected = "false";
          if (
            module[indexques].note === undefined ||
            module[indexques].note === 0
          ) {
            module[indexques].note = 0;
          } else {
            module[indexques].note -= 1 / moyen;
          }
        } else {
          module[indexques].fichierq[indexrep].selected = "false";
          if (module[indexques].note === undefined) {
            module[indexques].note = 1 / moyen;
          } else {
            module[indexques].note += 1 / moyen;
          }
        }
      }
    } else if (question.reponses.length > 0) {
      const valiny = e.target.id;
      let moyen = 0;
      let rps = [];

      for (let i = 0; i < question.reponses.length; i++) {
        const element = question.reponses[i];
        if (element.type_rep == true) {
          moyen++;
          rps.push(element);
        }
      }
      console.log(moyen);
      console.log(rps);
      const iscorrect = rps.every((ans) =>
        ans.desc_rep.includes(e.target.value)
      );
      if (e.target.checked === true) {
        if (iscorrect || reponse.type_rep === true) {
          module[indexques].reponses[indexrep].selected = "true";
          if (module[indexques].note === undefined) {
            module[indexques].note = 1 / moyen;
          } else {
            module[indexques].note += 1 / moyen;
          }
        } else {
          if (
            module[indexques].note === undefined ||
            module[indexques].note === 0
          ) {
            module[indexques].note = 0;
          } else {
            module[indexques].note -= 1 / moyen;
          }
          module[indexques].reponses[indexrep].selected = "true";
        }
      } else {
        if (iscorrect || reponse.type_rep === true) {
          if (
            module[indexques].note === undefined ||
            module[indexques].note === 0
          ) {
            module[indexques].note = 0;
          } else {
            module[indexques].note -= 1 / moyen;
          }
          module[indexques].reponses[indexrep].selected = "false";
        } else {
          module[indexques].reponses[indexrep].selected = "false";
          if (module[indexques].note === undefined) {
            module[indexques].note = 1 / moyen;
          } else {
            module[indexques].note += 1 / moyen;
          }
        }
      }
    } else if (question.val_ques) {
      let val = e.target.value;
      if (val === question.val_ques) {
        module[indexques].monreponse = val;
        module[indexques].note = 1;
      } else {
        module[indexques].monreponse = val;
        module[indexques].note = 0;
      }
    }
  };
  const editorConfig = {
    toolbar: "",
  };
  const Cursus = async () => {
    let notefinal = 0;
    const loadrep = toast.loading(
      `Veillez patientez,Traitement de votre note en cours...`
    );
    console.log(module);
    module.map((element) => {
      if (element.note !== undefined) {
        notefinal += element.note;
      }
    });


    setTimeout(() => {
      setScore(notefinal);
      console.log(score);
      try {
        toast.update(loadrep, {
          render: "Traitement terminé,voici votre résultat !",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        window.scrollTo(0, 0);
        setterminer(true);
      } catch (err) {
        console.log(err);
        setTimeout(() => {
          toast.update(loadrep, {
            render: "Echec de traitement!",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }, 3000);
      }
    }, 3000);
  };

  useEffect(() => {
    console.log(module);
  }, [module]);

  useEffect(() => {
    console.log(faux);
  }, [faux]);
  useEffect(() => {
    getModules();
  }, []);
  useEffect(() => {
    if (show) {
      if (time === TimeExo(module)) {
        Cursus();
        setterminer(true);
        toast.info("Fin de votre temps !");
      }
    }
  }, [time]);
  return (
    <div className="container">
      <div className="row justify-content-center my-3">
        <div className="col-lg-8 col-12 my-2 mt-3">
          <div className="card">
            <div className="card-body ">
              <div className="row justify-content-between align-items-center">
                <div className="">
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      {/* <div className={"h2 " + (load && "placeholder py-1 px-4")}>
                        {module.titre_mod}
                      </div> */}

                      {/* <div className="my-2">
                        <span className={"fst-italic border px-2 rounded-3 me-1 "+ (load && "placeholder py-1 px-4")}>
                          {module.lang_mod}
                        </span>
                      </div> */}

                      {/* <p
                        className={
                          "lead pe-3 pb-0 mb-0 my-1 " + (load && "placeholder px-5 py-2")
                        }
                      >
                        <span className="fw-bold">Objectif :</span>{" "}
                        {module?.objectif_mod}
                      </p> */}
                      {load === false ? (
                        <h1>
                          <svg
                            style={{ width: "15px" }}
                            className={"me-1 "}
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="QueryBuilderIcon"
                          >
                            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                            <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
                          </svg>
                          {toHoursAndMinutes(time)}/
                          {toHoursAndMinutes(TimeExo(module))}
                        </h1>
                      ) : (
                        <div className="placeholder px-5 py-3"></div>
                      )}
                    </div>
                    {terminer && (
                      <h1>Note:{Math.round(score * 100) / 100} /10</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     {  <div className="col-lg-8 col-12 my-2 mt-3">
          <div className="card">
            <div className="card-body ">
              <div className="row justify-content-between align-items-center">
                <div className="">
                  <h1>Exercice</h1>
                 {!show &&  <ul>
                      <li>
                        {" "}
                        <p>
                          Les exercices suivants comportent des Questions à
                          choix multiples et/ou des études de cas. A vous de
                          trouver les bonnes réponses.
                        </p>
                      </li>
                      <li>
                        <p>
                          A chaque réponse correspond une note. Entre chaque
                          exercice, cliquez sur suivant ou terminer pour obtenir
                          le total de vos notes et voir les réponses correctes.
                        </p>
                      </li>
                      <li>
                      Entre chaque exercice, cliquez sur suivant ou terminer pour obtenir le total de vos notes et voir les réponses correctes.
                      </li>
                    </ul>}
                </div>
                {/* <div className="col-lg-2 col-12">
              <p>
                Module: <strong>module 1</strong>
              </p>
              <p>
                Durée: <strong>30 minutes</strong>
              </p>
            </div> */}
              </div>
            </div>
          </div>
        </div>}
        {show ? (
          <div className="col-lg-8 col-12 p-1">
            {load ? (
              <div className="w-100 display-5 text-center">
                chargement des questions...
              </div>
            ) : (
              <div className="m-0 p-0">
                <form onSubmit={handleChange}>
                  {module.map((element, index) =>  index === step &&  (
                    <div key={index} className="mb-3 card py-3 px-4">
                       <div className="non-edit">
                            <CKEditor
                              className="custom-ckeditor"
                              editor={Editor}
                              config={editorConfig}
                              data={element.qcm_ques}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                                editor.enableReadOnlyMode("editor");
                                const toolbar = editor.ui.view.toolbar.element;
                                toolbar.style.display = "none";
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                              }}
                              // onBlur={ ( event, editor ) => {
                              //     console.log( 'Blur.', editor );
                              // } }
                              // onFocus={ ( event, editor ) => {
                              //     console.log( 'Focus.', editor );
                              // } }
                            />
                          </div>

                      {element.reponses.length > 0 ? (
                        <div className="">
                          {element.reponses.map((el, indi) => (
                            <div key={indi} className="form-check">
                              {terminer ? (
                                <input
                                  type="checkbox"
                                  className={
                                    "form-check-input " +
                                    (el.type_rep === true && terminer === true
                                      ? "is-valid"
                                      : el.selected === "true" &&
                                        terminer === true
                                      ? "is-invalid"
                                      : "")
                                  }
                                  id={`${el.id_rep}`}
                                  name={`${element.id_ques}`}
                                  value={el.desc_rep}
                                  checked={
                                    el.selected === "true" ? true : false
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={
                                    "form-check-input " +
                                    (el.type_rep === true && terminer === true
                                      ? "is-valid"
                                      : el.selected === "true" &&
                                        terminer === true
                                      ? "is-invalid"
                                      : "")
                                  }
                                  id={`${el.id_rep}`}
                                  name={`${element.id_ques}`}
                                  value={el.desc_rep}
                                  onChange={(e) =>
                                    handleChange(e, el, element, index, indi)
                                  }
                                  checked={
                                    el.selected === "true" ? true : false
                                  }
                                />
                              )}

                              <label
                                className="form-check-label"
                                htmlFor={el.id_rep}
                              >
                                {el.desc_rep}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : terminer && element.val_ques  ? (
                        <>
                          <input
                            type={"number"}
                            className={
                              "form-control " +
                              (element.val_ques == element.monreponse
                                ? "is-valid"
                                : "is-invalid")
                            }
                            value={element.monreponse}
                            readOnly
                            placeholder="votre réponse"
                            style={{ width: "200px" }}
                          />

                          <p className="invalid-feedback">
                            la vrai reponse c'est : {element.val_ques}
                          </p>
                        </>
                      ) : !terminer && element.val_ques  ? (
                        <input
                          type={"number"}
                          className="form-control"
                          onChange={(e) =>
                            handleChange(e, element, element, index, index)
                          }
                          value={element.monreponse ? element.monreponse : ""}
                          placeholder="Saisir ici votre réponse"
                          style={{ width: "200px" }}
                        />
                      ):null}
                      {element.fichierq.length > 0 && (
                        <div className="row w-100">
                          {element.fichierq.map((el, indi) => (
                            <div key={indi} className="form-check col-lg-4">
                              {terminer ? (
                                <input
                                  type="checkbox"
                                  className={
                                    "form-check-input " +
                                    (el.type_repq === true && terminer === true
                                      ? "is-valid"
                                      : el.selected === "true" &&
                                        terminer === true
                                      ? "is-invalid"
                                      : "")
                                  }
                                  id={`${el.id_fichq}`}
                                  name={`${element.id_ques}`}
                                  value={el.desc_repq}
                                  checked={
                                    el.selected === "true" ? true : false
                                  }
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={
                                    "form-check-input " +
                                    (el.type_repq === true && terminer === true
                                      ? "is-valid"
                                      : el.selected === "true" &&
                                        terminer === true
                                      ? "is-invalid"
                                      : "")
                                  }
                                  id={`${el.id_fichq}`}
                                  name={`${element.id_ques}`}
                                  value={el.desc_repq}
                                  onChange={(e) =>
                                    handleChange(e, el, element, index, indi)
                                  }
                                  checked={
                                    el.selected === "true" ? true : false
                                  }
                                />
                              )}
                              <label
                                className="form-check-label d-flex flex-column"
                                htmlFor={el.id_fichq}
                              >
                                <img
                                  style={{ width: "100%" }}
                                  height={150}
                                  src={file_url + el.fichierq}
                                  alt="question"
                                />
                                {el.desc_repq}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="d-flex flex-row gap-2 justify-content-between mt-5">
                    <button
                      type="button"
                      onClick={() => {
                        reset();
                        setterminer(false);
                        setshow(false);
                        setTime(0);
                        setScore(0);
                      }}
                      className="px-3 btn rounded-1 btn-secondary"
                    >
                      {terminer ? <>Réessayer</> : <>Abandonner</>}
                    </button>
                    <div className="d-flex gap-2">
                      {module?.length - 1 >= step && step > 0 ? (
                        <button
                          className="px-3 btn rounded-1 btn-secondary"
                          type="button"
                          onClick={() => setstep(step - 1)}
                        >
                          précedent
                        </button>
                      ) : (
                        <button
                          className="px-3 btn rounded-1 btn-secondary disabled"
                          type="button"
                        >
                          {" "}
                          précedent
                        </button>
                      )}
                      {module?.length - 1 > step ? (
                        <button
                          className="px-3 btn rounded-1 btn-cyan"
                          type="button"
                          onClick={() => setstep(step + 1)}
                        >
                          suivant
                        </button>
                      ) : (
                        <button
                          className="px-3 btn rounded-1 btn-cyan disabled"
                          type="button"
                        >
                          suivant
                        </button>
                      )}
                      <button
                        type="button"
                        className={
                          "px-3 btn rounded-1 btn-primary " +
                          (terminer && "disabled")
                        }
                        onClick={Cursus}
                      >
                        Terminer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        ) : (
          load === false && (
            <div className="col-lg-8 col-12 p-3 d-flex justify-content-center">
              {exenotfound ? (
                <p className="display-6">Aucun exercices disponible !</p>
              ) : (
                <button
                  className={"btn btn-primary btn-lg px-5 "}
                  onClick={() => setshow(!show)}
                >
                  Commencer
                </button>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}
