import { combineReducers } from "redux";
import {getMots, ChangerLangue, ExoModule, Getexercice, Getlesson, actualite, array1Reducer, array2Reducer, categ, recommandation, sensibilisation } from "./arrayReducers";

const rootReducer = combineReducers({
    array1: array1Reducer,
    array2: array2Reducer,
    actu: actualite,
    sensi:sensibilisation,
    categ: categ,
    suggestion:recommandation,
    exomod:ExoModule,
    lg:ChangerLangue,
    lesson:Getlesson,
    exercice:Getexercice,
    motmin:getMots
});

export default rootReducer;