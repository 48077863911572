import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function useIntersectionObserverWithProps(url,stateRedux,commande,apidata,props) {
  const sectionRef = useRef(null);
  const dataRedux = useSelector(state => state[stateRedux])
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [props]);

  
  

  const handleIntersect = (entries, observer) => {
   setLoad(true) 
    if (entries[0].isIntersecting) {
      
      axios.get(url)
        .then((response) => {
          // Traitez les données ici
          console.log(response.data);
          let donnee = response.data[apidata]
          setData(donnee)
          dispatch({type:commande, payload: donnee})
          // Arrêtez d'observer la section une fois que les données ont été récupérées.
          setLoad(false)
          observer.unobserve(sectionRef.current);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }
  };

  return {sectionRef,dataRedux,load};
}

export default useIntersectionObserverWithProps;
