
import axios from 'axios';
import { paramNotes } from '../config';

// Fonction pour récupérer des données depuis la base de données
export async function TotalQuestion() {
  try {
    const response = await axios.get(paramNotes); // Remplacez par l'URL de votre API
    return response.data.data;
  } catch (error) {
    throw error;
  }
}
