import React from "react";
import "../section/section1.css";
import imagesec from "../../../assets/image/cover.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBook,
  faFileContract,
  faFilePrescription,
  faFileSignature,
  faHand,
  faList,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import image from "../../../assets/image/imgtest/image";
import ScrollToTop from "react-scroll-to-top";
import MotMinistre from "./MotMinistre";
import { HashLink } from "react-router-hash-link";
import PermisGasy from "./main.png";
export default function Section1() {
  return (
    <div className="section1" id="home">
      <ScrollToTop smooth />
      {/* <div className="container">
        <div className="row align-items-center py-5 flex-md-wrap-reverse g-1 flex-wrap-reverse">
          <div className="col-lg-6 col-md-7 text-center text-md-start text-lg-start d-flex flex-column justify-content-center">
            <div className="pt-lg-0 pt-md-0 pt-sm-4 pt-4">
              <p className="display-6 display-5-lg">Apprendre à conduire</p>
              <p className="fs-lg-3 fs-3 fw-light"> Mettre la chance de son coté pour réussir son permis de conduire.</p>

            </div>
            
             
            
          </div>
          <div className="col-lg-6 col-md-5 h-md-2">
            <div className="h-50 d-flex align-items-center justify-content-center">
              <img src={imagesec}  className="img-fluid cover-droite w-50 w-lg-100" />
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
        style={{ minHeight: "485px", Height: "760px", maxHeight: "760px" }}
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item ">
            <div className="container col-xxl-8 col-md-8 px-lg-1 px-4 py-2">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-12 col-sm-8 col-lg-6">
                  <img
                    src={image.io}
                    style={{ maxWidth: "330px" }}
                    className="d-block mx-lg-auto img-fluid text-center rounded-circle"
                    alt="Bootstrap Themes"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6">
                  <h1 className="display-6 fw-bold lh-1 mb-3">
                    Module de formation pour la conduite de véhicules à moteur
                  </h1>
                  <p className="lead mt-2 lh-2">
                    Améliorez votre sécurité routière grâce à notre plateforme
                    d'apprentissage en ligne, qui vous offre des cours complets
                    sur les règles de la route et les bonnes pratiques de
                    conduite.
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start pt-4">
                    <Link to="/modules" className="nav-link">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg px-4 me-md-2 rounded-4"
                      >
                        Commencer
                        <FontAwesomeIcon icon={faPlayCircle} className="ms-2"/>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-caption d-none d-md-block">
              {/* <h5>Third slide label</h5>
              <p>Some representative placeholder content for the third slide.</p>
              <Link to="/modules" className="nav-link justify-content-center mt-3">
                <button type="submit" className="btn btn-primary px-4 py-2 rounded-1"><span className="pe-3 py-1">Commencer </span></button>
              </Link> */}
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <div className="container col-xxl-8 col-md-8 px-lg-1 px-4 py-2">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-12 col-sm-8 col-lg-6">
                  <img
                    src={PermisGasy}
                    className="d-block mx-lg-auto img-fluid text-center rounded-5"
                    alt="Bootstrap Themes"
                    style={{ maxWidth: "330px" }}
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6">
                  <h1 className="display-6 fw-bold lh-1 mb-3">
                    Leçons de Conduite
                  </h1>
                  <p className="lead mt-2 lh-2">
                    Préparez-vous efficacement pour l'examen du code de la route
                    grâce à notre plateforme d'apprentissage, qui vous offre des
                    tests d'entraînement et des cours en ligne actualisés
                    régulièrement.
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start pt-4">
                    <Link to="/modules" className="nav-link">
                    <button
                        type="button"
                        className="btn btn-primary btn-lg px-4 me-md-2 rounded-4"
                      >
                        Voir les Formations
                        <FontAwesomeIcon icon={faBook} className="ms-2"/>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-caption d-none d-md-block">
              {/* <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
              <Link to="/modules" className="nav-link justify-content-center mt-3">
                <button type="submit" className="btn btn-primary px-4 py-2 rounded-1"><span className="pe-3 py-1">Commencer </span></button>
              </Link> */}
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <div className="container col-xxl-8 col-md-8 px-lg-1 px-4 py-2">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-12 col-sm-8 col-lg-6">
                  <img
                    src={image.svgExercice}
                    className="d-block mx-lg-auto img-fluid text-center rounded-circle"
                    style={{
                      maxWidth: "330px",
                      height: "300px",
                      width: "auto",
                    }}
                    alt="Bootstrap Themes"
                    width="500"
                    height="200"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6">
                  <h1 className="display-6 fw-bold ">
                    Exercices et simulations
                  </h1>
                  <p className="lead mt-2 lh-2">
                    Notre plateforme d'apprentissage en ligne vous permet de
                    maîtriser les règles de la route en quelques heures
                    seulement, grâce à des cours interactifs et des exercices
                    pratiques.
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start pt-4">
                  <HashLink to="/#module" class="nav-link">
                  <button
                        type="button"
                        className="btn btn-primary btn-lg px-4 me-md-2 rounded-4"
                      >
                        Tester mes compétences
                        <FontAwesomeIcon icon={faPlayCircle} className="ms-2"/>
                      </button>
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-caption d-none d-md-block">
              {/* <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
              <Link to="/modules" className="nav-link justify-content-center mt-3">
                <button type="submit" className="btn btn-primary px-4 py-2 rounded-1"><span className="pe-3 py-1">Commencer </span></button>
              </Link> */}
            </div>
          </div>
          <div className="carousel-item active" data-bs-interval="2000">
            <MotMinistre />
            <div className="carousel-caption d-none d-md-block">
              {/* <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
              <Link to="/modules" className="nav-link justify-content-center mt-3">
                <button type="submit" className="btn btn-primary px-4 py-2 rounded-1"><span className="pe-3 py-1">Commencer </span></button>
              </Link> */}
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
