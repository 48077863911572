import {
  faAdd,
  faCancel,
  faEdit,
  faEye,
  faFile,
  faSchool,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../outils/Pagination";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  FichierLesson_API,
  file_url,
  LESSON_API,
  ListLessonHateaos_API,
  moduleByLesson,
  MODULE_API,
} from "../../../service/config";
import axios from "axios";
import { toast } from "react-toastify";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import TableBorderColor from "../../../outils/PluginsCk";
import { Button, Modal } from "react-bootstrap";
import { BlockUI } from "primereact/blockui";

const animatedComponents = makeAnimated();
const Fichier = [
  { value: "Video", label: "Video" },
  { value: "Photo", label: "Photo" },
  { value: "Document", label: "Document" },
];
export default function AjoutModifLesson({id,options,recharger}) {
  const abordController = new AbortController()
  const location = useLocation();

  const [show, setshow] = useState(false);
  const navigate = useNavigate();

  const [loadLesson, setLoadLesson] = useState(false);
  let dataLes = [];
  const [idLF, setidLF] = useState(null);
  const [load, setLoad] = useState(false);
  const [barcolor, setBarcolor] = useState("blue");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [error, setError] = useState([]);

  const [lesson, setLesson] = useState({});
  const vide = {
    titre_les: "",
    desc_les: "",
    "img_les ": null,
    "id_mod ": "",
  };
  const [formValues, setFormValues] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setLesson({ ...lesson, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState(null);
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setLesson({ ...lesson, img_les: e.target.files[0] });
      setError({ ...error, img_les: null });
    }
  };
  const onChangefichier = (i, e) => {
    let formfichier = [...formValues];

    if (e.target.files && e.target.files[0]) {
      formfichier[i]["fichierl"] = e.target.files[0];
    }
    setFormValues(formfichier);
  };
  let handleChangeFichier = (i, e) => {
    let formfichier = [...formValues];
    formfichier[i][e.target.name] = e.target.value;
    formfichier[i]["index"] = i;
    setFormValues(formfichier);
    if (e.target.name === "type_fichl") {
      formValues.forEach((element, index) => {
        document.getElementById(`type${index}`).innerHTML = "";
        if (
          element.type_fichl === "" ||
          element.type_fichl === "choisir ici..."
        ) {
          document.getElementById(`type${index}`).innerHTML =
            "<p className='text-danger fs-5'>type fichier requis</p>";
        } else {
          document.getElementById(`type${index}`).innerHTML = "";
        }
      });
    }
  };

  const addInput = (e) => {
    e.preventDefault();
    const nvInput = [
      ...formValues,
      { titre_fichl: "", type_fichl: "", fichierl: null, id_les: idLF },
    ];
    setFormValues(nvInput);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var text = "";
    setIto(choice);
    choice.forEach((element) => {
      text += element.value + ",";
    });
    console.log(text);
    setLesson({ ...lesson, id_mod: text });
    setError({ ...error, id_mod: null });
  };

  const handleSubmit = async () => {
   
   
    console.log(formValues);
    console.log(lesson);
    setLoad(true);
    const apiErrors = {};

    if (!lesson.titre_les) {
      setError({ ...error, titre_les: "ce champs est réquis !" });
      const input = document.getElementsByName("titre_les")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!lesson.id_mod) {
      setError({ ...error, id_mod: "ce champs est réquis !" });
      const input = document.getElementsByName("id_mod")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!lesson.img_les) {
      setError({ ...error, img_les: "ce champs est réquis !" });
      const input = document.getElementsByName("img_les")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!lesson.desc_les) {
      setError({ ...error, desc_les: "ce champs est réquis !" });
      const input = document.getElementsByName("desc_les")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      try {
        await axios
          .post(LESSON_API, lesson, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success("insertion reussi");
            let id_les = response.data.data.id_les;
            setidLF(id_les);
            setLesson(vide);
            recharger()
            setLoad(false);
            console.log(response);
            console.log(id_les);
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
             setError(msg)
            }

            console.log(error);
          });
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };
  

  

  const handleUpdate = async () => {
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(LESSON_API + "/" + id + "?_method=PUT", lesson, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success("modification reussi");
          gererModal()
          recharger()
          setLoad(false);
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est sruvenu");
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
          setError(msg)
          }

          console.log(error);
        });
    
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  const fetchUpdate = () => {
    setLoadLesson(true)
    const updt = [];

  axios.get(LESSON_API +"/"+ id,{
    signal: abordController.signal,
  }).then((rep)=>{
    console.log(rep)
    setLesson(rep.data.lesson)
    rep?.data?.module?.forEach(element => {
      updt.push({value:element.id_mod,label:element.titre_mod})
    });
    setIto(updt)
    setImage(file_url+rep.data.lesson.img_les);
    setLoadLesson(false)
  }).catch((err)=>{
    console.log(err)
    setLoadLesson(false)
  })
  
  };
  const ajoutLessonFichier = () => {

    var process = 0;
    setLoad(true);
    for (let index = 0; index < formValues.length; index++) {
      const element = formValues[index];
      document.getElementById(`type${index}`).innerHTML = "";
      if (
        element.type_fichl === "" ||
        element.type_fichl === "choisir ici..."
      ) {
        document.getElementById(`type${index}`).innerHTML =
          "<p className='text-danger fs-5'>type fichier requis</p>";
        setLoad(false);
        break;
      } else {
        document.getElementById(`type${index}`).innerHTML = "";
        if (index === formValues.length - 1) {
          formValues.forEach((element) => {
            process++;
            try {
              axios
                .post(FichierLesson_API + "?_method=PUT", element, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response);
                  if (process === formValues.length) {
                    toast.success("Fichier ajouter avec success");
                    gererModal()
                    setLoad(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  const msg = error.response.data;
                  console.log(msg);
                });
              setError([]);
            } catch (error) {
              console.log(error);
              setLoad(false);
            }
          });
        }
      }
    }
  };
  const gererModal = () => setshow(!show);

  // calls whenever state will be change , another approach

  return (
    <div className="">
      <Button variant="primary" onClick={() => gererModal()}>
        {id ? <FontAwesomeIcon icon={faEdit}/> : <span><FontAwesomeIcon icon={faAdd} className="me-2"/>Nouvelle léçon</span>}
      </Button>
      <Modal
        scrollable
        show={show}
        enforceFocus={false}
        focu
        size="xl"
        backdrop={"static"}
        onHide={()=>{
          gererModal();
          setidLF(null)
        }}
        onShow={() => {
         if (id) {
           fetchUpdate();
         }
      
        }}
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <h2>Formulaire Leçon</h2>
          <span> {id ? <>Modifier</> : <>Ajouter</>} une leçon</span>
        </Modal.Header>
        <Modal.Body>
          <BlockUI blocked={loadLesson}>
            {id  ? (
              <form className="" onSubmit={handleUpdate}>
                <div className="">
                  <div className="my-3">
                    <label className="form-label">Titre</label>
                    <input
                      className={
                        "form-control " + (error.titre_les && "is-invalid")
                      }
                      type="text"
                      name="titre_les"
                      value={lesson.titre_les}
                      onChange={handleChange}
                      placeholder="Saisir titre du module"
                    />
                    {error.titre_les && (
                      <p className="invalid-feedback">{error.titre_les}</p>
                    )}
                  </div>
                  <div className="my-3 row">
                    <div className="col">
                      <label className="form-label">Modules</label>
                      <Select
                        closeMenuOnSelect
                        components={animatedComponents}
                        defaultValue={options !=undefined  ? [options[4], options[5]] : []}
                        isMulti
                        name="id_mod"
                        placeholder="choix module"
                        options={options !=undefined ? options : []}
                        value={ito}
                        onChange={(choice) => change(choice)}
                        noOptionsMessage={() => "plus d'options"}
                        
                      />
                    </div>
                    {error.id_mod && (
                      <p className="text-danger">{error.id_mod}</p>
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="form-label">
                      Image de representation du leçon
                    </label>
                    <div className="row">
                      <div className="col-lg-6 col-12 mb-2">
                        <input
                          type="file"
                          className={
                            "form-control " + (error.img_les && "is-invalid")
                          }
                          id="img_les"
                          name="img_les"
                          onChange={onImageChange}
                          placeholder="choisir un image"
                          accept="image/png, image/gif, image/jpeg"
                          multiple
                        />
                        {error.img_les && (
                          <p className="invalid-feedback">{error.img_les}</p>
                        )}
                      </div>

                      {image && (
                        <span
                          className="avatar avatar-xl"
                          style={{ backgroundImage: `url(${image})` }}
                        ></span>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Description du leçon</label>
                    <textarea
                      rows={5}
                      className={
                        "form-control " + (error.desc_les && "is-invalid")
                      }
                      name="desc_les"
                      value={lesson.desc_les}
                      onChange={handleChange}
                      placeholder="saisir description"
                    ></textarea>
                    {error.desc_les && (
                      <p className="invalid-feedback">{error.desc_les}</p>
                    )}
                  </div>
                  {/* <div className="my-3">
                    <label className="form-label">Contenu</label>
                    <CKEditor
                      
                      editor={Editor}
                      data={lesson.contenu_les ? lesson.contenu_les : ""}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                        // editor.enableReadOnlyMode("editor")
                        // const toolbar = editor.ui.view.toolbar.element;
                        // toolbar.style.display ='none'
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log("ato");
                        // console.log( { event, editor, data } );
                        console.log(data);
                        if (data === "") {
                          setError({
                            ...error,
                            contenu_les: "le contenu est réquis !",
                          });
                        } else {
                          setLesson({ ...lesson, contenu_les: data });
                          setError({ ...error, contenu_les: null });
                        }
                      }}
                    />
                    <p id="contenu_les"></p>
                    {error.contenu_les && (
                      <p name="contenu_les" className="text-danger">
                        {error.contenu_les}
                      </p>
                    )}
                  </div> */}
                </div>
              </form>
            ) : (
              <form
                className=""
                onSubmit={idLF == null ? handleSubmit : ajoutLessonFichier}
              >
                <div className="modal-content">
                  <button
                    type="reset"
                    className="p-0 m-0"
                    style={{ visibility: "hidden" }}
                    id="reset_form"
                  ></button>
                  {idLF == null ? (
                    <div className="modal-body py-0">
                      <div className="my-3">
                        <label className="form-label">Titre</label>
                        <input
                          className={
                            "form-control " + (error.titre_les && "is-invalid")
                          }
                          type="text"
                          name="titre_les"
                          value={lesson.titre_les}
                          onChange={handleChange}
                          placeholder="Saisir titre leçon"
                        />
                        {error.titre_les && (
                          <p className="invalid-feedback">{error.titre_les}</p>
                        )}
                      </div>
                      <div className="my-3 row">
                        <div className="col">
                          <label className="form-label">Modules</label>
                          <Select
                            closeMenuOnSelect
                            components={animatedComponents}
                            defaultValue={options ? [options[4], options[5]] : []}
                            isMulti
                            name="id_mod"
                            placeholder="choix module"
                            options={options ? options : []}
                            value={ito}
                            onChange={(choice) => change(choice)}
                            noOptionsMessage={() => "plus d'options"}
                          />
                          {error.id_mod && (
                            <p className="text-danger">{error.id_mod}</p>
                          )}
                        </div>
                      </div>
                      <div className="mt-3">
                        <label className="form-label">
                          Image de representation du leçon
                        </label>
                        <div className="row">
                          <div className="col-lg-6 col-12 mb-2">
                            <input
                              type="file"
                              className={
                                "form-control " +
                                (error.img_les && "is-invalid")
                              }
                              id="img_les"
                              name="img_les"
                              onChange={onImageChange}
                              placeholder="choisir un image"
                              accept="image/png, image/gif, image/jpeg"
                              multiple
                            />
                            {error.img_les && (
                              <p className="invalid-feedback">
                                {error.img_les}
                              </p>
                            )}
                          </div>

                          {image && (
                            <span
                              className="avatar avatar-xl"
                              style={{ backgroundImage: `url(${image})` }}
                            ></span>
                          )}
                        </div>
                      </div>
                      <div className="my-3">
                        <label className="form-label">
                          Description du leçon
                        </label>
                        <textarea
                          rows={5}
                          className={
                            "form-control " + (error.desc_les && "is-invalid")
                          }
                          name="desc_les"
                          value={lesson.desc_les}
                          onChange={handleChange}
                          placeholder="saisir description"
                        ></textarea>
                        {error.desc_les && (
                          <p className="invalid-feedback">{error.desc_les}</p>
                        )}
                      </div>
                      {/* <div className="my-3 dansles">
                        <label className="form-label">Contenu</label>
                        <CKEditor
                          editor={Editor}
                          data={
                            lesson.contenu_les == null
                              ? lesson.contenu_les
                              : "<></>"
                          }
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                            // editor.enableReadOnlyMode("editor")
                            // const toolbar = editor.ui.view.toolbar.element;
                            // toolbar.style.display ='none'
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log("ato");
                            // console.log( { event, editor, data } );
                            console.log(data);
                            if (data === "") {
                              setError({
                                ...error,
                                contenu_les: "le contenu est réquis !",
                              });
                            } else {
                              setLesson({ ...lesson, contenu_les: data });
                              setError({ ...error, contenu_les: null });
                            }
                          }}
                        />
                        <p id="contenu_les"></p>
                        {error.contenu_les && (
                          <p name="contenu_les" className="text-danger">
                            {error.contenu_les}
                          </p>
                        )}
                      </div> */}
                    </div>
                  ) : (
                    <div
                      className="modal-body py-0"
                      style={{ height: "550px", overflowY: "auto" }}
                    >
                      <p className="lead my-2">
                        S'il y a des fichier qui doivent etre enregistrés avec
                        le leçon veuillez les ajouter ici, sinon cliquer sur le
                        bouton{" "}
                        <span className="fw-bold text-primary">Terminer</span>
                      </p>
                      <div className="mt-1">
                        <div className="row my-3">
                          <div className="col d-flex align-items-center gap-3">
                            <label className="form-label">
                              Fichier rattaché
                            </label>{" "}
                            <button
                              className="btn btn-primary"
                              onClick={addInput}
                            >
                              <FontAwesomeIcon icon={faAdd} className="pe-2" />
                              Ajouter un champs
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          {formValues.map((element, index) => (
                            <div
                              className="col-6 d-flex gap-2 my-2"
                              key={index}
                            >
                              <div className="card p-3 w-100">
                                <div className="mt-1">
                                  <label className="form-label">
                                    Fichier du leçon{" "}
                                  </label>
                                  <input
                                    type="file"
                                    name="fichierl"
                                    id={`fichier_les${index}`}
                                    className="form-control"
                                    onChange={(e) => onChangefichier(index, e)}
                                    required
                                  />
                                </div>
                                <div className="mt-3">
                                  <label className="form-label">
                                    Titre du fichier
                                  </label>
                                  <input
                                    value={element.titre_fichl}
                                    className="form-control"
                                    name="titre_fichl"
                                    onChange={(e) =>
                                      handleChangeFichier(index, e)
                                    }
                                    required
                                  />
                                </div>
                                <div className="my-3">
                                  <label className="form-label">
                                    Type du fichier
                                  </label>
                                  <select
                                    className="form-control"
                                    value={element.type_fichl}
                                    name="type_fichl"
                                    id="type_fichl"
                                    onChange={(e) =>
                                      handleChangeFichier(index, e)
                                    }
                                    required
                                  >
                                    <option>choisir ici...</option>
                                    <option value="Photo">Photo</option>
                                    <option value="Video">Video</option>
                                    <option value="Audio">Audio</option>
                                    <option value="Document">Document</option>
                                    <option value="Autre">Autre</option>
                                  </select>
                                  <div
                                    style={{ color: "red" }}
                                    id={`type${index}`}
                                  ></div>
                                </div>
                                {index ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
             
                </div>
              </form>
            )}
          </BlockUI>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button type="button" className="btn" onClick={() => {gererModal();}}>
              <FontAwesomeIcon icon={faCancel} className="me-2" /> Annuler
            </button>
            
        { idLF ? <button   type="button" onClick={()=>ajoutLessonFichier()}
              className={"btn btn-primary " + (load && "disabled")}
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" />
              {id ? "Modifier" : "Enregistrer"}
            </button> :
            <button
              type="button"
              onClick={()=>id ? handleUpdate() : handleSubmit()}
              className={"btn btn-primary " +  (load && "disabled")}
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" />
              {id ? "Modifier" : "Enregistrer"}
            </button>}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
