import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { toast } from "react-toastify";
import contact_img from "../../assets/image/Mail sent-rafiki.svg";
import { Contact_API } from "../../service/config";
import socket from "../../service/Socket_Connection";
export default function Contact() {
  const user = localStorage.getItem("authToken");
  const [load, setload] = useState(false);
  const { email } = jwtDecode(user);
  const [data, setdata] = useState({
    object_cont: "",
    email_cont: email,
    desc_cont: "",
  });
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setdata({ ...data, [name]: value });
  };
  const postMessage = async (e) => {
    e.preventDefault();
    setload(true);
    try {
      await axios
        .post(Contact_API, data)
        .then((response) => {
          socket.emit('envoyer',{to:"SuperAdmin",message:data.desc_cont})
          toast.success("E-mail envoyé !");
          setdata({ ...data, object_cont: "" });
          setdata({ ...data, desc_cont: "" });
          
          setload(false);
          console.log(response);
        })
        .catch((error) => {
          toast.error("Email pas envoyer");
          setload(false);
          if (error.response.data.line === 261) {
            toast.error("Erreur, pas de connexion au réseau");
          }
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setload(false);
    }
 
  };
  

  return (
    <div className="">
      <div className="card pt-4 pb-5">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-6 col-md-12 border rounded-3">
              <div className="w-100 p-4">
                <form onSubmit={postMessage}>
                  <p className="h2 my-3">Contactez-nous</p>
                  <h3>Nous sommes disponible pour vous</h3>
                  <div className="my-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      value={data.object_cont}
                      name="object_cont"
                      placeholder="Sujet"
                      required
                    />
                  </div>
                  <div className="my-3">
                    <textarea
                      type="text"
                      rows="5"
                      onChange={handleChange}
                      value={data.desc_cont}
                      name="desc_cont"
                      className="form-control"
                      placeholder="Description du message"
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-end my-3">
                    <button
                      type="submit"
                      className={"btn btn-primary px-3 " + (load && "disabled")}
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <img
                  alt="permis"
                  src={contact_img}
                  style={{ width: "85%" }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
