import {
  faArrowRightToBracket,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTimesSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cursus_API,
  file_url,
  list4moduleHateaosByLangue,
  modulequestionrep,
  Questionnaire_API,
  questionnaireById,
  randomQuestionnairesbyCategorie,
  randomQuestionnairesbyModule,
} from "../../service/config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import cs from "../../non-editable-ck.css";
import { Bars } from "react-loader-spinner";
import { TotalQuestion } from "../../service/CallApi/ApiCall";
import { BlockUI } from "primereact/blockui";
import RetourComp from "../../outils/RetourComp";

export default function ExoCategorie() {
  const [loadquestion, setloadquestion] = useState(false);
  const [exercice, setExercice] = useState([]);
  const [TimeExo, setTimeExo] = useState(0);
  const [totalExo, settotalExo] = useState(10);
  const detailsCategorie = useLocation().state?.categorie;
  const [allidques, setallidques] = useState([]);
  const [allques, setallques] = useState([]);
  const [step, setstep] = useState(0);
  const [afficher, setafficher] = useState(false);
  const [module, setmodule] = useState({});
  const [exenotfound, setexenotfound] = useState(true);
  const [load, setLoad] = useState(true);
  const [notfound, setnotfound] = useState(false);
  const [terminer, setterminer] = useState(false);
  const [answer, setAnswer] = useState(
    Array(module?.questionnaires?.length).fill("")
  );
  const [show, setshow] = useState(false);
  let { id } = useParams();
  let Fin = 0;
  function addhtml(el, htmlcontent) {
    const io = document.getElementById(el);
    console.log(el);
    console.log(htmlcontent);

    if (htmlcontent == null || io === null) {
    } else {
      io.innerHTML =
        htmlcontent === null || htmlcontent === undefined ? "" : htmlcontent;
    }
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours >= 1 && minutes >= 1 && seconds >= 1) {
      return (
        <>
          {hours < 10 ? <>0{hours}</> : { hours }}:
          {minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours >= 1 && minutes == 0) {
      return (
        <>
          {hours}:00:{seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (minutes >= 1 && hours == 0) {
      return (
        <>
          00:{minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours == 0 && minutes == 0) {
      return <>00:00:{seconds < 10 ? <>0{seconds}</> : seconds}</>;
    }
  }
  const getModules = async () => {
    setLoad(true);
    try {
      await axios
        .get(modulequestionrep)
        .then((response) => {
          console.log(response);
          const data = response.data.data;
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.id_mod == id) {
              setmodule(element);
              setLoad(false);
              if (element.questionnaires.length === 0) {
              } else if (element.questionnaires.length > 0) {
                setexenotfound(false);
              }
            } else {
              setnotfound(true);
            }
          }

          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getquestion = () => {
    setLoad(true);
    setstep(0);
    let idques = [];
    let timeExo = 0;
    axios
      .get(randomQuestionnairesbyCategorie + id + "&limit=10")
      .then((rep) => {
        console.log(rep.data);
        setLoad(false);
        if (rep.data.data.length > 0) {
          rep.data.data.forEach((element) => {
            idques.push(element.id_ques);
            timeExo += element.dure_ques;
          });
          setallidques(idques);
          setTimeExo(timeExo);
          setexenotfound(false);
        } else {
          setexenotfound(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (allidques[0] != undefined) {
      getDetails(allidques[0]);
    }
  }, [allidques]);
  const getDetailsQuest = async (id_ques) => {
    setloadquestion(true);
    if (allques[step + 1] == undefined) {
      try {
        await axios
          .get(questionnaireById + id_ques)
          .then((rep) => {
            console.log(rep.data);

            setallques([...allques, rep.data.data]);
            setstep(step + 1);
            setloadquestion(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setstep(step + 1);
      setloadquestion(false);
    }
  };
  const getDetails = (id_ques) => {
    try {
      axios
        .get(questionnaireById + id_ques)
        .then((rep) => {
          console.log(rep.data);

          setallques([...allques, rep.data.data]);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [questionnaire, setquestionnaire] = useState([]);
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(0);
  const [run, setrun] = useState(true);

  useEffect(() => {
    let intervalId = 0;

    if (show) {
      if (terminer === false) {
        intervalId = setInterval(() => {
          if (!loadquestion) {
            setTime((time) => time + 1);
          }
        }, 1000);
        return () => clearInterval(intervalId);
      } else {
        setTime(time);
      }
    }
  }, [show, terminer,loadquestion]);
  const reset = () => {
    setAnswer([]);
    setafficher(false)
    setallques([]);
    getquestion();
    allques.map((element) => {
      element.reponses.map((rep) => {
        rep.selected = "false";
      });
      element.fichierq.map((rep) => {
        rep.selected = "false";
      });
    });
  };
  const handleio = (e) => {
    const updatedUserAnswers = [...answer];
    updatedUserAnswers[step] = e.target.value;
    setAnswer(updatedUserAnswers);
  };
  const handleChange = (e, reponse, question, indexques, indexrep) => {
    if (question.fichierq.length > 0) {
      const valiny = e.target.id;
      let moyen = 0;
      let rps = [];

      for (let i = 0; i < question.fichierq.length; i++) {
        const element = question.fichierq[i];
        console.log(element);
        if (element.type_repq == true) {
          moyen++;
          rps.push(element);
        }
      }
      console.log(moyen);
      console.log(rps);
      const iscorrect = rps.every((ans) =>
        ans.desc_repq.includes(e.target.value)
      );
      if (e.target.checked === true) {
        if (iscorrect || reponse.type_repq === true) {
          allques[indexques].fichierq[indexrep].selected = "true";
          if (allques[indexques].note === undefined) {
            allques[indexques].note = 1 / moyen;
          } else {
            allques[indexques].note += 1 / moyen;
          }
        } else {
          allques[indexques].fichierq[indexrep].selected = "true";
          if (
            allques[indexques].note === undefined ||
            allques[indexques].note === 0
          ) {
            allques[indexques].note = 0;
          } else {
            allques[indexques].note -= 1 / moyen;
          }
        }
      } else {
        if (iscorrect || reponse.type_repq === true) {
          allques[indexques].fichierq[indexrep].selected = "false";
          if (
            allques[indexques].note === undefined ||
            allques[indexques].note === 0
          ) {
            allques[indexques].note = 0;
          } else {
            allques[indexques].note -= 1 / moyen;
          }
        } else {
          allques[indexques].fichierq[indexrep].selected = "false";
          if (allques[indexques].note === undefined) {
            allques[indexques].note = 1 / moyen;
          } else {
            allques[indexques].note += 1 / moyen;
          }
        }
      }
    } else if (question.reponses.length > 0) {
      const valiny = e.target.id;
      let moyen = 0;
      let rps = [];

      for (let i = 0; i < question.reponses.length; i++) {
        const element = question.reponses[i];
        if (element.type_rep == true) {
          moyen++;
          rps.push(element);
        }
      }
      console.log(moyen);
      console.log(rps);
      const iscorrect = rps.every((ans) =>
        ans.desc_rep.includes(e.target.value)
      );
      if (e.target.checked === true) {
        if (iscorrect || reponse.type_rep === true) {
          allques[indexques].reponses[indexrep].selected = "true";
          if (allques[indexques].note === undefined) {
            allques[indexques].note = 1 / moyen;
          } else {
            allques[indexques].note += 1 / moyen;
          }
        } else {
          if (
            allques[indexques].note === undefined ||
            allques[indexques].note === 0
          ) {
            allques[indexques].note = 0;
          } else {
            allques[indexques].note -= 1 / moyen;
          }
          allques[indexques].reponses[indexrep].selected = "true";
        }
      } else {
        if (iscorrect || reponse.type_rep === true) {
          if (
            allques[indexques].note === undefined ||
            allques[indexques].note === 0
          ) {
            allques[indexques].note = 0;
          } else {
            allques[indexques].note -= 1 / moyen;
          }
          allques[indexques].reponses[indexrep].selected = "false";
        } else {
          allques[indexques].reponses[indexrep].selected = "false";
          if (allques[indexques].note === undefined) {
            allques[indexques].note = 1 / moyen;
          } else {
            allques[indexques].note += 1 / moyen;
          }
        }
      }
    } else if (question.val_ques) {
      let val = e.target.value;
      if (val === question.val_ques) {
        allques[indexques].monreponse = val;
        allques[indexques].note = 1;
      } else {
        allques[indexques].monreponse = val;
        allques[indexques].note = 0;
      }
    }
  };

  const Cursus = async () => {
    setterminer(true);
    setafficher(false)
    let notefinal = 0;
    const loadrep = toast.loading(
      `Veillez patientez,Traitement de votre note en cours...`
    );

    allques.map((element) => {
      if (element.note !== undefined) {
        notefinal += element.note;
      }
    });

    setTimeout(() => {
      console.log(score);
      try {
        axios
          .post(cursus_API, {
            temps: time,
            resultat_curs: notefinal,
            total_curs:totalExo,
            id_mod: allques[0]?.id_mod,
          })
          .then((reponse) => {
            console.log(reponse);

            toast.update(loadrep, {
              render: "Traitement terminé,voici votre résultat !",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            setScore(notefinal);
          
            window.scrollTo(0, 0);
            setterminer(true);
            setafficher(true)
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              toast.update(loadrep, {
                render: error.response.data.message ? error.response.data.message : "Echec de traitement !",
                type: "error",
                isLoading: false,
                autoClose: 3000,
              });
            }, 3000);
          });
      } catch (err) {
        console.log(err);
        setTimeout(() => {
            toast.update(loadrep, {
              render: err.response.data.message ? err.response.data.message : "Echec de traitement !",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
       
        }, 3000);
      }
    }, 3000);
  };
  const editorConfig = {
    toolbar: "",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await TotalQuestion();
        settotalExo(result.total_exercice);
      } catch (error) {
        console.error("Erreur de récupération des données :", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    getquestion();
  }, []);
  useEffect(() => {
    if (show) {
      if (time === TimeExo) {
        Cursus();

        toast.info("Fin de votre temps !");
      }
    }
  }, [time]);
  return (
    <div className="container">
      <div className="row justify-content-center my-3">
        <div className="col-lg-8 col-12 my-2 mt-3 p-0">
          <div className="card">
            <div className="card-body ">
              <div className="row justify-content-between align-items-center">
                <div className="">
                  <div className="">
                    <div className="d-flex justify-content-end">
                      {terminer==true && afficher==true ? 
                        <h1>
                          Note:{Math.round(score * 100) / 100} /{totalExo}
                        </h1>
                      :null}
                    </div>
                    <div className="w-100">
                      <div
                        className={"h1 " + (load && "placeholder py-1 px-4")}
                      >
                       <RetourComp/> Permis {detailsCategorie?.categ}{" "}
                        {detailsCategorie?.equi_categ && (
                          <>({detailsCategorie?.equi_categ})</>
                        )}
                      </div>

                      {/* <div className="my-2">
                          <span
                            className={
                              "fst-italic border px-2 rounded-3 me-1 " +
                              (load && "placeholder py-1 px-4")
                            }
                          >
                            {detailsCategorie?.lang_mod}
                          </span>
                        </div> */}

                      <p
                        className={
                          "lead  pb-0 mb-0 my-1 " +
                          (load && "placeholder px-5 py-2")
                        }
                      >
                        <span className="fw-bold">Déscription :</span>{" "}
                        {detailsCategorie?.desc_categ}
                      </p>
                      {/* <div className="d-flex gap-2 my-2">
                          <p className="fw-bold">Catégories :</p>
                          <div>
                          {detailsCategorie?.categories.map((el,i)=><span key={i} className="mx-1 badge bg-cyan">{el.categ}</span>)}
                          </div>
                        </div> */}
                      {load === false && (
                        <h1>
                          <svg
                            style={{ width: "15px" }}
                            className={"me-1 "}
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="QueryBuilderIcon"
                          >
                            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                            <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
                          </svg>
                          {toHoursAndMinutes(time)}-{toHoursAndMinutes(TimeExo)}
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!show && (
          <div className="col-lg-8 col-12 my-2 mt-2">
            <div className="card">
              <div className="card-body ">
                <div className="row justify-content-between align-items-center">
                  <div className="">
                    <h1>Exercice</h1>

                    <ul>
                      <li>
                        {" "}
                        <p>
                          Les exercices suivants comportent des Questions à
                          choix multiples et/ou des études de cas. A vous de
                          trouver les bonnes réponses.
                        </p>
                      </li>
                      <li>
                        <p>
                          A chaque réponse correspond une note. Entre chaque
                          exercice, cliquez sur suivant ou terminer pour obtenir
                          le total de vos notes et voir les réponses correctes.
                        </p>
                      </li>
                      <li>
                        <p>
                          Entre chaque exercice, cliquez sur suivant ou terminer
                          pour obtenir le total de vos notes et voir les
                          réponses correctes.
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-lg-2 col-12">
                <p>
                  Module: <strong>module 1</strong>
                </p>
                <p>
                  Durée: <strong>30 minutes</strong>
                </p>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {show ? (
          <div className="col-lg-8 col-12 p-1">
            {load ? (
              <div className="w-100 display-5 text-center">
                chargement des questions...
              </div>
            ) : (
              <div className="m-0 p-0">
                <form onSubmit={handleChange} style={{ position: "relative" }}>
                  {loadquestion && (
                    <FontAwesomeIcon
                      style={{
                        position: "absolute",
                        zIndex: "49999999",
                        top: "45%",
                        left: "45%",
                        transform: "translate(-50%, -50%)",
                      }}
                      size="4x"
                      color="white"
                      spinPulse
                      className="ms-2"
                      icon={faSpinner}
                    />
                  )}
                  {allques?.map(
                    (element, index) =>
                      index == step && (
                        <BlockUI blocked={loadquestion}>
                          <div key={index} className="mb-3 card py-3 px-4">
                            <div className="non-edit">
                              <CKEditor
                                className="custom-ckeditor"
                                editor={Editor}
                                config={editorConfig}
                                data={element.qcm_ques}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                  editor.enableReadOnlyMode("editor");
                                  const toolbar =
                                    editor.ui.view.toolbar.element;
                                  toolbar.style.display = "none";
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                }}
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                              />
                            </div>
                            {element.reponses.length > 0 ? (
                              <div className="">
                                {element.reponses.map((el, indi) => (
                                  <div key={indi} className="form-check">
                                    {terminer ? (
                                      <input
                                        type="checkbox"
                                        className={
                                          "form-check-input " +
                                          (el.type_rep === true &&
                                          terminer === true
                                            ? "is-valid"
                                            : el.selected === "true" &&
                                              terminer === true
                                            ? "is-invalid"
                                            : "")
                                        }
                                        id={`${el.id_rep}`}
                                        name={`${element.id_ques}`}
                                        value={el.desc_rep}
                                        checked={
                                          el.selected === "true" ? true : false
                                        }
                                        readOnly={true}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className={
                                          "form-check-input " +
                                          (el.type_rep === true &&
                                          terminer === true
                                            ? "is-valid"
                                            : el.selected === "true" &&
                                              terminer === true
                                            ? "is-invalid"
                                            : "")
                                        }
                                        id={`${el.id_rep}`}
                                        name={`${element.id_ques}`}
                                        value={el.desc_rep}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            el,
                                            element,
                                            index,
                                            indi
                                          )
                                        }
                                        checked={
                                          el.selected === "true" ? true : false
                                        }
                                      />
                                    )}

                                    <label
                                      className="form-check-label"
                                      htmlFor={el.id_rep}
                                    >
                                      {el.desc_rep}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : terminer && element.val_ques ? (
                              <>
                                <input
                                  type={"number"}
                                  className={
                                    "form-control " +
                                    (element.val_ques === element.monreponse
                                      ? "is-valid"
                                      : "is-invalid")
                                  }
                                  value={element.monreponse}
                                  readOnly
                                  placeholder="votre réponse"
                                  style={{ width: "200px" }}
                                />

                                <p className="invalid-feedback">
                                  la vrai reponse c'est : {element.val_ques}
                                </p>
                              </>
                            ) : !terminer && element.val_ques ? (
                              <input
                                type={"number"}
                                className="form-control"
                                onChange={(e) => handleio(e)}
                                onBlur={(e) =>
                                  handleChange(
                                    e,
                                    element,
                                    element,
                                    index,
                                    index
                                  )
                                }
                                placeholder="Saisir ici votre réponse"
                                style={{ width: "200px" }}
                                value={answer[step]}
                              />
                            ) : null}
                            {element.fichierq.length > 0 && (
                              <div className="row g-3 justify-content-center w-100">
                                {element.fichierq.map((el, indi) => (
                                  <div
                                    key={indi}
                                    className="col-lg-4 d-flex align-items-stretch justify-content-center"
                                  >
                                    <div className="rounded-3  mx-auto d-block p-2" style={{position:'relative',width:'90%',overflow:'hidden',border:el.selected === "true" ? 'solid #206bc4 1px' : 'solid gray 1px'}}>
                                    {terminer ? (
                                      <input
                                        type="checkbox"
                                        style={{position:'absolute',left:'10px',top:'8px'}}
                                        className={
                                          "form-check-input " +
                                          (el.type_repq === true &&
                                          terminer === true
                                            ? "is-valid"
                                            : el.selected === "true" &&
                                              terminer === true
                                            ? "is-invalid"
                                            : "")
                                        }
                                        id={`${el.id_fichq}`}
                                        name={`${element.id_ques}`}
                                        value={el.desc_repq}
                                        checked={
                                          el.selected === "true" ? true : false
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        style={{position:'absolute',left:'10px',top:'8px'}}
                                        className={
                                          "form-check-input " +
                                          (el.type_repq === true &&
                                          terminer === true
                                            ? "is-valid"
                                            : el.selected === "true" &&
                                              terminer === true
                                            ? "is-invalid"
                                            : "")
                                        }
                                        id={`${el.id_fichq}`}
                                        name={`${element.id_ques}`}
                                        value={el.desc_repq}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            el,
                                            element,
                                            index,
                                            indi
                                          )
                                        }
                                        checked={
                                          el.selected === "true" ? true : false
                                        }
                                      />
                                    )}
                                     <label
                                      className="form-check-label d-flex flex-column w-100 justify-content-end"
                                      htmlFor={el.id_fichq}
                                    >
                                      <img
                                        style={{ width: "100%",height:'150px',maxHeight:'150px' }}
                                    
                                        src={file_url + el.fichierq}
                                        alt="question"
                                      />
                                     <span className="p-2">{el.desc_repq}</span> 
                                    </label>
                                    </div>
                               
                                   
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </BlockUI>
                      )
                  )}
                  <div className="d-flex flex-row gap-2 justify-content-between mt-5">
                    <button
                      type="button"
                      onClick={() => {
                        reset();
                        setterminer(false);
                        setshow(false);
                        setTime(0);
                        setScore(0);
                        setstep(0);
                      }}
                      className="px-3 btn rounded-1 btn-secondary"
                    >
                      {terminer ? <>Réessayer</> : <>Abandonner</>}
                    </button>
                    <div>
                      {allidques?.length - 1 >= step && step > 0 ? (
                        <button
                          className="px-3  btn btn-white rounded-1 btn-info me-2"
                          type="button"
                          onClick={() => setstep(step - 1)}
                        >
                          précedent
                        </button>
                      ) : (
                        <button
                          className="px-3  btn btn-white rounded-1 btn-info me-2 disabled"
                          type="button"
                        >
                          précedent
                        </button>
                      )}
                      {allidques?.length - 1 > step ? (
                        <button
                          className="px-3 btn rounded-1 btn-cyan me-2"
                          type="button"
                          onClick={() => getDetailsQuest(allidques[step + 1])}
                        >
                          suivant
                          {loadquestion ? (
                            <FontAwesomeIcon
                              spinPulse
                              className="ms-2"
                              icon={faSpinner}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-2"
                              icon={faArrowRightToBracket}
                            />
                          )}
                        </button>
                      ) : (
                        <button
                          className="px-3 btn rounded-1 btn-cyan me-2 disabled"
                          type="button"
                        >
                          suivant
                        </button>
                      )}

                      <button
                        type="button"
                        className={
                          "px-3 btn rounded-1 btn-success " +
                          (terminer && "disabled")
                        }
                        onClick={Cursus}
                      >
                        Terminer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        ) : (
          load === false && (
            <div className="col-lg-8 col-12 p-3 d-flex justify-content-center">
              {exenotfound ? (
                <p className="display-6">
                  Cette module ne contient pas encore d'exercice !
                </p>
              ) : exenotfound == false && allques.length > 0 ? (
                <button
                  className={"btn btn-primary btn-lg px-5 "}
                  onClick={() => setshow(!show)}
                >
                  Commencer
                </button>
              ) : (
                <Bars
                  height="40"
                  width="40"
                  color="#22577A"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}
