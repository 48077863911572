import {
  faAdd,
  faBookBookmark,
  faCancel,
  faCommentAlt,
  faEdit,
  faEye,
  faFileImage,
  faQuestionCircle,
  faSchool,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../outils/Pagination";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import {
  CategorieByModule_API,
  CATEGORIE_API,
  file_url,
  lessonByModulesHateaos_API,
  ModuleCategorie_API,
  MODULE_API,
  AvisModule_API,
  moduleWithRatting,
  moduleWithRattingLessonQuestion,
} from "../../../service/config";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import TimePicker from "react-time-picker";
import ReactInputMask from "react-input-mask";
import { truncate } from "../../../outils/Truncate";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const animatedComponents = makeAnimated();
const langue = [
  { value: "Malagasy", label: "Malagasy" },
  { value: "Français", label: "Français" },
];
export default function AjoutModifierModule({ id, getModules }) {
  var text = "";

  const [showModal, setshowModal] = useState(false);
  const gererModal = () => setshowModal(!showModal);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tags, settags] = useState([]);
  const [formtag, setformtag] = useState([]);
  const [loadcateg, setloadcateg] = useState(false);
  const [mode, setMode] = useState(false);
  const [barColor, setBarColor] = useState("blue");
  const [options, setOptions] = useState([]);
  const [optionsInit, setOptionsInit] = useState([]);
  const category = useSelector(state=>state.categ);
  const [load, setLoad] = useState(false);
  const [lg, setLg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let dataMod = [];
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState({
    titre_mod: "",
    dure_mod: null,
    method_mod: "",
    objectif_mod: "",
    support_mod: "",
    lang_mod: "",
    img_mod: null,
    desc_mod: "",
    id_categ: "",
  });
  const [vide, setVide] = useState({
    titre_mod: "",
    dure_mod: null,
    method_mod: "",
    objectif_mod: "",
    support_mod: "",
    lang_mod: "",
    img_mod: null,
    desc_mod: "",
    id_categ: "",
  });
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setModule({ ...module, [name]: value });
    setError({ ...error, [name]: null });
  };

  function manova() {
    text = "";
    module.method_mod = "";
    let nb = 0;
    tags.forEach((element) => {
      nb++;
      text += element + ";";
      if (nb === tags.length) {
        module.method_mod = text;
        setError({ ...error, method_mod: null });
      }
    });
    console.log(module.method_mod);
  }
  useEffect(() => {
    manova();
  }, [tags]);

  function manovaform() {
    text = "";
    module.support_mod = "";
    let nb = 0;
    formtag.forEach((element) => {
      nb++;
      text += element + ";";
      if (nb === formtag.length) {
        module.support_mod = text;
        setError({ ...error, support_mod: null });
      }
    });
    console.log(module.support_mod);
  }
  useEffect(() => {
    manovaform();
  }, [formtag]);

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    setError({ ...error, img_mod: null });

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setModule({ ...module, img_mod: e.target.files[0] });
    }
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    setIto(choice);
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.value + ",";
      if (nb === choice.length) {
        module.id_categ = text;
        setError({ ...error, id_categ: null });
      }
    });
  };

  const getCateg = (cat) => {
    let opt = [];
    text = "";
    cat.forEach((element) => {
      opt.push({ label: element.categ, value: element.id_categ });
      text += element.id_categ + ",";
    });
    setIto(opt);
    return text;
  };

  const changeLg = (choice) => {
    setLg(choice);
    setModule({ ...module, lang_mod: choice.value });
    setError({ ...error, lang_mod: null });
  };

  const videSelect = () => {
    setformtag([]);
    setLg([]);
    setIto([]);
    settags([])
    setImage(null);
    setModule(vide)
  }

  const handleSubmit = async () => {
    const apiErrors = {};
    if (module.titre_mod === "") {
      apiErrors.titre_mod = ["le titre du module est réquis"];
      setError(apiErrors);
    } else if (module.objectif_mod === "") {
      apiErrors.objectif_mod = ["l'objectif du module est réquis"];
      setError(apiErrors);
    } else if (module.method_mod === "") {
      apiErrors.method_mod = ["la methodologie du module est réquis"];
      setError(apiErrors);
    } else if (module.support_mod === "") {
      apiErrors.support_mod = ["le support de formation du module est réquis"];
      setError(apiErrors);
    } else if (module.dure_mod === "" || module.dure_mod === null) {
      apiErrors.dure_mod = ["la durée du module est réquis"];
      setError(apiErrors);
    } else if (module.lang_mod === "" || module.lang_mod === null) {
      apiErrors.lang_mod = ["la langue du module est réquis"];
      setError(apiErrors);
    } else if (module.id_categ === "" || module.id_categ === null) {
      apiErrors.id_categ = ["la catégorie du module est réquis"];
      setError(apiErrors);
    } else if (module.img_mod === "" || module.img_mod === null) {
      apiErrors.img_mod = ["l'image du module est réquis"];
      setError(apiErrors);
    } else if (module.desc_mod === "" || module.desc_mod === null) {
      apiErrors.desc_mod = ["la déscription du module est réquis"];
      setError(apiErrors);
    } else {
      setLoad(true);
      try {
        await axios
          .post(MODULE_API, module, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success("insertion reussi");
            setModule(vide);

            gererModal();
            videSelect();
            setLoad(false);
            console.log(response);
          })
          .catch((error) => {
            toast.warn("un probleme est sruvenu");
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.lang_mod = msg.lang_mod;
              apiErrors.titre_mod = msg.titre_mod;
              apiErrors.img_mod = msg.img_mod;
              apiErrors.id_categ = msg.id_categ;
              apiErrors.desc_mod = msg.desc_mod;
              apiErrors.dure_mod = msg.dure_mod;
              apiErrors.method_mod = msg.method_mod;
              apiErrors.objectif_mod = msg.objectif_mod;
              apiErrors.support_mod = msg.support_mod;
            }

            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };
  function handleSelectAll() {
    const allOptions = options.map((option) => option.value);
    setSelectedOptions(allOptions);
  }

  const handleUpdate = async () => {
    const apiErrors = {};
    console.log(module);

    try {
      await axios
        .post(MODULE_API + "/" + id + "?_method=PUT", module, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success("modification reussi");
          gererModal();
          getModules();
          setModule(vide);
          setLoad(false);
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est sruvenu");
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            setError(msg);
          }

          console.log(error);
        });
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  const Init = (modul) => {
    var method = modul.method_mod;
    var mettremethod = method.split(";");
    mettremethod.pop();
    settags(mettremethod);
    var support = modul.support_mod;
    var mettresupport = support.split(";");
    mettresupport.pop();
    setformtag(mettresupport);

    setImage(file_url + modul.img_mod);
  };

  const fetchUpdate = () => {
    let forModule = {};
    axios
      .get(MODULE_API + "/" + id)
      .then((rep) => {
        console.log(rep);
        let champs = Object.keys(rep.data.modules);
        for (let i = 0; i < champs.length; i++) {
          const element = champs[i];
          if (rep.data.modules[element] && champs[i] != "img_mod") {
            forModule[element] = rep.data.modules[element];
          }
        }

        forModule.id_categ = getCateg(rep.data.categorie);
        setLg([{ value: forModule.lang_mod, label: forModule.lang_mod }]);
        Init(rep.data.modules);
        console.log(forModule);
        setModule(forModule);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initialiser = () => {
    document.getElementById("reset_form").click();

    setMode(false);
    setModule(vide);
    setImage(null);
    setIto([]);
    setLg([]);
    setError([]);
    settags([]);
    setformtag([]);
  };

  useEffect(()=>{
if (category) {
  let ctg = []
  category.forEach(element => {
    ctg.push({label:element.categ,value:element.id_categ});
  });
  setOptions(ctg)
}
  },[category])

  return (
    <div>
      <Button
        onClick={() => {
          videSelect();
          gererModal();
          fetchUpdate();
        }}
      >
        {id ? (
          <FontAwesomeIcon icon={faEdit} />
        ) : (
          <>
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouveau module
          </>
        )}
      </Button>
      <Modal
        scrollable
        size="xl"
        show={showModal}
        backdrop={"static"}
        onHide={gererModal}
      >
        <Modal.Header closeButton>
          <h2 className="p-2">Formulaire module</h2>
        </Modal.Header>
        <Modal.Body>
          <form encType="multipart/form-data">
            <div className="">
              <div className="row">
                <div className="col-lg-6 my-1">
                  <label className="form-label">Titre</label>
                  <input
                    className={
                      "form-control " + (error.titre_mod && "is-invalid")
                    }
                    onChange={handleChange}
                    name="titre_mod"
                    value={module.titre_mod}
                    type="text"
                    placeholder="Saisir titre du module"
                  />
                  {error.titre_mod && (
                    <p className="invalid-feedback">{error.titre_mod}</p>
                  )}
                </div>
                <div className="col-lg-6 my-1">
                  <label className="form-label">Objectif</label>
                  <input
                    className={
                      "form-control " + (error.objectif_mod && "is-invalid")
                    }
                    onChange={handleChange}
                    name="objectif_mod"
                    value={module.objectif_mod}
                    type="text"
                    placeholder="Saisir objectif du module"
                  />
                  {error.objectif_mod && (
                    <p className="invalid-feedback">{error.objectif_mod}</p>
                  )}
                </div>
                <div className="col-12 my-1">
                  <label className="form-label">Méthodologie</label>
                  <TagsInput
                    classNames="input"
                    name="meth"
                    value={tags}
                    className={
                      "taginput form-control " +
                      (error.method_mod && "is-invalid")
                    }
                    onChange={settags}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        let val = e.target.value;
                        let nam = e.target.name;
                        settags([...tags, val]);
                        document.getElementsByName(nam)[0].value = "";
                      }
                    }}
                    separators={[";", ",", ""]}
                    placeHolder="séparez-les méthodologie par des points virgules (;) ou des virgules (,)"
                  />

                  <p className={" " + (error.method_mod && "text-danger")}>
                    {error.method_mod}
                  </p>
                </div>
                <div className="col-12 my-1">
                  <label className="form-label">Support de formation</label>
                  <TagsInput
                    value={formtag}
                    name="form"
                    className={
                      "taginput w-100 " + (error.support_mod && "is-invalid")
                    }
                    onChange={setformtag}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        let val = e.target.value;
                        let nam = e.target.name;
                        setformtag([...formtag, val]);
                        document.getElementsByName(nam)[0].value = "";
                      }
                    }}
                    separators={[";", ","]}
                    placeHolder="séparez-les support de formation par des points virgules (;) ou des virgules (,)"
                  />
                  {error.support_mod && (
                    <p className={" " + (error.support_mod && "text-danger")}>
                      {error.support_mod}
                    </p>
                  )}
                </div>
                <div className="col-lg-4 my-1">
                  <label className="form-label">Durée (en heure)</label>
                  <input
                    className={
                      "form-control " + (error.dure_mod && "is-invalid")
                    }
                    onChange={handleChange}
                    name="dure_mod"
                    value={module.dure_mod}
                    type="number"
                    placeholder="Saisir la durée"
                  />
                  {error.dure_mod && (
                    <p className="invalid-feedback">{error.dure_mod}</p>
                  )}
                </div>
                <div className="col-lg-3 my-1">
                  <label className="form-label">Langue</label>
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    defaultValue={[langue[0]]}
                    isMulti={false}
                    name="lang_mod"
                    value={lg}
                    placeholder="choix langue"
                    options={langue}
                    onChange={(lg) => changeLg(lg)}
                  />
                  {error.lang_mod && (
                    <p className="text-danger fs-5">{error.lang_mod}</p>
                  )}
                </div>
                <div className="col-lg-5 my-1">
                  <label className="form-label">Categorie</label>

                  <div className="d-flex w-100 gap-2 align-items-center">
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      isMulti
                      placeholder="choix catégorie"
                      options={options.sort(function(a, b) {
                        return a.label.localeCompare(b.label);
                      })}
                      name="id_categ"
                      value={ito}
                      onChange={(choice) => change(choice)}
                      noOptionsMessage={() => "plus d'options"}
                      isDisabled={loadcateg}
                      isLoading={loadcateg}
                    />
                    {error.id_categ && (
                      <p className="text-danger fs-5">{error.id_categ}</p>
                    )}
                    <button
                      className="btn btn-cyan"
                      type="button"
                      onClick={() => change(options)}
                    >
                      toutes
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 my-2 ">
                  <label className="form-label">
                    Image de representation du module
                  </label>

                  <input
                    type="file"
                    className={
                      "form-control " + (error.img_mod && "is-invalid")
                    }
                    name="img_mod"
                    id="img_mod"
                    onChange={onImageChange}
                    placeholder="choisir un image"
                    accept="image/png, image/gif, image/jpeg"
                    multiple
                  />
                  {error.img_mod && (
                    <p className="invalid-feedback">{error.img_mod}</p>
                  )}

                  {image && (
                    <span
                      className="avatar avatar-lg my-3"
                      style={{ backgroundImage: `url(${image})` }}
                    ></span>
                  )}
                </div>
                <div className="col-lg-6 my-2 ">
                  <label className="form-label">Description du module</label>
                  <textarea
                    rows={5}
                    className={
                      "form-control " + (error.desc_mod && "is-invalid")
                    }
                    value={module.desc_mod}
                    name="desc_mod"
                    onChange={handleChange}
                    placeholder="saisir description"
                  ></textarea>
                  {error.desc_mod && (
                    <p className="text-danger fs-5">{error.desc_mod}</p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            type="button"
            onClick={() => gererModal()}
            className="btn"
            data-bs-dismiss="modal"
          >
            <FontAwesomeIcon icon={faCancel} className="me-2" /> Annuler
          </button>
          <Button
            onClick={() => (id == null  ? handleSubmit() : handleUpdate())}
            disabled={load}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
            {id == null ? <>Ajouter</> : <>Modifier</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
