import {
  faAdd,
  faEdit,
  faHouse,
  faTrash,
  faUndo,
  faUndoAlt,
  faUnsorted,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { file_url, organisme_API } from "../../../service/config";
import isconnected from "../../../service/isconnected";
import ChangerStatus from "./ChangerStatus";

export default function Organisme() {
  const [recharger,setrecharger] = useState('')
  const columns = [
    {
      name: "nom",
      wrap:true,
      selector: (row) => row.nom_org,
      sortable: true,
    },
    {
      wrap:true,
      name: "email",
      selector: (row) => row.email_org,
      sortable: true,
    },
    // {
    //   name: "adresse",
    //   selector: (row) => row.adresse_org,
    //   sortable: true,
    // },
    {
      name: "ville",
      selector: (row) => row.ville_org,
      sortable: true,
    },
    {
      name: "tel",
      selector: (row) => row.tel_org,
      sortable: true,
    },
    {
      name: "statut",
      selector: (row) =>  <div className="d-flex justify-content-center"><ChangerStatus row={row} setrecharger={setrecharger}/></div>,
      sortable: true,
    },
    {
      name: "logo",
      width:'10%',
      selector: (row) => (
        <>
          {" "}
          <span
            className="avatar avatar-md py-2 my-1"
            style={{ backgroundImage: `url(${file_url}/${row.logo_org})` }}
          ></span>
        </>
      ),
      sortable: true,
    },
    {
      width:'15%',
      cell: (row) => (
        <div className="d-flex gap-1">
          
          <button
            className="btn btn-primary"
            onClick={() => {
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}/${row.logo_org}`);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(true);
  const [formValues, setFormValues] = useState({
    nom_org: "",
    active_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    logo_org: null,
  });
  const [vide, setvide] = useState({
    nom_org: "",
    active_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    logo_org: null,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState(null);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFormValues({ ...formValues, logo_org: e.target.files[0] });
      setError({ ...error, logo_org: null });
    }
  };

  const handleSubmit = () => {
    if (!formValues.nom_org) {
      setError({ ...error, nom_org: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_org) {
      setError({ ...error, email_org: "ce champs est réquis !" });
      const input = document.getElementsByName("email_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_org) {
      setError({ ...error, adresse_org: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_org) {
      setError({ ...error, ville_org: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_org) {
      setError({ ...error, province_org: "ce champs est réquis !" });
      const input = document.getElementsByName("province_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_org) {
      setError({ ...error, cp_org: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.pays_org) {
      setError({ ...error, pays_org: "ce champs est réquis !" });
      const input = document.getElementsByName("pays_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.web_org) {
      setError({ ...error, web_org: "ce champs est réquis !" });
      const input = document.getElementsByName("web_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_org) {
      setError({ ...error, tel_org: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.type_org) {
      setError({ ...error, type_org: "ce champs est réquis !" });
      const input = document.getElementsByName("type_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.logo_org === null) {
      setError({ ...error, logo_org: "ce champs est réquis !" });
      const input = document.getElementsByName("logo_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(organisme_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("enregistrement reussi !");
          getAllOrganisme();
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_org = msg.nom_org;
            apiErrors.active_org = msg.active_org;
            apiErrors.email_org = msg.email_org;
            apiErrors.adresse_org = msg.adresse_org;
            apiErrors.ville_org = msg.ville_org;
            apiErrors.province_org = msg.province_org;
            apiErrors.cp_org = msg.cp_org;
            apiErrors.pays_org = msg.pays_org;
            apiErrors.web_org = msg.web_org;
            apiErrors.tel_org = msg.tel_org;
            apiErrors.type_org = msg.type_org;
            apiErrors.logo_org = msg.logo_org;
          }
          if (error.response.status == 500 || error.response.status == 401) {
            toast.error(error.response.data.message);
            setShow(false);
            getAllOrganisme();
            setFormValues(vide);
            setImage(null);
          }
        });
      setError(apiErrors);
    }
  };
  const handleUpdate = () => {
    if (!formValues.nom_org) {
      setError({ ...error, nom_org: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_org) {
      setError({ ...error, email_org: "ce champs est réquis !" });
      const input = document.getElementsByName("email_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_org) {
      setError({ ...error, adresse_org: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_org) {
      setError({ ...error, ville_org: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_org) {
      setError({ ...error, province_org: "ce champs est réquis !" });
      const input = document.getElementsByName("province_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_org) {
      setError({ ...error, cp_org: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.pays_org) {
      setError({ ...error, pays_org: "ce champs est réquis !" });
      const input = document.getElementsByName("pays_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.web_org) {
      setError({ ...error, web_org: "ce champs est réquis !" });
      const input = document.getElementsByName("web_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_org) {
      setError({ ...error, tel_org: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.type_org) {
      setError({ ...error, type_org: "ce champs est réquis !" });
      const input = document.getElementsByName("type_org")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (typeof formValues.logo_org === "string") {
      const { logo_org, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(organisme_API + `/${formValues.id_org}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("Modification reussi !");
          getAllOrganisme();
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (error.response.status == 500 || error.response.status == 401) {
            toast.error(error.response.data.message);
            setShow(false);
            getAllOrganisme();
            setFormValues(vide);
            setImage(null);
          }
          if (msg) {
            apiErrors.nom_org = msg.nom_org;
            apiErrors.active_org = msg.active_org;
            apiErrors.email_org = msg.email_org;
            apiErrors.adresse_org = msg.adresse_org;
            apiErrors.ville_org = msg.ville_org;
            apiErrors.province_org = msg.province_org;
            apiErrors.cp_org = msg.cp_org;
            apiErrors.pays_org = msg.pays_org;
            apiErrors.web_org = msg.web_org;
            apiErrors.tel_org = msg.tel_org;
            apiErrors.type_org = msg.type_org;
            apiErrors.logo_org = msg.logo_org;
          }
        });
      setError(apiErrors);
    }
  };

  const getAllOrganisme = () => {
    setLoad(true);
    axios
      .get(organisme_API +
        `${
          isconnected.user().type_util == "SuperAdmin" ? "?type_org=MTM" : isconnected.user().type_util == "MTM" ? "?type_org=Auto-écoles,CIM,Examinateurs,ATT,CIM,PTF,Autre" : "type_org= "
        }`)
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const SupprimerOrg = () => {
    setsupp(false);

    for (let index = 0; index < selectedRows.length; index++) {
      const id = selectedRows[index];
      axios
        .delete(organisme_API + "/" + id)
        .then((rep) => {
          console.log(rep);
          if (index === selectedRows.length - 1) {
            // la boucle est terminée
          }

          if (index === selectedRows.length - 1) {
            toast.success("element(s) supprimé(s)");
            getAllOrganisme();
            handleClearRows();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 500 || err.response.status == 401) {
            toast.error(error.response.data.message);
          }
        });
    }
  };
  useEffect(() => {
    getAllOrganisme();
  }, [recharger]);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => SupprimerOrg()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h3>MINISTERE DES TRANSPORTS ET DE LA METEOROLOGIE </h3>
          <p>Gérer</p>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouveau MTM
          </Button>
        </div>
      </div>

      <div className="">
        <DataTable
          title={<p className="fs-4">-----------</p>}
          columns={columns}
          data={data}
          selectableRows
          selectedRow={selectedRows}
          clearSelectedRows={toggledClearRows}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows.map((row) => row.id_org));
          }}
          progressPending={load}
          contextActions={
            <div className="flex">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  setToggleClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>
              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  setsupp(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire organisme</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="">
                    <label className="form-label">Nom (*)</label>
                    <input
                      className={
                        "form-control " + (error.nom_org && "is-invalid")
                      }
                      name="nom_org"
                      value={formValues.nom_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir nom organisme"
                    />
                    {error.nom_org ? (
                      <div className="invalid-feedback">{error.nom_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Email (*)</label>
                    <input
                      className={
                        "form-control " + (error.email_org && "is-invalid")
                      }
                      name="email_org"
                      value={formValues.email_org}
                      onChange={handleChange}
                      type="email"
                      placeholder="Saisir email de l'organisme"
                    />
                    {error.email_org ? (
                      <div className="invalid-feedback">{error.email_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Adresse (*)</label>
                    <input
                      className={
                        "form-control " + (error.adresse_org && "is-invalid")
                      }
                      name="adresse_org"
                      value={formValues.adresse_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir adresse de l' organisme"
                    />
                    {error.adresse_org ? (
                      <div className="invalid-feedback">
                        {error.adresse_org}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Ville (*)</label>
                    <input
                      className={
                        "form-control " + (error.ville_org && "is-invalid")
                      }
                      name="ville_org"
                      value={formValues.ville_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir ville de l'organisme"
                    />
                    {error.ville_org ? (
                      <div className="invalid-feedback">{error.ville_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Province (*)</label>
                    <input
                      className={
                        "form-control " + (error.province_org && "is-invalid")
                      }
                      name="province_org"
                      value={formValues.province_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir province de l'organisme"
                    />
                    {error.province_org ? (
                      <div className="invalid-feedback">
                        {error.province_org}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row">
                    <div className=" col-12 col-lg-4">
                      <label className="form-label">Code postal (*)</label>
                      <input
                        className={
                          "form-control " + (error.cp_org && "is-invalid")
                        }
                        name="cp_org"
                        value={formValues.cp_org}
                        onChange={handleChange}
                        type="number"
                        placeholder="Saisir son code postal"
                      />
                      {error.cp_org ? (
                        <div className="invalid-feedback">{error.cp_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12 col-lg-8">
                      <label className="form-label">Pays (*)</label>
                      <input
                        className={
                          "form-control " + (error.pays_org && "is-invalid")
                        }
                        name="pays_org"
                        value={formValues.pays_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir le pays"
                      />
                      {error.pays_org ? (
                        <div className="invalid-feedback">{error.pays_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">web</label>
                    <input
                      className={
                        "form-control " + (error.web_org && "is-invalid")
                      }
                      name="web_org"
                      value={formValues.web_org}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir web"
                    />
                    {error.web_org ? (
                      <div className="invalid-feedback">{error.web_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Telephone </label>
                    <InputMask
                      onKeyUp={(e) => {
                        console.log(e.target.value);
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            tel_org: ["le champs telephone est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, tel_org: [] });
                        }
                      }}
                      onChange={handleChange}
                      mask="+261 39 99 999 99"
                      type="text"
                      className={
                        "form-control " +
                        (error.tel_org?.length > 0 && " is-invalid")
                      }
                      value={formValues.tel_org}
                      name="tel_org"
                      placeholder="Telephone"
                    />

                    {error.tel_org &&
                      error.tel_org.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          {element}
                        </p>
                      ))}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Type (*)</label>
                    <select
                      className={
                        "form-control " + (error.type_org && "is-invalid")
                      }
                      name="type_org"
                      value={formValues.type_org}
                      onChange={handleChange}
                    >
                      <option>choisir type ...</option>
                      {isconnected.user().type_util === "ATT" && (
                        <>
                          <option value="Auto-écoles">Auto-écoles</option>
                          <option value="Examinateurs">Examinateurs</option>
                        </>
                      )}
                      {isconnected.user().type_util === "MTM" && (
                        <option value="ATT">ATT</option>
                      )}
                      {isconnected.user().type_util === "SuperAdmin" && (
                        <>
                          {/* <option value="CIM">CIM</option> */}
                          <option value="MTM" selected>
                            Ministère du Transport et de la Méteorologie de
                            Madagascar
                          </option>
                          {/* <option value="PTF">PTF</option> */}
                        </>
                      )}
                    </select>
                    {error.type_org ? (
                      <div className="invalid-feedback">{error.type_org}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">Logo </label>
                        <input
                          className={
                            "form-control " + (error.logo_org && "is-invalid")
                          }
                          name="logo_org"
                          id="logo_org"
                          type="file"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        {error.logo_org && (
                          <div className="invalid-feedback">
                            {error.logo_org}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                      {image && (
                        <span
                          className="avatar avatar-md"
                          style={{ backgroundImage: "url(" + image + ")" }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => (mode ? handleUpdate() : handleSubmit())}
          >
            {mode ? <>Modifier</> : <>Enregistrer</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
