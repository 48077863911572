import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ACTU_API, CATEGORIE_API, file_url } from "../../../service/config";
import LoadingBar from "react-top-loading-bar";
import { TagsInput } from "react-tag-input-component";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function GererActu() {
    var text = "";
  const [id, setID] = useState(null);
  const navigate = useNavigate()
  const ref = useRef(null);
  const [barColor, setBarColor] = useState("blue");
  const [tags, settags] = useState([]);
  const [mode, setMode] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    titre_actu: "",
    tag_actu: "",
    desc_actu: "",
    resum_actu:"",
    img_actu: null,
  });
  const vide = {
    titre_actu: "",
    tag_actu: "",
    resum_actu:"",
    desc_actu: "",
    img_actu: null,
  };
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    if (value === "") {
      setError({...error,[name]:"ce champs est réquis !"})
    }else{
      setError({...error,[name]:null})
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setError({...error,img_actu:null})
      setFormValues({ ...formValues, img_actu: e.target.files[0] });
    }
  };

  function manova() {
    text = "";
    formValues.tag_actu = "";
    let nb=0
      tags.forEach((element) => {
        nb++;
        text += element + ";";
        if (nb === tags.length) {
            formValues.tag_actu = text;
          setError({ ...error, tag_actu: null });
        }
      });
      console.log(formValues.tag_actu);
  }
   useEffect(() => {
    manova()
   }, [tags])

  const getCategorie = async () => {
    setBarColor("blue");
    ref.current.continuousStart();

    try {
      await axios
        .get(ACTU_API)
        .then((response) => {
          const data = response.data.data;
          console.log(data);
          ref.current.complete();
          setCategorie(data);
        })
        .catch((error) => {
          setBarColor("red");
          console.log(error);
          console.log(error);
        });
    } catch (error) {
      toast.error("un erreur est survenu");
      setBarColor("red");
      console.log(error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const data = {};
    const msgErreur = {};
    if (formValues.img_actu == null) {
      data.titre_actu = formValues.titre_actu;
      data.tag_actu = formValues.tag_actu;
      data.desc_actu = formValues.desc_actu;
      data.resum_actu = formValues.resum_actu;
    } else {
      data.titre_actu = formValues.titre_actu;
      data.tag_actu = formValues.tag_actu;
      data.desc_actu = formValues.desc_actu;
      data.resum_actu = formValues.resum_actu;
      data.img_actu = formValues.img_actu;
    }
    setFormValues(data);
    if (mode) {
      try {
        await axios
          .post(ACTU_API + "/" + id + "?_method=PUT", formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Element modifiée");
            setLoad(false);

            getCategorie();
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            console.log(msg);
            if (msg) {
              msgErreur.titre_actu = msg.titre_actu;
              msgErreur.tag_actu = msg.tag_actu;
              msgErreur.desc_actu = msg.desc_actu;
              msgErreur.resum_actu = msg.resum_actu;
              msgErreur.img_actu = msg.img_actu;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
        setError([]);
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    } else {
      try {
        await axios
          .post(ACTU_API, formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Actualité Ajouté");
            setLoad(false);
            setError([]);
            getCategorie();
          })
          .catch((error) => {
            const msg = error.response.data;
            console.log(error);
            console.log(msg);
            if (msg) {
              msgErreur.titre_actu = msg.titre_actu;
              msgErreur.tag_actu = msg.tag_actu;
              msgErreur.desc_actu = msg.desc_actu;
              msgErreur.img_actu = msg.img_actu;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    }

  };

  const fetchUpdate = async (element) => {
    setError([]);
    setMode(true);
    setID(element.id_actu);
    settags([])
  
    formValues.titre_actu = element.titre_actu;
    formValues.tag_actu = element.tag_actu;
    formValues.desc_actu = element.desc_actu;
    formValues.resum_actu = element.resum_actu;
   if (element.tag_actu!=="") {
    let tag = element.tag_actu.split(";")
    if (tag[tag.length-1] ==="") {
     tag.pop()
     settags(tag)
    }else{
      settags(tag)
    }
   }

    setImage(`${file_url}${element.img_actu}`);
  };
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin ===null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map((element, index) => (
           element!=="" && <span key={index} className="badge bg-cyan px-2 rounded-3 m-1">
              {element}
            </span>
          ))}
        </>
      );
    }
  }
  const initialiser = () => {
    setMode(false);
    setFormValues(vide);

    settags([])
    setImage(null);
    setError([]);
  };

  const supprimer = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      await axios
        .delete(ACTU_API + "/" + id)
        .then((response) => {
          toast.success("Actualité " + id + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getCategorie();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Actualité pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  useEffect(() => {
    getCategorie();
  }, []);

  return (
    <>
      <LoadingBar color={barColor} ref={ref} />
      <div className="container-lg container-fluid py-4">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h2>Actualités</h2>
            <p className="">Liste des Actualités</p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => navigate("/admin/gerer_actualite/new")}
              className="btn btn-primary px-4 py-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvelle
              Actualité
            </button>
          </div>
        </div>
        <div className="row">
          <div className="overflow-auto">
            <table className="table table-hover table-bordered table-striped table-light ">
              <thead>
                <tr className="">
                  <th>ID</th>
                  <th>Titre</th>
                  <th>Tag</th>
                  <th>Résumé</th>
                  <th>Image</th>
                  <th>Date</th>
                  <th className="col-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {categorie.map((element) => (
                  <tr key={element.id_actu}>
                    <td>{element.id_actu}</td>
                    <td>{element.titre_actu}</td>
                    <td>{Separer(element.tag_actu)}</td>
                    <td>{element.resum_actu}</td>
                    <td>{moment(element.created_at).format('DD-MM-yyyy')}</td>
                    <td>
                      {" "}
                      <span
                        className="avatar avatar-sm"
                        style={{
                          backgroundImage: `url(${file_url}/${element.img_actu})`,
                        }}
                      ></span>
                    </td>
                    <td className="d-flex gap-2 justify-content-center">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => navigate("/admin/gerer_actualite/"+element.id_actu)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <FontAwesomeIcon color="blue" icon={faEdit} />
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => setID(element.id_actu)}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-danger"
                      >
                        <FontAwesomeIcon color="red" icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal modal-blur"
        id="modal-danger"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon mb-2 text-danger icon-lg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9v2m0 4v.01"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>
              <h3>vous etes sur ?</h3>
              <div className="text-muted">
                vous-voulez vraiment supprimer cette Actualité ? c'est
                irréversible.
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="fermerdel"
                      className="btn w-100"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={supprimer}
                      className={"btn btn-danger w-100 " + (load && "disabled")}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
