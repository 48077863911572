import {
  faBook,
  faEnvelopeOpenText,
  faFile,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { file_url, loisByType_API, TexteLois_API } from "../../service/config";

export default function List_Text_Lois() {
  let { id } = useParams();
  const navigate =useNavigate()
  const [load, setload] = useState(true);
  const [textlois, setTextlois] = useState([]);
  function verifier(url) {
    var parts = url.split(".");
    var extension = parts.pop();
    return extension;
  }
  const download = async (ur) => {
    const url = file_url + ur;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const getTextloi = async () => {
    setload(true);
    try {
      await axios
        .get(loisByType_API + "/" + id)
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.data);
          setTimeout(() => {
            document.getElementById("contenu").innerHTML =
              reponse.data.lois[0].details_lois;
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();
  }, [id]);

  return (
    <div className="container-lg container-fluid py-3">
      <div className="my-3 card py-2 d-flex align-items-center">
        <p className="h1 text-center fst-italic">
          <FontAwesomeIcon icon={faBook} className="me-2" />
          Textes en vigueur
        </p>
      </div>
      <div className="row">
        {load ? (
          <div className="col-12 p-5">
           <div className="display-6 text-center">Chargement ...</div>
          </div>
        ) : textlois.length === 0 ? (
          <div className="w-100  my-5 p-5">
            <p className="display-5 fw-light text-secondary text-center">
              Aucun contenu pour le moment
            </p>
          </div>
        ) : (
          textlois.map((element, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3 d-flex align-items-stretch"
            >
              <div class="card">
                <div class="row row-0">
                  <div class="col-3 order-md-last">
                    <img
                      src={file_url+element.img_lois}
                      class="w-100 h-100 object-cover card-img-end"
                      alt="type "
                    />
                  </div>
                  <div class="col">
                    <div class="card-body">
                      <Link to={"/texte et lois/"+element.id_lois} replace><h3 class="card-title">{element.titre_lois}</h3></Link>
                      <p class="text-muted">
                        {element.desc_lois}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
