import {
  faBookBookmark,
  faCheck,
  faCross,
  faPlus,
  faSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { Rating } from "react-simple-star-rating";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const question = [
  {
    id: 1,
    ques: "comment ?",
    reponses: [
      { id: 1, value: "ity", marina: false },
      { id: 2, value: "non", marina: false },
      { id: 3, value: "belk", marina: true },
    ],
  },
  {
    id: 2,
    ques: "inona ?",
    reponses: [
      { id: 4, value: "meee", marina: false },
      { id: 5, value: "hihihih", marina: true },
      { id: 6, value: "nna", marina: true },
    ],
  },
  {
    id: 3,
    ques: "quoi ?",
    reponses: [
      { id: 7, value: "aaa", marina: true },
      { id: 8, value: "eka", marina: false },
      { id: 9, value: "hum", marina: true },
    ],
  },
];
export default function Exercices() {
  const [rating, setRating] = useState(0);
  const [progress, setProgress] = useState(65);
  const [show, setshow] = useState(false);
  const [reponse, setreponse] = useState([]);
  const tooltipArraylist = [
    "très mauvais",
    "mauvais",
    "modérée",
    "très bon",
    "excellent",
  ];

  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours >= 1 && minutes >= 1 && seconds >= 1) {
      return (
        <>
          {hours < 10 ? <>0{hours}</> : { hours }}:
          {minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours >= 1 && minutes == 0) {
      return (
        <>
          {hours}:00:{seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (minutes >= 1 && hours == 0) {
      return (
        <>
          00:{minutes < 10 ? <>0{minutes}</> : minutes}:
          {seconds < 10 ? <>0{seconds}</> : seconds}
        </>
      );
    } else if (hours == 0 && minutes == 0) {
      return <>00:00:{seconds < 10 ? <>0{seconds}</> : seconds}</>;
    }
  }

  // Catch Rating value
  let handleChange = (i, e, ques) => {
    let datareponse = [...reponse];
    datareponse[i] = { id_ques: ques, id_rep: e.target.id };
    setreponse(datareponse);
  };
  useEffect(() => {
    console.log(reponse);
  }, [reponse]);
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);
  return (
    <div className="container-fluid bg-light m-0 p-0 pb-4">
      <ScrollToTopOnMount />
      <div className="container-lg container-fluid">
        {!show && (
          <div className="row mb-3">
            <div className="col-12">
              <div className="row bg-white my-3 shadow">
                <div className="py-3 col-lg-6 col-sm-12 col-12">
                  <div className="  p-3">
                    <h5 className="">Titre module</h5>
                    <p
                      style={{ fontSize: "16px" }}
                      className="card-subtitle fw-bold mb-2"
                    >
                      Categorie A'
                    </p>
                    <p className="fs-4">
                      Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                      aut odit aut fugit, sed quia consequuntur magni dolores
                      eos qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem.
                    </p>
                    <div className="mb-3">
                      <p className="mb-0 fw-bold">Moyenne de ce module</p>
                      <Rating
                        onClick={handleRating}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                        showTooltip={true}
                        tooltipArray={tooltipArraylist}
                        tooltipDefaultText="votre note"
                        titleSeparator="sur"
                        initialValue={0}
                        readonly={false}
                        /* Available Props */
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col">
                        <p
                          style={{ fontSize: "12px" }}
                          className="mb-1 fw-bold"
                        >
                          {progress}% de cours de ce modules terminer
                        </p>
                        <div className="progress" style={{ height: "15px" }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: progress.toString() + "%" }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {progress}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 col-lg-6 col-sm-12 col-12 ">
                  <div className=" bg-secondary h-100"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!show ? (
          <div className="row justify-content-center">
            <div className="col-12 d-flex justify-content-center flex-column bg-white py-4 p-3">
              <h1>Exercice aléatoire</h1>
              <p>
                Ameliorez vos compétence dans des series d'exercice aléatoire
                qui testerons vos connaissance en la matiere de conduite d'une
                voiture. A la fin une note vous sera distrubuée pour voir a quel
                point votre niveau de connaissance se situe et ainsi l'ameliorer
                au fur et a mesure
              </p>
              <div className="my-3">
                <button
                  className="btn btn-lg btn-primary rounded-4 px-4 py-3"
                  onClick={() => setshow(true)}
                >
                  Commencer un exercice
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12 my-2 mt-3">
              <div className="card">
                <div className="card-body ">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-lg-8 col-12">
                      <h1>Exercice</h1>
                      <p>Cochez la bonne reponse </p>
                      <p>
                        Remarques: veuillez cliquer sur le bouton terminer en
                        bas de page apres avoir cochez toute les cases pour voir
                        votre note{" "}
                      </p>
                    </div>
                    <div className="col-lg-2 col-12">
                      <p>
                        Module: <strong>module 1</strong>
                      </p>
                      <p>
                        Durée: <strong>30 minutes</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-12 p-1">
              <div className="m-0 p-0">
                <form>
                  {question.map((element, index) => (
                    <div key={index} className="mb-3 card py-3 px-4">
                      <p>{element.ques}</p>

                      <div
                        className=""
                        onChange={(e) => handleChange(index, e, element.id)}
                      >
                        {element.reponses.map((el, indi) => (
                          <div key={indi} className="form-check  text-danger">
                            <input
                              type="radio"
                              className="form-check-input border-danger"
                              id={el.id}
                              name={element.id}
                              value="option1"
                            />
                            <label className="form-check-label" for={el.id}>
                              {el.value}{" "}
                              <FontAwesomeIcon icon={faTimes} color="red" />{" "}
                              <FontAwesomeIcon icon={faCheck} color="green" />
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <div className="d-flex flex-row gap-2 justify-content-end mt-5">
                    <button
                      type="button"
                      className="px-3 btn rounded-1 btn-secondary"
                      onClick={() => setshow(false)}
                    >
                      Abandonner
                    </button>
                    <button type="" className="px-3 btn rounded-1 btn-primary">
                      Terminer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
