import {
  faBook,
  faEnvelopeOpenText,
  faFile,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { file_url, TexteLois_API } from "../../service/config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";


export default function Text_Lois() {

  let { id } = useParams();
  const [load, setload] = useState(true);
  const [textlois, setTextlois] = useState({});
  function verifier(url) {
    var parts = url.split(".");
    var extension = parts.pop();
    return extension;
  }
  const download = async (ur) => {
    const url = file_url + ur;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const getTextloi = async () => {
    setload(true);
    try {
      await axios
        .get(TexteLois_API + "/" + id)
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.lois[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();
  }, []);

  return (
    <div className="container-lg container-fluid py-3">
        <div className="my-3 card py-2 d-flex align-items-center" >
          
          <p className="h1 text-center fst-italic" ><FontAwesomeIcon icon={faBook} className="me-2"/>Textes en vigueur</p>
          
        </div>
      <div className="card">
        <div className="card-body">
          <div className="row  flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
            <div className="col-12 col-sm-8 col-lg-4">
              {load === false && (
                <img
                  src={file_url + textlois.img_lois}
                  className="d-block mx-lg-auto img-fluid text-center"
                  style={{ width: "60%" }}
                  alt="permis categorie"
                  loading="lazy"
                />
              )}
            </div>
            <div className="col-lg-8">
              <h1 className="display-7 fw-bold lh-1 mb-3 text-primary">
                {textlois.titre_lois}
              </h1>
              <p className="lead">{textlois.desc_lois}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 rounded-3">
          <div className="card">
            <div className="card-body">
            <div className="non-edit">
              <CKEditor
                editor={Editor}
                data={textlois.details_lois}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                  editor.enableReadOnlyMode("editor");
                  const toolbar = editor.ui.view.toolbar.element;
                  toolbar.style.display = "none";
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                // onBlur={ ( event, editor ) => {
                //     console.log( 'Blur.', editor );
                // } }
                // onFocus={ ( event, editor ) => {
                //     console.log( 'Focus.', editor );
                // } }
              />
              </div>
            </div>
          </div>
        </div>
      {textlois.fichiert !==undefined && <> <div className="col-12 pt-3">
          <div className="p-3 bg-white">
            <h4>Description Image</h4>
            <div className="row">
              {textlois.fichiert.length === 0 ? (
                <div className="display-6 text-center">Aucun fichier</div>
              ) : (
                textlois.fichiert.map((element, index) => {
                  if (element.type_ficht === "Photo") {
                    return (
                      <div
                        key={index}
                        className="my-2 col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch p-2"
                      >
                        <div className="py-3">
                          <h5>{element.titre_ficht}</h5>
                          <img
                            src={file_url + element.fichiert}
                            height="100%"
                          />
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        </div>
        <div className="col-12 pt-3">
          <div className="p-3 bg-white">
            <h4>Description Audio</h4>
            {textlois.fichiert.map((element, index) => {
              if (element.type_ficht === "Audio") {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-12 col-12"
                  >
                    <h5 className="my-2">{element.titre_ficht}</h5>
                    <figure>
                      <audio controls src={file_url + element.fichiert}>
                        <a href={file_url + element.fichiert}>Telecharger</a>
                      </audio>
                    </figure>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="col-12 pt-3">
          <div className="p-3 bg-white">
            <h4>Description document et autre</h4>
            <div className="row gap-2">
              {textlois.fichiert.map((element, index) => {
                if (element.type_ficht === "Document") {
                  return (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-sm-12 col-12"
                    >
                      <h5 className="my-2">{element.titre_ficht}</h5>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => download(element.fichiert)}
                      >
                        Télécharger{" "}
                        {verifier(element.fichiert) === "doc" ||
                        verifier(element.fichiert) === "docx" ? (
                          <FontAwesomeIcon
                            className="ps-2"
                            size="2x"
                            icon={faFileWord}
                          />
                        ) : verifier(element.fichiert) === "pdf" ? (
                          <FontAwesomeIcon
                            className="ps-2"
                            size="2x"
                            icon={faFilePdf}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ps-2"
                            size="2x"
                            icon={faFile}
                          />
                        )}
                      </button>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div> </> }
      </div>
    </div>
  );
}
