import React from 'react'

function truncate(str) {
    if (str) {
      return str.length > 100 ? str.substring(0, 100) + "..." : str;
    }
  }

function truncateTitle(str) {
    if (str) {
      return str.length > 25 ? str.substring(0, 27) + "..." : str;
    }
  }
export {
    truncate,
    truncateTitle
}