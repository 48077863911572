import { faArrowLeft, faArrowRight, faCommentAlt, faPaperPlane, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { commentmodule_API } from "../../../service/config";
import isconnected from "../../../service/isconnected";
import { Button, Modal } from "react-bootstrap";


export default function Comment_Mod({id}) {
  const [mod, setmod] = useState(false);
  moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});
const navigate = useNavigate()
  // eslint-disable-next-line
  const [rating, setRating] = useState(0);
  // eslint-disable-next-line
  const [moyenne, setMoyenne] = useState(0);
  const [module, setmodule] = useState({});
  const [loadcomment, setloadcomment] = useState(true);
  const [info, setInfo] = useState([])
  const [loadcom, setloadcom] = useState(false);
  const [loadmod, setloadmod] = useState(true);
  const [comments, setcommments] = useState([]);
  const [comment, setComment] = useState({
    com_mod: "",
    id_mod:id
  });
  const vide = useState({
    com_mod: "",
    id_mod:id
  });
  const [avis, setAvis] = useState({});
  const tooltipArraylist = [
    "très mauvais",
    "mauvais",
    "modérée",
    "très bon",
    "excellent",
  ];
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  

  const getAllComment = async () => {
    setloadcomment(true);
    try {
      await axios
        .get(commentmodule_API + "/modules/" + id)
        .then((response) => {
          console.log(response);
          setcommments(response.data.commentairemodules);
          setloadcomment(false);
          console.log(info)
        })
        .catch((er) => {
          console.log(er);
          setloadcomment(false);
        });
    } catch (error) {
      console.log(error);
      setloadcomment(false);
    }
  };
  const handleModal = () => setmod(!mod);
  return (
   <>
    <Button
        className="bg-info"
        onClick={() => {
          getAllComment();
          handleModal();
        }}
      >
        <FontAwesomeIcon className="me-2" icon={faCommentAlt} /> voir
      </Button>
      <Modal size="lg" show={mod} onHide={handleModal} scrollable>
        <Modal.Header
          className="col-12 d-flex justify-content-between align-items-center my-2"
          closeButton
        >
          <h2>Liste de commentaires</h2>
        </Modal.Header>
        <Modal.Body>
        <div className="row justify-content-center">
          {loadcomment ? (
            <div className="col-12 p-5 display-6 text-center">
              Chargement des commentaires
            </div>
          ) : comments.length === 0 ? (
            <div className="col-12 p-5 display-6 text-center">
              Aucun commentaire
            </div>
          ) : (
            comments.map((element, index) => (
              <div key={index} className={"col-lg-11 col-12 ps-3 bg-white py-3 rounded-3 "}>
                <div className={"d-flex"} >
                  <div className="flex-shrink-0">
                    <img
                      src={"avatar"}
                      className="rounded-circle"
                      alt="Sample avatar"
                      width={45}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3" style={{marginLeft:"45px"}}>
                    <h5>
                     {element.user.nom_util}{" "} {(isconnected.user().email === element.user.email ) && <i className="text-primary pe-2">vous</i>}
                      <small className="text-muted">
                        <i>Posté {moment(element.created_at).fromNow()}</i>
                      </small>
                    </h5>
                    <p>
                     {element.com_mod}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        </Modal.Body>
        </Modal>
   </>
    
  );
}
