import {
  faSignsPost,
  faUser,
  faUserGroup,
  faUserPlus,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Nombre } from "../../service/CallApi/TotalUserByDay";
import axios from "axios";
import { getNombreInscrits } from "../../service/config";
import Gestion from "./Utilisateurs/Gestion";

export default function DashboardV2() {
  const [data, setdata] = useState({});
  const [load,setload] = useState(true)
  const getNbInscrits = () => {
    setload(true)
    try {
      axios
        .get(getNombreInscrits + "?nbr_jours")
        .then((rep) => {
          setdata(rep.data);
          setload(false)
        })
        .catch((err) => {
          console.log(err);
          setload(false)
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNbInscrits();
  }, []);
  return (
    <div className="h-100">
      <div className="jumbotron container-lg container-fluid h-100 w-100 py-4">
        <div className="row row-cards">
          <div className="col-12 d-flex justify-content-start">
            <h3>Tableau de bord</h3>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 col-lg-4 d-flex align-items-stretch">
              <div className="card w-100 h-100">
                <div className="card-body text-center">
                  <h4>Total inscrits</h4>
                  <span className="display-5">{data?.totalInscrits}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-lg-4 d-flex align-items-stretch">
              <div className="card w-100 h-100">
                <div className="card-body text-center">
                  <h4>Les inscrits ce 30 derniers jours</h4>
                  <span className="display-5">
                    {data?.inscritsDerniersJours}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-4 d-flex align-items-stretch">
              <div className="card w-100 h-100">
                <div className="card-body text-center">
                  <h4 className="mb-4"> Durée moyenne de connexion</h4>
                  <span className="h1 pt-2">{!load && "2 heures"}</span>
                </div>
              </div>
            </div>
          </div>
          <Gestion dash={true}/>
        </div>
      </div>
    </div>
  );
}
