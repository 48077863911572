import {
  faArrowAltCircleRight,
  faFile,
  faFilePdf,
  faFileWord,
  faListDots,
  faNewspaper,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ACTU_API, file_url } from "../../../service/config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector } from "react-redux";
import StickyBox from "react-sticky-box";
import { ScrollPanel } from "primereact/scrollpanel";
import { BlockUI } from "primereact/blockui";
import { Button } from "react-bootstrap";
import RetourComp from "../../../outils/RetourComp";

export default function Details_actu() {
  const abortController = new AbortController();
  const navigate = useNavigate();
  const dataRedux = useSelector((state) => state.actu);
  const location = useLocation();
  let { id } = useParams();
  console.log(dataRedux);
  const [load, setload] = useState(true);
  const [textlois, setTextlois] = useState({});
  const editorConfig = {
    toolbar: "",
  };
  function Separer(strin) {
    if (strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }
  function verifier(url) {
    var parts = url.split(".");
    var extension = parts.pop();
    return extension;
  }
  const download = async (ur) => {
    const url = file_url + ur;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const getTextloi = () => {
    setload(true);
    try {
      axios
        .get(ACTU_API + "/" + id, {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.actualite);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getActualDetail = (id_a) => {
    setload(true);
    try {
      axios
        .get(ACTU_API + "/" + id_a, {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.actualite);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="container-lg container-fluid py-3">
      <div className="row">
        <div className="col-12 col-lg-8" style={{ position: "relative" }}>
          {load && (
            <FontAwesomeIcon
              style={{
                position: "absolute",
                zIndex: "49999999",
                top: "45%",
                left: "45%",
                transform: "translate(-50%, -50%)",
              }}
              size="4x"
              color="white"
              spinPulse
              className="ms-2"
              icon={faSpinner}
            />
          )}
        
            <div className="card w-100">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h1 className="fst-italic">
      <RetourComp/>Actualité
                </h1>
                <Button variant="primary" style={{position:'relative',zIndex:'55'}} onClick={() => navigate("/tous les actualités")}>
                  
                    Toutes les actualités{" "}
                    <FontAwesomeIcon className="ms-2" icon={faListDots} />
                  
                </Button>
              </div>
            </div>
            <BlockUI style={{minHeight:'500px !important'}} blocked={load}>
            <div className="card">
              <div className="card-body">
                <div className="row  flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
                  <div className="col-12 col-sm-8 col-lg-4">
              
                      <img
                        src={file_url + textlois.img_actu}
                        className="d-block mx-lg-auto img-fluid text-center"
                        style={{ width: "60%" }}
                        alt="permis categorie"
                        loading="lazy"
                      />
                    
                  </div>
                  <div className="col-lg-8">
                    <h1 className="display-7 fw-bold lh-1 mb-3 text-primary">
                      {textlois.titre_actu}
                    </h1>
                    <div className="my-2 d-flex gap-1">
                      {Separer(textlois.tag_actu)}
                    </div>
                    <p className="lead">{textlois.resum_actu}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 rounded-3">
                <div className="card" style={{minHeight:'300px'}}>
                  <div className="card-body">
                    <div className="non-edit">
                      <CKEditor
                        config={editorConfig}
                        editor={Editor}
                        data={
                          textlois.desc_actu
                            ? textlois.desc_actu
                            : "Chargement..."
                        }
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                          editor.enableReadOnlyMode("editor");
                          const toolbar = editor.ui.view.toolbar.element;
                          toolbar.style.display = "none";
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                        }}
                        // onBlur={ ( event, editor ) => {
                        //     console.log( 'Blur.', editor );
                        // } }
                        // onFocus={ ( event, editor ) => {
                        //     console.log( 'Focus.', editor );
                        // } }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BlockUI>
        </div>
        <div className="col-12 col-lg-4 py-3">
          <StickyBox offsetTop={20} offsetBottom={20}>
            <h2>Autres actualités pour vous :</h2>
            <ScrollPanel
              className="custombar1"
              style={{ width: "100%", height: "500px", overflow: "auto" }}
            >
              {dataRedux.map((el, i) =>
                el.id_actu == id ? null : (
                  <div key={i} className="card my-1">
                    <div className="card-body">
                      <div className="row  flex-lg-row-reverse justify-content-between align-items-center">
                        <div className="col-4">
                      
                            <img
                              src={file_url + el.img_actu}
                              className="d-block mx-lg-auto img-fluid text-center"
                              style={{ width: "100%", maxWidth: "100px" }}
                              alt="couverture"
                              loading="lazy"
                            />
                        
                        </div>
                        <div className="col-8">
                          <Link
                            to={"/détails actualités/" + el.id_actu}
                            state={el}
                            onClick={() => getActualDetail(el.id_actu)}
                          >
                            {" "}
                            <h1 className=" fw-bold lh-1 mb-3 text-primary text-justify">
                              {el.titre_actu}
                            </h1>
                          </Link>
                          <div className="my-2 d-flex gap-1">
                            {Separer(el.tag_actu)}
                          </div>
                          <p className="lead fs-5">{el.resum_actu}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </ScrollPanel>
          </StickyBox>
        </div>
      </div>
    </div>
  );
}
