import { faCheckDouble, faEdit, faEye, faMailBulk, faReply, faTrash, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import image from "../../assets/image/imgtest/image";
import { Contact_API, file_url } from "../../service/config";

export default function NotificationAdmin() {
  const columns = [
    {
      name: "id",
      selector: (row) => row.id_cont,
      sortable: true,
      width:'8%'
    },
    {
      name: "Sujet",
      selector: (row) => row.object_cont,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_cont,
      sortable: true,
    },
    {
      name: "Description",
      maxWidth: '155px',
      selector: (row) => row.desc_cont,
      sortable: true,
    },
    {
      name: "réçu le",
      selector: (row) => <>{moment(row.created_at).locale('fr').format('LL LT')}</>,
      sortable: true,
    },
    {
      name: "lu",
      width:'8%',
      selector: (row) => (
        <>
          {" "}
          {row.is_repondre ? <span className="badge bg-success">lu<FontAwesomeIcon icon={faCheckDouble} className='ms-2'/></span> : <span className="badge bg-secondary">non lu</span>}
        </>
      ),
      sortable: true,
    },
    {
      cell: (row) => (
        <Link
        to={"/admin/mes notification/reply"}
        state={{ data: row }}
        className='text-center'
      >
        <span className="badge bg-primary">
          voir <FontAwesomeIcon className="ms-1" icon={faEye} />
        </span>
      </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [onIt, setOnIt] = useState(false);
 
  const [supp, setsupp] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
 

  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const [listcontact, setlistcontact] = useState([]);
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
    monthsShort:
      "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
    monthsParseExact: true,
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Aujourd’hui à] LT",
      nextDay: "[Demain à] LT",
      nextWeek: "dddd [à] LT",
      lastDay: "[Hier à] LT",
      lastWeek: "dddd [dernier à] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "dans %s",
      past: "il y a %s",
      s: "quelques secondes",
      m: "une minute",
      mm: "%d minutes",
      h: "une heure",
      hh: "%d heures",
      d: "un jour",
      dd: "%d jours",
      M: "un mois",
      MM: "%d mois",
      y: "un an",
      yy: "%d ans",
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? "er" : "e");
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === "M";
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "PD" : "MD";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });
  const [load, setload] = useState(true);
  const navigate = useNavigate();
  const getList = async () => {
  
   
    try {
      await axios
        .get(Contact_API + "?order=desc&limit=10")
        .then((reponse) => {
          console.log(reponse);
          setlistcontact(reponse.data.data);
          setload(false);
          setOnIt(true)
          setTimeout(() => {
           
            getList()
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getList();
  }, []);


  return (
    <>
      <div className="container-lg container-fluid py-4">
        <div className="">
        <DataTable
          title={<h2><FontAwesomeIcon icon={faMailBulk} className='me-2'/>Boite de notification</h2>}
          columns={columns}
          data={listcontact}
          selectableRows={false}
          selectedRow={selectedRows}
          clearSelectedRows={toggledClearRows}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows.map((row) => row.id_org));
          }}
          progressPending={onIt ? false : load}
          contextActions={
            <div className="flex">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  setToggleClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>
              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  setsupp(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText:'Lignes par page'
          }}
          paginationRowsPerPageOptions={[5,10,15,20]}
        />
      </div>
        {/* <div className="row">
          <div className="overflow-auto">
            {load ? (
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : listcontact.length === 0 ? (
              <div className="w-100 text-center py-5 ">
                <p className="display-5">Aucune liste à afficher !</p>
              </div>
            ) : (
              <table className="table table-hover table-bordered table-striped table-light ">
                <thead>
                  <tr className="">
                    <th className="col-1">ID</th>
                    <th>Objet</th>
                    <th>Expéditeur</th>
                    <th>Déscription</th>
                    <th>réçu le</th>
                    <th className="col-1">Vu</th>
                    <th className="col-1">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listcontact.map((element) => (
                    <tr key={element.id_cont}>
                      <td>{element.id_cont}</td>
                      <td>{element.object_cont}</td>
                      <td>{element.email_cont}</td>
                      <td>{element.desc_cont}</td>
                      <td>{moment(element.created_at).locale("fr").fromNow()}</td>
                      <td>{element.is_repondre ? <span className="badge bg-success">lu<FontAwesomeIcon icon={faCheckDouble} className='ms-2'/></span> : <span className="badge bg-secondary">non lu</span>}</td>
                      <td className="d-flex gap-2 justify-content-center">
                        <Link
                          to={"/admin/mes notification/reply"}
                          state={{ data: element }}
                        >
                          <span className="badge bg-primary">
                            voir <FontAwesomeIcon className="ms-1" icon={faEye} />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div> */}
      </div>

      <div
        className="modal modal-blur"
        id="modal-danger"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon mb-2 text-danger icon-lg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9v2m0 4v.01"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>
              <h3>vous etes sur ?</h3>
              <div className="text-muted">
                vous-voulez vraiment supprimer cette catégorie? c'est
                irréversible.
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="fermerdel"
                      className="btn w-100"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                  </div>
                  {/* <div className="col"><button type='button' onClick={supprimer} className={"btn btn-danger w-100 " + (load && "disabled")}>
                                Supprimer catégorie
                            </button></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
