import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function RetourComp({url}) {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="p-2"
      onClick={() => navigate(url ? url : -1)}
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <FontAwesomeIcon icon={faArrowCircleLeft} />
    </button>
  );
}
