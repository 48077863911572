import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Slide, JackInTheBox, Zoom } from "react-awesome-reveal";
import axios from "axios";
import {
  CategorieByModule_API,
  file_url,
  list4moduleHateaosByLangue,
  MODULE_API,
} from "../../../service/config";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import useIntersectionObserverWithProps from "../../../outils/ObserverApparitionPropsChange";
import LoadingCard from "./LoadingCard";

export default function SuggestionModule(props) {
  const {dataRedux,load,sectionRef}  = useIntersectionObserverWithProps(list4moduleHateaosByLangue + localStorage.getItem("langue"),'suggestion','addsuggestion','data',props.test);
 
  function truncate(str) {
    if (str.length < 50) {
      return str;
    } else {
      return str.substring(0, 100) + "...";
    }
  }
  

  // const getModules = async () => {
  //   dataMod = [];
  //   if (dataRedux.length == 0) {
  //     setLoad(true);
  //     try {
  //       axios
  //         .get(list4moduleHateaosByLangue + localStorage.getItem("langue") ? localStorage.getItem("langue") : "Français" )
  //         .then((response) => {
  //           console.log(response);
  //           const data = response.data.data;
  //           setModules(data);
  //           dispatch({ type: "addsuggestion", payload: data });
  //           setLoad(false);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }else {
  //     setLoad(false)
  //     try {
  //       axios
  //         .get(list4moduleHateaosByLangue + localStorage.getItem("langue"))
  //         .then((response) => {
  //           console.log(response);
  //           const data = response.data.data;
  //           setModules(data);
  //           dispatch({ type: "addsuggestion", payload: data });
  //           setLoad(false);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getModules();
  // }, [props.test]);
  const sortByProperty = (array, property) =>
    [...array].sort((a, b) =>
      typeof a[property] === 'string'
        ? a[property].localeCompare(b[property])
        : a[property] - b[property]
    );


  return (
    <div className="container-lg container-fluid my-2 pb-5 " id="recommandation">
      <div className="my-3 d-flex  pb-1">
        <div className="vr w-1 me-2 bg-primary"></div>
        <p className="h1" ref={sectionRef} >Recommandation de module</p>
        {/* <div className="card-actions btn-actions">
          <a href="#" className="btn-action">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path></svg>
          </a>
          <a href="#" className="btn-action">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="6 15 12 9 18 15"></polyline></svg>
          </a>
          <div className="dropdown position-relative">
            <a href="#" className="btn-action dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="19" r="1"></circle><circle cx="12" cy="5" r="1"></circle></svg>
            </a>
            <div className="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: "translate3d(0px, 34.4px, 0px)" }}>
              <a className="dropdown-item" href="#"><FontAwesomeIcon icon={faArrowUp} className="me-2" />Plus récents</a>
              <a className="dropdown-item" href="#"><FontAwesomeIcon icon={faArrowDown} className="me-2" />Plus anciens</a>

            </div>
          </div>

        </div> */}
      </div>

      {load ? <LoadingCard/> : dataRedux.length === 0 ? (
        <div className="w-100  my-5 p-5 bg-light">
          <p className="display-5 fw-light text-secondary text-center">
            Aucune recommandation pour l'instant
          </p>
        </div>
      ) : (
        <div className="row">
          {" "}
          {dataRedux.map((element, index) => (
            <div
              key={index}
              className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-2"
            >
              <div className="card effet bg-card p-0  h-100 position-relative h-100 rounded-3 w-100">
              <div
                    className="img-responsive img-responsive-21x9 card-img-top"
                    style={{
                      position: "relative",
                      height: "150px",
                      maxHeight: "150px",
                    backgroundImage: `url(${file_url}${element.img_mod})`,
                    backgroundSize: "cover",
                  }}
                >
                    <div className="ombre"></div>
                    <Tag className="bg-info" style={{position:'absolute',top:'4px',right:'3px'}}>
                      {element.lang_mod}
                    </Tag>
                </div>
                <div className="card-body">
                  <Link to={`/details/${element.id_mod}`}>
                    <h5 className="card-title text-truncate">{element.titre_mod}</h5>
                  </Link>
                  <div className="card-subtitle d-flex flex-row flex-wrap gap-1">
                    {sortByProperty(element.categories, "categ").map((el, ino) => (
                      <span key={ino} className="badge bg-cyan px-3">
                        {el.categ}
                      </span>
                    ))}
                  </div>
                  <div className="card-text">
                    <p
                      className="lead text-muted text-justify fs-5 truncate-4-lines"
                     
                    >
                    {element.desc_mod}
                  </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
