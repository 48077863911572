import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ACTU_API, file_url } from "../../../service/config";
import { TagsInput } from "react-tag-input-component";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate, useParams } from "react-router-dom";

export default function AddModifActu() {
  var text = "";
  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [barColor, setBarColor] = useState("blue");
  const [tags, settags] = useState([]);
  const [mode, setMode] = useState(false);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    titre_actu: "",
    tag_actu: "",
    desc_actu: "",
    resum_actu: "",
    img_actu: null,
  });
  const vide = {
    titre_actu: "",
    tag_actu: "",
    resum_actu: "",
    desc_actu: "",
    img_actu: null,
  };
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    if (value === "") {
      setError({ ...error, [name]: "ce champs est réquis !" });
    } else {
      setError({ ...error, [name]: null });
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setError({ ...error, img_actu: null });
      setFormValues({ ...formValues, img_actu: e.target.files[0] });
    }
  };

  function manova() {
    text = "";
    formValues.tag_actu = "";
    let nb = 0;
    tags.forEach((element) => {
      nb++;
      text += element + ";";
      if (nb === tags.length) {
        formValues.tag_actu = text;
        setError({ ...error, tag_actu: null });
      }
    });
  }
  useEffect(() => {
    manova();
  }, [tags]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const data = {};
    const msgErreur = {};
    if (formValues.img_actu == null) {
      data.titre_actu = formValues.titre_actu;
      data.tag_actu = formValues.tag_actu;
      data.desc_actu = formValues.desc_actu;
      data.resum_actu = formValues.resum_actu;
    } else {
      data.titre_actu = formValues.titre_actu;
      data.tag_actu = formValues.tag_actu;
      data.desc_actu = formValues.desc_actu;
      data.resum_actu = formValues.resum_actu;
      data.img_actu = formValues.img_actu;
    }
    setFormValues(data);
    if (formValues.titre_actu === "") {
      setError({ ...error, titre_actu: "ce champs est requis !" });
      const input = document.getElementsByName("titre_actu")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false)
    } else if (formValues.tag_actu === "") {
      setError({ ...error, tag_actu: "ce champs est requis !" });
      const input = document.getElementsByName("tags")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false)
    } else if (formValues.resum_actu === "") {
      setError({ ...error, resum_actu: "ce champs est requis !" });
      const input = document.getElementsByName("resum_actu")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false)
    } else if (formValues.desc_actu === "") {
      setError({ ...error, desc_actu: "ce champs est requis !" });
      const input = document.getElementById("desc_actu");
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false)
    } else {
      if (id !== "new") {
        try {
          await axios
            .post(ACTU_API + "/" + id + "?_method=PUT", formValues, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              navigate(-1);
              toast.success("Element modifiée");
              setLoad(false);
            })
            .catch((error) => {
              console.log(error);
              const msg = error.response.data;
              console.log(msg);
              if (msg) {
                msgErreur.titre_actu = msg.titre_actu;
                msgErreur.tag_actu = msg.tag_actu;
                msgErreur.desc_actu = msg.desc_actu;
                msgErreur.resum_actu = msg.resum_actu;
                msgErreur.img_actu = msg.img_actu;
              }
              toast.info("Veuillez compléter toutes les champs !");
              setLoad(false);
              setError(msgErreur);
            });
          setError([]);
        } catch (error) {
          console.log(error);
          toast.error("probleme liee au serveur");
          setLoad(false);
        }
      } else {
        if (formValues.titre_actu === "") {
          setError({ ...error, titre_actu: "ce champs est requis !" });
        } else if (formValues.tag_actu === "") {
          setError({ ...error, tag_actu: "ce champs est requis !" });
        } else if (formValues.img_actu === null || formValues.img_actu === "" || formValues.img_actu === {}) {
          setError({ ...error, img_actu: "ce champs est requis !" });
          const input = document.getElementsByName("img_actu")[0];
          const inputTop = input.offsetTop;
          window.scrollTo(0, inputTop);
          setLoad(false)
        } else if (formValues.resum_actu === "") {
          setError({ ...error, resum_actu: "ce champs est requis !" });
        } else if (formValues.desc_actu === "") {
          setError({ ...error, desc_actu: "ce champs est requis !" });
          setLoad(false)
        } else {
          try {
            await axios
              .post(ACTU_API, formValues, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log(response);
                navigate(-1);
                toast.success("Actualité Ajouté");
                setLoad(false);
                setError([]);
              })
              .catch((error) => {
                const msg = error.response.data;
                console.log(error);
                console.log(msg);
                if (msg) {
                  msgErreur.titre_actu = msg.titre_actu;
                  msgErreur.tag_actu = msg.tag_actu;
                  msgErreur.desc_actu = msg.desc_actu;
                  msgErreur.img_actu = msg.img_actu;
                }
                toast.info("Veuillez compléter toutes les champs !");
                setLoad(false);
                setError(msgErreur);
              });
          } catch (error) {
            console.log(error);
            toast.error("probleme liee au serveur");
            setLoad(false);
          }
        }
      }
    }
  };

  const fetchUpdate = async () => {
    if (id !== "new") {
      try {
        await axios
          .get(ACTU_API + "/" + id)
          .then((reponse) => {
            const data = reponse.data.actualite;
            console.log(reponse);
            formValues.titre_actu = data.titre_actu;
            formValues.tag_actu = data.tag_actu;
            formValues.desc_actu = data.desc_actu;
            formValues.resum_actu = data.resum_actu;
            if (data.tag_actu !== "") {
              let tag = data.tag_actu.split(";");
              if (tag[tag.length - 1] === "") {
                tag.pop();
                settags(tag);
              } else {
                settags(tag);
              }
            }

            setImage(`${file_url}${data.img_actu}`);
          })
          .catch((err) => {
            if (err.response.data.line === 385) {
              toast.info("aucun resultat !");
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchUpdate();
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Actualités</h2>
            <p className="h3">
              {id !== "new" ? <>Modifier</> : <>Ajouter</>} un actualité
            </p>
          </div>
          <form
            className=""
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="py-0">
              <div className="my-3">
                <label className="form-label">Titre</label>
                <input
                  className={
                    "form-control " + (error.titre_actu && "is-invalid")
                  }
                  name="titre_actu"
                  value={formValues.titre_actu}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir titre"
                />
                {error.titre_actu ? (
                  <div className="invalid-feedback">{error.titre_actu}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="my-3">
                <label className="form-label">Tags</label>
                <TagsInput
                  value={tags}
                  name="tags"
                  className={
                    "taginput w-100 " + (error.tag_actu && "is-invalid")
                  }
                  onChange={settags}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      let val = e.target.value;
                      let nam = e.target.name;
                      settags([...tags, val]);
                      document.getElementsByName(nam)[0].value = "";
                    }
                  }}
                  separators={[";", ","]}
                  placeHolder="séparez les tags par des points virgules (;) ou des virgules (,)"
                />
                {error.tag_actu && (
                  <p className="text-danger">{error.tag_actu}</p>
                )}
              </div>
              <div className="my-3">
                <label className="form-label">Image </label>
                <input
                  className={"form-control " + (error.img_actu && "is-invalid")}
                  name="img_actu"
                  id="img_actu"
                  type="file"
                  onChange={onImageChange}
                  accept="image/*"
                />
                {error.img_actu && (
                  <div className="invalid-feedback">{error.img_actu}</div>
                )}
              </div>
              <div className="my-3">
                {image && (
                  <span
                    className="avatar avatar-sm"
                    style={{ backgroundImage: "url(" + image + ")" }}
                  ></span>
                )}
              </div>
              <div className="my-3">
                <label className="form-label">
                  Quelque résumé de l'actualité
                </label>
                <textarea
                  rows={4}
                  className={
                    "form-control " + (error.resum_actu && "is-invalid")
                  }
                  name="resum_actu"
                  value={formValues.resum_actu}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici résumé"
                />
                {error.resum_actu ? (
                  <div className="invalid-feedback">{error.resum_actu}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="my-3 dansles">
                <label className="form-label">Détails de l'Actualité</label>
                <CKEditor
                  editor={Editor}
                  data={formValues.desc_actu}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                    // editor.enableReadOnlyMode("editor")
                    // const toolbar = editor.ui.view.toolbar.element;
                    // toolbar.style.display ='none'
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log("ato");
                    // console.log( { event, editor, data } );
                    console.log(data);
                    if (data === "") {
                      setError({
                        ...error,
                        desc_actu: "le details est réquis !",
                      });
                    } else {
                      setFormValues({ ...formValues, desc_actu: data });
                      setError({ ...error, desc_actu: null });
                    }
                  }}
                  // onBlur={ ( event, editor ) => {
                  //     console.log( 'Blur.', editor );
                  // } }
                  // onFocus={ ( event, editor ) => {
                  //     console.log( 'Focus.', editor );
                  // } }
                />
                <p id="desc_actu"></p>
                {error.desc_actu && (
                  <p name="desc_actu" className="text-danger">
                    {error.desc_actu}
                  </p>
                )}
              </div>
            </div>
            <div className="w-100">
              <div className="row justify-content-between ">
                <div className="col-3">
                  <button
                    id="fermer"
                    type="button"
                    className="btn w-100"
                    data-bs-dismiss="modal"
                    onClick={() => navigate(-1)}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="submit"
                    className={"btn btn-primary w-100 " + (load && "disabled")}
                  >
                    <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                    {id !== "new" ? <>Modifier</> : <>Ajouter</>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
