import {
  faCheckCircle,
  faEdit,
  faEraser,
  faFilePdf,
  faHouse,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";

export default function GestionSMS() {
  const columns = [
    {
      name: "Motif du message",
      selector: (row) => (
        <>
          {row.nom} {row.prenom}
        </>
      ),
      sortable: true,
    },
    {
      name: "Extrait Message",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button className="btn btn-secondary"><FontAwesomeIcon icon={faEdit}/></button>
          <button className="btn btn-danger"><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const columns_valid = [
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom} {row.prenom}
        </>
      ),
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-secondary">{row.status}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button className="btn btn-primary" onClick={() => {}}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [data, setdata] = useState([
    {
      nom: "Razafindrakoto",
      prenom: "Tiana",
      adresse: "2 Rue des Baobabs, Antsirabe",
      telephone: "+261 34 98 765 43",
      email: "tiana.razafindrakoto@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-03",
    },
    {
      nom: "Rakoto",
      prenom: "Jean",
      adresse: "12 Rue des Fleurs, Antananarivo",
      telephone: "+261 32 12 345 67",
      email: "jean.rakoto@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-02-05",
    },
    {
      nom: "Raharisoa",
      prenom: "Tina",
      adresse: "4 Rue des Coquelicots, Antsiranana",
      telephone: "+261 34 23 456 78",
      email: "tina.raharisoa@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2021-12-31",
    },
    {
      nom: "Andrianasolo",
      prenom: "Luc",
      adresse: "1 Rue des Lilas, Antananarivo",
      telephone: "+261 32 87 654 32",
      email: "luc.andrianasolo@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
    {
      nom: "Randriamanantena",
      prenom: "Sandra",
      adresse: "8 Rue des Roses, Fianarantsoa",
      telephone: "+261 34 56 789 01",
      email: "sandra.randriamanantena@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-01-23",
    },
    {
      nom: "Ratovonirina",
      prenom: "Feno",
      adresse: "7 Rue de la Mer, Toamasina",
      telephone: "+261 33 45 678 90",
      email: "feno.ratovonirina@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-02-01",
    },
    {
      nom: "Ratsimba",
      prenom: "Fara",
      adresse: "5 Rue des Narcisses, Antsirabe",
      telephone: "+261 32 34 567 89",
      email: "fara.ratsimba@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
  ]);
  const [showvalid, setshowvalid] = useState(false);
  const handleValidation = () => setshowvalid(!showvalid);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <DataTable
                title={<p className="fs-2">Liste des candidats </p>}
                columns={columns_valid}
                data={data}
                contextActions={
                  <button className="btn btn-cyan me-2">
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleValidation}>
            Fermer
          </Button>
          <Button variant="primary">
            <>Envoyer</>
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> SMS{" "}
          </h2>
          <h4>Gestion des SMS</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7 col-12 pb-2">
          <div className="card">
            <div className="card-body">
              <div className="">
                <DataTable
                  columns={columns}
                  data={data}
                  contextActions={
                    <button className="btn btn-cyan me-2">
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      Valider
                    </button>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button className="btn btn-secondary">
                  <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                  Exporter en PDF
                </button>
                <button className="btn btn-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-lg-5 col-12 pb-2">
          <div className="card">
            <div className="card-body pb-4">
              <h1>Formulaire SMS</h1>
              <div className="row">
                <div className="col-12">
                  <div className="my-2">
                    <label className="form-label">Motif</label>
                    <select className="form-control w-100" name="autoecole">
                      <option value="">Inscription</option>
                      <option value="">Examen</option>
                      <option value="">Resultat examen</option>
                      <option value="">Mot de passe oublié</option>
                      <option value="">Autres</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-2">
                    <label className="form-label">Message</label>
                    <textarea
                      className="form-control"
                      rows={5}
                      placeholder="description"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-cyan">Envoyer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
