import React from "react";
import "@tabler/core";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faBuilding, faChartSimple, faDriversLicense, faHome, faLinesLeaning, faList12, faMap, faNoteSticky, faPrescription, faSchoolCircleXmark, faSms, faUserGraduate, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import isconnected from "../../../service/isconnected";

export default function HeaderOrganisme() {
  const type = ['SuperAdmin','ATT','MTM']
  const activeClassName = " border-bottom border-2 border-primary rounded-0 w-100 d-flex justify-content-start";
  return (
    <div className="bg-light m-0 p-0" style={{ zIndex: 1 }}>
      <div class="container-lg container-fluid ps-0">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-light ">
              <div class="container-fluid ">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav d-flex flex-wrap">
                    <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/accueil"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faHome}
                        />
                        <span className="nav-link-title">Accueil</span>
                      </NavLink>
                    </li>
                 {type.includes(isconnected.user().type_util ) && <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/gerer"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faBuilding}
                        />

                        <span className="nav-link-title">Organismes</span>
                      </NavLink>
                    </li>}
                   {isconnected.user().type_util==='ATT' && <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/auto-école"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faDriversLicense}
                        />
                        <span className="nav-link-title">Auto-école</span>
                      </NavLink>
                    </li>}
                 {isconnected.user().type_util==='ATT' && <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/membres"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faUserGroup}
                        />
                        <span className="nav-link-title">Membres</span>
                      </NavLink>
                    </li>}
                    <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/candidats"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faAddressCard}
                        />
                        <span className="nav-link-title">Candidats</span>
                      </NavLink>
                    </li>
                    <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/apprenants"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faUserGraduate}
                        />
                        <span className="nav-link-title">Apprenants</span>
                      </NavLink>
                    </li>
                    <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/examens"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faPrescription}
                        />
                        <span className="nav-link-title">Examens</span>
                      </NavLink>
                    </li>
                    <li className="nav-item"  data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show">
                      <NavLink
                        to="/organisme/resultats"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                         <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faNoteSticky}
                        />
                        <span className="nav-link-title">Resultats</span>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown justify-self-end ms-3">
                      <button
                        className="btn btn-dark dropdown-toggle  dropdown position-static"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Plus
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li className="dropdown-item">
                          <NavLink
                            to="/organisme/statistique de suivi"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faChartSimple}
                        />
                            <span className="nav-link-title">
                              Statistique de suivi
                            </span>
                          </NavLink>
                        </li>
                        <li className="dropdown-item">
                          <NavLink
                            to="/organisme/gestion sms"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faSms}
                        />
                            <span className="nav-link-title">SMS</span>
                          </NavLink>
                        </li>
                        <li className="dropdown-item">
                          <NavLink
                            to="/organisme/cursus"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faLinesLeaning}
                        />
                            <span className="nav-link-title">Cursus</span>
                          </NavLink>
                        </li>
                        <li className="dropdown-item">
                          <NavLink
                            to="/organisme/cartographie"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faMap}
                        />
                            <span className="nav-link-title">
                              Cartographie
                            </span>
                          </NavLink>
                        </li>
                        {/* <li className="dropdown-item">
                          <NavLink
                            to="/organisme/statistique suivi"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faSchoolCircleXmark}
                        />
                            <span className="nav-link-title">
                              Statistique de suivi
                            </span>
                          </NavLink>
                        </li> */}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
