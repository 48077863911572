import { faAdd, faCancel, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from '../../../outils/Pagination';
import { FichierLesson_API, FichierLois_API, file_url, TexteLois_API } from '../../../service/config'
import LoadingBar from 'react-top-loading-bar'
import { useRef } from 'react';

export default function GererTexteLoisFichier() {
  const ref = useRef(null)
  const [load, setLoad] = useState(false)
  const [valider, setValider] = useState(false)
  const [formValues, setFormValues] = useState([])
  const [ID, setId] = useState(null)
  const [barcolor, setBarcolor] = useState("blue")
  const [error, setError] = useState([]);
  const [options, setOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const abortController = new AbortController();
  let { id } = useParams();
  
  const [fichier, setFichier] = useState({fichiert:[]})
  const getAllFichier = () => {
    ref.current.continuousStart()
    setBarcolor("blue")
    try {
      axios.get(TexteLois_API +"/" + id,
      {
      signal: abortController.signal,
      }).then((response) => {
        setFichier(response.data.lois[0])
        console.log(response)
        ref.current.complete()
      }).catch((e) => {
        setBarcolor("red")
        console.log(e)
      })
    } catch (error) {
      console.log(error)
      setBarcolor("red")
      toast.error("une erreur s'est produit !")
    }
  }

  const vide = {
    "titre_les": "",
    "desc_les": "",
    "img_les ": null,
    "id_mod ": ""
  }



  const onChangefichier = (i, e) => {
    let formfichier = [...formValues];
  
    if (e.target.files && e.target.files[0]) {
      formfichier[i]['fichiert'] = e.target.files[0];
    }
    setFormValues(formfichier);
  
  }
  let handleChangeFichier = (i, e) => {
    let formfichier = [...formValues];
    formfichier[i][e.target.name] = e.target.value;
    formfichier[i]["index"] = i
    setFormValues(formfichier);
    if (e.target.name === "type_ficht") {
      formValues.forEach((element, index) => {
        document.getElementById(`type${index}`).innerHTML = ""
        if (element.type_ficht === "" || element.type_ficht === "choisir ici...") {
          document.getElementById(`type${index}`).innerHTML = "<p className='text-danger fs-5'>type fichier requis</p>"
        } else {
          document.getElementById(`type${index}`).innerHTML = ""
        }
      })
    }
  }


  const addInput = (e) => {
    e.preventDefault();
    const nvInput = [
      ...formValues,
      { titre_ficht: "", type_ficht: "", fichiert: null, id_lois: id },
    ];
    setFormValues(nvInput)
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }


  const ajoutLessonFichier = (e) => {
    e.preventDefault();
    var process = 0;
    setLoad(true)

    for (let index = 0; index < formValues.length; index++) {
      const element = formValues[index];
      document.getElementById(`type${index}`).innerHTML = ""
      if (element.type_ficht === "" || element.type_ficht === "choisir ici...") {

        document.getElementById(`type${index}`).innerHTML = "<p className='text-danger fs-5'>type fichier requis</p>"
        setValider(false);
        setLoad(false)
        break;
      } else {
        document.getElementById(`type${index}`).innerHTML = ""
        if (index === formValues.length - 1) {
          formValues.forEach(element => {
            process++;
            try {
              axios.post(FichierLois_API + "?_hmethod=PUT", element, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }).then(response => {
                console.log(response);
                if (process === formValues.length) {
                  setLoad(false)
                  toast.success("fichier ajouter",{autoClose:3500})
                  document.getElementById('fermerModal').click();
                  getAllFichier()

                }
              }).catch((error) => {
                console.log(error)
                const msg = error.response.data
                console.log(msg)
                if (msg.fichiert) {
                  toast.error(msg.fichiert[0])
                }
                setLoad(false)

              })
              setError([])
            } catch (error) {
              console.log(error)
setLoad(false)
            }

          });
        }
      }

    }
  }

  const supprimer = async () => {
    setLoad(true)
    try {
      await axios.delete(FichierLois_API + "/" + ID).then((response) => {
        toast.success("Fichier numero " + ID + " supprimer")
        setLoad(false)
        document.getElementById('fermerdel').click();
        getAllFichier();
      }).catch((error) => {
        console.log(error)
        toast.error("fichier pas supprimer!")
        setLoad(false)
      })
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false)
    }
  }

  const initialiser = () => {
    setLoad(false)
    setFormValues([])
    setError(vide)
    document.getElementById("reset_form").click()
  };


  useEffect(() => {
    getAllFichier()
    return () => {
      abortController.abort();
    };

  }, [])




  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  const paginatedInvoices = Pagination.getData(fichier.fichiert, currentPage, itemsPerPage);

  return (
    <>
      <LoadingBar ref={ref} color={barcolor} />
      <div className='container-lg container-fluid py-4'>
        <div className="modal modal-blur" id="modal-danger" tabIndex="-1" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="modal-status bg-danger"></div>
              <div className="modal-body text-center py-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
                <h3>vous etes sur ?</h3>
                <div className="text-muted">vous-voulez vraiment supprimer cette fichier? c'est irreversible.</div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col"><button type='button' id='fermerdel' className="btn w-100" data-bs-dismiss="modal">
                      Annuler
                    </button></div>
                    <div className="col"><button type='button' onClick={supprimer} className={"btn btn-danger w-100 " + (load && "disabled")}>
                      Supprimer fichier
                    </button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal modal-blur" tabIndex="-1" id='exampleModal'>
          <div className="modal-dialog modal-lg" role="document">
            <form className='my-3' onSubmit={ajoutLessonFichier}>
              <div className="modal-content">
                <div className='d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black'>
                  <h2 className='p-2'>Formulaire Fichier</h2>
                  <button className="btn-close p-0" type='button' data-bs-dismiss="modal" id='fermerModal' aria-label="Close"></button>
                </div>
                <div className="modal-body py-0" style={{ height: "550px", overflowY: "auto" }}>
                  <p className='lead my-2'>Si il y a des fichier qui doivent etre enregistrés avec le leçons veuillez les ajouter ici, sinon cliquer sur le bouton <span className='fw-bold text-primary'>Terminer</span></p>
                  <div className='mt-1'>
                    <button type="reset" className="p-0 m-0" style={{ visibility: "hidden" }} id="reset_form"></button>
                    <div className='row my-3'>
                      <div className='col d-flex align-items-center gap-3'>
                        <label className="form-label">Fichier rattaché</label>  <button className="btn btn-primary" onClick={addInput}><FontAwesomeIcon icon={faAdd} className="pe-2" />Ajouter un champs</button>
                      </div>

                    </div>
                    <div className='row'>

                      {formValues.map((element, index) => (
                       <div className="col-6 d-flex gap-2 my-2" key={index}>
                       <div className="card p-3 w-100">
                         <div className="mt-1">
                           <label className="form-label">
                             Fichier du texte en vigueur{" "}
                           </label>
                           <input
                             type="file"
                             name="fichiert"
                             id={`fichier_les${index}`}
                             className="form-control"
                             onChange={(e) => onChangefichier(index, e)}
                          
                             required
                           />
                         </div>
                         <div className="mt-3">
                           <label className="form-label">
                             Titre du fichier
                           </label>
                           <input
                             value={element.titre_ficht}
                             className="form-control"
                             name="titre_ficht"
                             onChange={(e) =>
                               handleChangeFichier(index, e)
                             }
                             required
                           />
                         </div>
                         <div className="my-3">
                           <label className="form-label">
                             Type du fichier
                           </label>
                           <select
                             className="form-control"
                             value={element.type_ficht}
                             name="type_ficht"
                             id="type_ficht"
                             onChange={(e) =>
                               handleChangeFichier(index, e)
                             }
                             required
                           >
                             <option>choisir ici...</option>
                             <option value="Photo">Photo</option>
                             <option value="Audio">Audio</option>
                             <option value="Document">Document</option>
                             <option value="Autre">Autre</option>
                           </select>
                           <div
                             style={{ color: "red" }}
                             id={`type${index}`}
                           ></div>
                         </div>
                         {index ? (
                           <button
                             type="button"
                             className="btn btn-danger"
                             onClick={() => removeFormFields(index)}
                           >
                             <FontAwesomeIcon icon={faTrash} />
                           </button>
                         ) : null}
                       </div>
                     </div>
                      ))}

                    </div>
                  </div>


                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row justify-content-between">
                      <div className={"col d-flex justify-content-start"}>
                        <button className='btn' type='button' data-bs-dismiss="modal" aria-label="Close" >
                          <FontAwesomeIcon icon={faCancel} className="me-2" />   Annuler
                        </button>

                      </div>
                      <div className="col d-flex justify-content-end"><button type='submit' className={"btn btn-primary " + (load && "disabled")} >
                        <FontAwesomeIcon icon={faAdd} className="me-2" /> {<>Ajouter</>}
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
        <div>
          <h2>Fichier</h2>
        </div>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <p className=''>Fichier du texte en vigueur numero: {id}</p>
          <div>
            <button type="button" onClick={initialiser} className='btn btn-primary px-4 py-2' data-bs-toggle="modal" data-bs-target="#exampleModal"><FontAwesomeIcon icon={faAdd} className="me-2" /> Nouveau fichier</button>
          </div>
        </div>
        <div>
        </div>
        <div className='overflow-auto d-flex flex-column justify-content-between table-ty'>
          <table className='table table-hover table-bordered table-striped table-light '>
            <thead>
              <tr className=''>
                <th>ID</th>
                <th>Fichier</th>
                <th>Titre</th>
                <th>Type</th>
                <th>id texte en vigueur</th>
                <th>Cree le</th>
                <th className='col-2'>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedInvoices.map((element, index) => <tr key={index}>
                <td>{element.id_ficht}</td>
                <td className='text-center'>{element.type_ficht == "Document" ? "doc" :  <span className="avatar avatar-sm"  style={{ backgroundImage: `url(${file_url}/${element.fichiert})` }}></span>}</td>
                <td>{element.titre_ficht}</td>
                <td>{element.type_ficht}</td>
                <td>{element.id_lois}</td>
                <td>
                  {element.created_at}
                </td>
                <td className='text-center'><button className='btn btn-danger' type='button' onClick={() => setId(element.id_ficht)} data-bs-toggle="modal" data-bs-target="#modal-danger"><FontAwesomeIcon icon={faTrashAlt} /></button></td>

              </tr>)}
            </tbody>
          </table>
          <div className='d-flex justify-content-end align-items-end'>
            <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} onPageChanged={handlePageChange} length={fichier.fichiert.length}
            />
          </div>


        </div>
      </div>
    </>
  )
}
